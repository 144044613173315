import React, { useEffect, useState } from 'react'
import axios from 'axios'
import './styles.css'

export default function ResetAuthCode() {

    const [mode, setMode] = useState('loading')

    useEffect(() => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const signataire = urlParams.get('signataire')

        axios.get(`https://dkbsign.com/new_api_dkbsign/public/api/signataires/${signataire}/code-authentification`)
        .then(res => {
            setMode('success')
            console.log(res)
        }).catch(err => {
            setMode('error')
            console.log(err)
        })
        

        console.log(signataire)
    }, [])

    return (
        <div className='reset-content'>
            {
                mode === 'loading' && <p className='msg-loading'>Chargement...</p>
            }
            {
                mode === 'error' && 
                <div className='msg-error'>
                    <img className='msg-error_img' src="./assets/media/home_icon/error.png" alt="msg-error_img" />                    
                    <p>Erreur lors de l'envoi du code</p>
                    <a href="https://dkbsign.com">Retour sur la page d'acceuil DKBSign</a>
                </div>
            }
            {
                mode === 'success' &&
                <div className='msg-success'>
                    <img className='msg-success_img' src="./assets/media/home_icon/Disponiblite.png" alt="msg-success_img" />                    
                    <p>Code envoyé avec succès</p>
                    <a href="https://dkbsign.com">Retour sur la page d'acceuil DKBSign</a>
                </div>
            }
        </div>
    )
}
