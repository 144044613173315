import React from 'react'
import { HiQuestionMarkCircle } from 'react-icons/hi'
import "./tooltip.css"

export default function ToolTip() {
    return (
        <div className="tooltip-form"><HiQuestionMarkCircle color='red' size={20}/>
            <span className="tooltiptext">Le volume de signature correspond à la volumétrie de signature c’est-à-dire le nombre de signature</span>
        </div>
    )
}
