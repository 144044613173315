import axios from 'axios';
import { ACTUALITE_URL } from '../../../../services/api/URL_API';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';
import { useTranslateContext } from '../../../../hooks';

const BASE_URL = 'https://dkbsign.com/dkbsign_web_api/public';

const token = Cookies.get('token_admin');

export const AddActualite = async ({
  title,
  content,
  img,
  subtitle,
  posteddate,
  alt,
}) => {
  const { translate } = useTranslateContext();

  const formData = new FormData();

  formData.append('title', title);
  formData.append('content', content);
  formData.append('subtitle', subtitle);
  formData.append('posteddate', posteddate);
  formData.append('alt', alt);

  formData.append('img', img);

  try {
    const response = await axios.post(
      `${BASE_URL}${ACTUALITE_URL}/store`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      }
    );
    // handle success

    toast.success(translate.admin.actualiteEteAjoute, {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

    window.location.reload();
  } catch (error) {
    toast.error(translate.admin.erreurImpossibleAjouterActualite, {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
};

export const EditActualite = async ({
  title,
  content,
  img,
  subtitle,
  posteddate,
  alt,
  id,
}) => {
  const { translate } = useTranslateContext();

  const formData = new FormData();

  formData.append('title', title);
  formData.append('content', content);
  formData.append('subtitle', subtitle);
  formData.append('posteddate', posteddate);
  formData.append('alt', alt);

  formData.append('img', img);

  try {
    const response = await axios.post(
      `${BASE_URL}${ACTUALITE_URL}/update/${id}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      }
    );
    // handle success

    toast.success(translate.admin.actualiteEteModifie, {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    window.location.reload();
  } catch (error) {
    toast.error(translate.admin.erreurImpossibleDeModifieActualite, {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
};

export const DeleteActualite = async ({ id }) => {
  const { translate } = useTranslateContext();

  try {
    const response = await axios.get(
      `${BASE_URL}${ACTUALITE_URL}/destroy/${id}`,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      }
    );
    // handle success

    toast.success(translate.admin.actualiteEteSupprime, {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    window.location.reload();
  } catch (error) {
    toast.error(translate.admin.erreurImpossibleDeSupprimerActualite, {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
};
