import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getStatistic,
  userSelector,
} from '../../services/reduxSlices/UserSlice';
import './document.css';
import Pagination from '../Pagination/Pagination';

import { useTranslateContext } from '../../hooks';

/**
 * Ce Composant envellope les lignes d'un tableau
 *
 * @param {children} param0
 * @returns TableWrapper
 *
 */
export default function TableWrapper({
  children,
  setPage,
  currentPage,
  maxPage,
  onApplySearch,
  onApplyFilter,
  status,
}) {
  const { translate } = useTranslateContext();
  const { stats } = useSelector(userSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getStatistic());
  }, []);

  const getTotalNb = (data) => {
    if (data) {
      const result =
        data.en_attente.count +
        data.en_cours.count +
        data.refuse.count +
        data.signe.count;
      return result;
    }

    return 0;
  };

  return (
    <div
      id="kt_content_container"
      className="d-flex flex-column-fluid align-items-start container-xxl"
    >
      <div className="content flex-row-fluid" id="kt_content">
        <div className="card card-flush pb-8">
          <div className="card-header align-items-center py-5 gap-2 gap-md-5">
            <div className="card-title">
              <div className="d-flex align-items-center position-relative my-1 table-filter">
                <input
                  type="text"
                  onClick={(e) => onApplySearch(e)}
                  data-kt-ecommerce-product-filter="search"
                  className="form-control filter-search form-control-solid w-250px ps-14"
                  placeholder={translate.admin.rechercherUnDocument}
                />

                <div className="buttonContainer">
                  <input
                    type="button"
                    className={`form-control form-control-solid w-150px ps-5 filter-status`}
                    onClick={(e) => onApplyFilter('0')}
                    value={`${translate.admin.tous} (${getTotalNb(stats)})`}
                  />
                  <span className="badgeButton">
                    {stats
                      ? stats.signe.count +
                        stats.refuse.count -
                        (stats.signe.consulte + stats.refuse.consulte)
                      : 0}
                  </span>
                </div>
                <div className="buttonContainer">
                  <input
                    onClick={(e) => onApplyFilter('signe')}
                    type="button"
                    className={`form-control form-control-solid w-150px ps-5 filter-status ${
                      status === 'signe' && 'signer-btn'
                    }`}
                    value={`${translate.admin.signe} (${
                      stats ? stats.signe.count : 0
                    })`}
                  />
                  <span className="badgeButton">
                    {stats ? stats.signe.count - stats.signe.consulte : 0}
                  </span>
                </div>
                <input
                  type="button"
                  onClick={(e) => onApplyFilter('en_attente')}
                  className={`form-control form-control-solid w-150px ps-5 filter-status ${
                    status === 'en_attente' && 'en_attente-btn'
                  }`}
                  value={`${translate.admin.attente} (${
                    stats ? stats.en_attente.count : 0
                  })`}
                />
                <input
                  type="button"
                  onClick={(e) => onApplyFilter('en_cours')}
                  className={`form-control form-control-solid w-150px ps-5 filter-status ${
                    status === 'en_cours' && 'en_cour-btn'
                  }`}
                  value={`${translate.admin.encours} (${
                    stats ? stats.en_cours.count : 0
                  })`}
                />
                <div className="buttonContainer">
                  <input
                    onClick={(e) => onApplyFilter('refuse')}
                    type="button"
                    className={`form-control form-control-solid w-150px ps-5 filter-status ${
                      status === 'refuse' && 'refuse-btn'
                    }`}
                    value={`${translate.admin.refuse} (${
                      stats ? stats.refuse.count : 0
                    })`}
                  />
                  <span className="badgeButton">
                    {stats ? stats.refuse.count - stats.refuse.consulte : 0}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="card-body table-responsive">
            <table
              className="table align-middle table-row-dashed fs-6 gy-5"
              id="kt_ecommerce_products_table"
            >
              <thead>
                <tr className="text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0">
                  <th className="w-10px pe-2">
                    <div className="form-check form-check-sm form-check-custom form-check-solid me-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        data-kt-check="true"
                        data-kt-check-target="#kt_ecommerce_products_table .form-check-input"
                        value="1"
                      />
                    </div>
                  </th>
                  <th className="min-w-200px">Documents</th>
                  <th className="text-end min-w-100px">{translate.admin.recu}</th>
                  <th className="text-end min-w-100px">Status</th>
                  <th className="text-end min-w-70px">Actions</th>
                </tr>
              </thead>

              <tbody className="fw-bold text-gray-600">{children}</tbody>
            </table>
          </div>
          <Pagination
            setPage={setPage}
            currentPage={currentPage}
            maxPage={maxPage <= 3 ? 3 : maxPage}
          />
        </div>
      </div>
    </div>
  );
}
