import { useState } from "react";

export const useParamsDocument = () => {

    const [type, setType] = useState('')
    const [alertMsg, setAlertMsg] = useState('')
    const [allModalOpen, setAllModalOpen] = useState(false)

    const [signataireAll, setSignataireAll] = useState(false)
    const [signatureAll, setSignatureAll] = useState(false)
    const [qrcodeAll, setQrcodeAll] = useState(false)

    const toggleSignataire = () => setSignataireAll(!signataireAll)
    const toggleSignature = () => setSignatureAll(!signataireAll)
    const toggleQrcode = () => setQrcodeAll(!signataireAll)

    const getMessageAlert = (typeBox) => {
        if (typeBox === 'signature') {
            setAlertMsg('Voulez vous appliquer cette signature a tout les documents')
            setType(typeBox)
            return
        }
        if (typeBox === 'qrcode') {
            setAlertMsg('Voulez vous appliquer ce qrCode a tout les documents')
            setType(typeBox)
            return
        }
        else {
            setAlertMsg('Voulez vous appliquer ce signataire a tout les documents')
            setType(typeBox)
            return
        }
    }

    const setIsAll = (bool) => {
        setSignataireAll(bool)
        setSignatureAll(bool)
        setQrcodeAll(bool)
    }

    const toggleFunction = () => {
        if (type === 'signature') return toggleSignature()
        if (type === 'qrcode') return toggleQrcode()
        else return toggleSignataire()
    }

    return {
        toggleFunction,
        allModalOpen,
        setAllModalOpen,
        getMessageAlert,
        alertMsg,
        setIsAll,

        isAll: signataireAll || signatureAll || qrcodeAll
    }
}