import React, { useEffect, useState } from 'react'
import Container from '../../../../components/AdminLanding/Container/Container'
import ImageTable from '../../../../components/AdminLanding/Images/ImageTable'
import { GetImages } from './services'

export default function Images() {

    const [data, setData] = useState(null)

    useEffect(() => {
        GetImages().then(source => setData(source))
    }, [])
    
    return (
        <Container>
            <ImageTable data={data} />
        </Container>
    )
}
