import React, { useEffect, useState } from 'react'
import Container from '../../../../components/AdminLanding/Container/Container'
import ActualiteTable from '../../../../components/AdminLanding/Actualite/ActualiteTable'
import { GetActualite } from './services'

export default function Actualite() {

    const [data, setData] = useState(null)

    useEffect(() => {
      GetActualite().then(source => setData(source))
    }, [])
    
    return (
        <Container>
            <ActualiteTable data={data} />
        </Container>
    )
}
