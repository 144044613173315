import React, { useState, useEffect } from 'react';
import LandingWrapper from './LandingWrapper';
import { ImKey } from 'react-icons/im';
import { TryModal } from './Modal';
import { RiSecurePaymentFill } from 'react-icons/ri';
import { GiReceiveMoney, GiFlexibleStar } from 'react-icons/gi';
import {
  GetReferencement,
  GetActualite,
  GetImagesByPage,
} from './Admin/Main/services';
import { Helmet } from 'react-helmet';
import { GoLaw } from 'react-icons/go';
import { BsPatchCheckFill } from 'react-icons/bs';
import { FaPenAlt } from 'react-icons/fa';
import { SiWebmoney } from 'react-icons/si';
import { BsArrowRight, BsCalendarWeek } from 'react-icons/bs';
import SpinnerPage from './SpinnerPage';
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';
import Bounce from 'react-reveal/Bounce';
import './styles.css';

import { useTranslateContext } from '../../hooks';

var empty_img = {
  img1: null,
  alt1: null,
  img2: null,
  alt2: null,
  img3: null,
  alt3: null,
  img4: null,
  alt4: null,
  img5: null,
  alt5: null,
  img6: null,
  alt6: null,
  img7: null,
  alt7: null,
  img8: null,
  alt8: null,
  img9: null,
  alt9: null,
  img10: null,
  alt10: null,
  img11: null,
  alt11: null,
  img12: null,
  alt12: null,
  img13: null,
  alt13: null,
  img14: null,
  alt14: null,
  img15: null,
  alt15: null,
  img16: null,
  alt16: null,
  img17: null,
  alt17: null,
  img18: null,
  alt18: null,
  img19: null,
  alt19: null,
  img20: null,
  alt20: null,
};

const PAGE_NAME = 'ACCEUIL';
function trimString(chaine) {
  if (chaine.length > 150) {
    return chaine.slice(0, 150) + '...';
  } else {
    return chaine;
  }
}

const BASE_URL_IMG = `https://dkbsign.com/dkbsign_web_api/public/storage/websiteimages/ACCUEIL`;

export default function LandingHome() {
  const [referencement, setReferencement] = useState(null);
  const [actualite, setActualite] = useState(null);
  const [IMAGES, SETIMAGES] = useState(null);

  useEffect(() => {
    GetReferencement().then((source) => setReferencement(source));
    GetActualite().then((source) => setActualite(source));
    GetImagesByPage({ cible: 'ACCUEIL' }).then((source) => SETIMAGES(source));
  }, []);

  return IMAGES ? (
    <div className="landing-home">
      <LandingWrapper>
        <Helmet>
          <title>
            {referencement
              ? referencement.find((dt) => dt.pageCible === PAGE_NAME)
                ? referencement.find((dt) => dt.pageCible === PAGE_NAME)
                    .siteTitle
                : 'DKBSign'
              : 'DKBSign'}
          </title>
          <meta
            name="description"
            content={
              referencement
                ? referencement.find((dt) => dt.pageCible === PAGE_NAME)
                  ? referencement.find((dt) => dt.pageCible === PAGE_NAME)
                      .meta_description
                  : ''
                : ''
            }
          />

          <meta
            name="keywords"
            lang="fr"
            content={
              referencement
                ? referencement.find((dt) => dt.pageCible === PAGE_NAME)
                  ? referencement.find((dt) => dt.pageCible === PAGE_NAME)
                      .meta_keywords
                  : ''
                : ''
            }
          />

          <meta
            name="robots"
            content={
              referencement
                ? referencement.find((dt) => dt.pageCible === PAGE_NAME)
                  ? referencement.find((dt) => dt.pageCible === PAGE_NAME)
                      .meta_robots
                  : ''
                : ''
            }
          />
          <meta
            name="category"
            content={
              referencement
                ? referencement.find((dt) => dt.pageCible === PAGE_NAME)
                  ? referencement.find((dt) => dt.pageCible === PAGE_NAME)
                      .meta_category
                  : ''
                : ''
            }
          />

          <meta
            name="identifier-url"
            content={
              referencement
                ? referencement.find((dt) => dt.pageCible === PAGE_NAME)
                  ? referencement.find((dt) => dt.pageCible === PAGE_NAME)
                      .meta_identifier_url
                  : ''
                : ''
            }
          />

          <meta
            name="reply-to"
            content={
              referencement
                ? referencement.find((dt) => dt.pageCible === PAGE_NAME)
                  ? referencement.find((dt) => dt.pageCible === PAGE_NAME)
                      .meta_reply_to
                  : ''
                : ''
            }
          />
        </Helmet>
        <Header IMAGES={IMAGES[0] ? IMAGES[0] : empty_img} />
        <Fade duration={3000}>
          <Partenaire IMAGES={IMAGES[0] ? IMAGES[0] : empty_img} />
        </Fade>
        <SignSomeClick />
        <Actualite data={actualite} />
        <Avantage />
        <SignatureElectronique />
        <Certificat IMAGES={IMAGES[0] ? IMAGES[0] : empty_img} />
      </LandingWrapper>
    </div>
  ) : (
    <div className="global-loader">
      <SpinnerPage />
    </div>
  );
}

const Header = ({ IMAGES }) => {
  const { translate } = useTranslateContext();
  const [showModal, setShowModal] = useState(false);

  return (
    <React.Fragment>
      {showModal && (
        <TryModal showModal={showModal} setShowModal={setShowModal} />
      )}
      <div className="landing-home__body-content">
        <div className="flip-header">
          <Fade duration={4000} left>
            <div className="landing-home__body-content-left">
              <div className="landing-home__body-content-left-title">
                <h1>{translate.landingPage.envoyezSigner}</h1>
                <h3>{translate.landingPage.vosDocumentsEnLigne}</h3>
                <p>
                  {translate.landingPage.OuQueVousSoyezEnTouteSimplicite} <br />
                  {translate.landingPage.rapideFacileEtMoindreCout}
                </p>
              </div>
              <div
                onClick={() => setShowModal(true)}
                className="landing-home__body-content-left-button"
              >
                {translate.landingPage.essayer}
              </div>
            </div>

            <div className="landing-home__body-content-right">
              <img
                src={
                  IMAGES.img1
                    ? `${BASE_URL_IMG}/${IMAGES.img1}`
                    : './assets/media/home_icon/Signature-electronique.png'
                }
                className="landing-home__body-content-right_img_signataire"
                alt={IMAGES.alt1 ? `${IMAGES.alt1}` : ''}
              />
            </div>
          </Fade>
        </div>
      </div>
    </React.Fragment>
  );
};

const Partenaire = ({ IMAGES }) => {
  const { translate } = useTranslateContext();

  const [images, setImages] = useState([
    {
      id: 1,
      src: IMAGES.img2
        ? `${BASE_URL_IMG}/${IMAGES.img2}`
        : './assets/media/home_icon/ent_1.png',
      alt: IMAGES.alt2 ? `${IMAGES.alt2}` : 'image_entreprise',
    },
    {
      id: 2,
      src: IMAGES.img3
        ? `${BASE_URL_IMG}/${IMAGES.img3}`
        : './assets/media/home_icon/ent_2.png',
      alt: IMAGES.alt3 ? `${IMAGES.alt3}` : 'image_entreprise',
    },
    {
      id: 3,
      src: IMAGES.img4
        ? `${BASE_URL_IMG}/${IMAGES.img4}`
        : './assets/media/home_icon/ent_3.png',
      alt: IMAGES.alt4 ? `${IMAGES.alt4}` : 'image_entreprise',
    },
    {
      id: 4,
      src: IMAGES.img5
        ? `${BASE_URL_IMG}/${IMAGES.img5}`
        : './assets/media/home_icon/ent_4.png',
      alt: IMAGES.alt5 ? `${IMAGES.alt5}` : 'image_entreprise',
    },
    {
      id: 5,
      src: IMAGES.img6
        ? `${BASE_URL_IMG}/${IMAGES.img6}`
        : './assets/media/home_icon/ent_5.png',
      alt: IMAGES.alt6 ? `${IMAGES.alt6}` : 'image_entreprise',
    },
    {
      id: 6,
      src: IMAGES.img7
        ? `${BASE_URL_IMG}/${IMAGES.img7}`
        : './assets/media/home_icon/ent_6.png',
      alt: IMAGES.alt7 ? `${IMAGES.alt7}` : 'image_entreprise',
    },
    {
      id: 7,
      src: IMAGES.img8
        ? `${BASE_URL_IMG}/${IMAGES.img8}`
        : './assets/media/home_icon/ent_7.png',
      alt: IMAGES.alt8 ? `${IMAGES.alt8}` : 'image_entreprise',
    },
    {
      id: 8,
      src: IMAGES.img9
        ? `${BASE_URL_IMG}/${IMAGES.img9}`
        : './assets/media/home_icon/ent_8.jpg',
      alt: IMAGES.alt9 ? `${IMAGES.alt9}` : 'image_entreprise',
    },
    {
      id: 9,
      src: IMAGES.img10
        ? `${BASE_URL_IMG}/${IMAGES.img10}`
        : './assets/media/home_icon/ent_9.png',
      alt: IMAGES.alt10 ? `${IMAGES.alt10}` : 'image_entreprise',
    },
    {
      id: 10,
      src: IMAGES.img11
        ? `${BASE_URL_IMG}/${IMAGES.img11}`
        : './assets/media/home_icon/ent_10.png',
      alt: IMAGES.alt11 ? `${IMAGES.alt11}` : 'image_entreprise',
    },
    {
      id: 11,
      src: IMAGES.img12
        ? `${BASE_URL_IMG}/${IMAGES.img12}`
        : './assets/media/home_icon/ent_11.png',
      alt: IMAGES.alt12 ? `${IMAGES.alt12}` : 'image_entreprise',
    },
    {
      id: 12,
      src: IMAGES.img13
        ? `${BASE_URL_IMG}/${IMAGES.img13}`
        : './assets/media/home_icon/ent_12.png',
      alt: IMAGES.alt13 ? `${IMAGES.alt13}` : 'image_entreprise',
    },
  ]);

  return (
    <div className="landing-partenaire">
      <p className="landing-partenaire__title">
        {translate.landingPage.ilsNousFontConfiance}
      </p>
      <div className="landing-partenaire__content">
        <marquee direction="left" scrollamount="10">
          {images.map((image, index) => {
            return <ItemPartenaire image={image} key={index} />;
          })}
        </marquee>
      </div>
    </div>
  );
};

const SignSomeClick = ({ IMAGES }) => {
  const { translate } = useTranslateContext();
  const [cards, setCards] = useState([
    {
      no: 1,
      title: translate.landingPage.telechargerVosDocuments,
      description: translate.landingPage.designezLesSignataires,
    },
    {
      no: 2,
      title: translate.landingPage.signezEtFaitesSigner,
      description: translate.landingPage.chaquesSignataireParEmailSignerSaisieCodeSMS,
    },
    {
      no: 3,
      title: translate.landingPage.conservezVosDocuments,
      description: translate.landingPage.notreServiceDeConservationValeurProbanteSecurise,
    },
  ]);

  return (
    <div className="landing-some-click">
      <div className="landing-some-click-title">
        {translate.landingPage.signezSimplementVosDocumentsEnQuelquesClics}
      </div>
      <div className="landing-some-click-cards">
        <Zoom top duration={3000}>
          {cards.map((card, index) => {
            return <SignSomeClickCard card={card} key={index} />;
          })}
        </Zoom>
      </div>
    </div>
  );
};

const SignSomeClickCard = ({ card }) => {
  return (
    <div className="landing-some-click-card">
      <div className="landing-some-click-card__number">{card.no}</div>
      <div className="landing-some-click-card__content">
        <div className="landing-some-click-card__content-title">
          {card.title}
        </div>
        <div className="landing-some-click-card__content-description">
          {card.description}
        </div>
      </div>
    </div>
  );
};

const ItemPartenaire = ({ image }) => {
  return (
    <img className="landing-partenaire_items" src={image.src} alt={image.alt} />
  );
};

const Actualite = ({ data }) => {
    const { translate } = useTranslateContext();
  const handleClick = () => {
    window.location.href = `/home-reference`;
  };

  return (
    <div className="landing-actualite">
      <p className="landing-partenaire__title">{translate.others.actualites}</p>
      <div className="landing-actualite__content">
        <div className="news-list">
          {data ? (
            data.length > 0 ? (
              data.map((dt, key) => (
                <ActualiteItem
                  key={key}
                  id={dt.slug}
                  titre={dt.title}
                  alt={dt.alt}
                  subtitle={dt.subtitle}
                  posteddate={dt.posteddate}
                  img={dt.img}
                />
              ))
            ) : (
              <></>
            )
          ) : (
            <></>
          )}
        </div>
        <div
          onClick={() => handleClick()}
          style={{ marginLeft: '18px' }}
          className="we-trust-us_content-direction"
        >
          <BsArrowRight className="show-button" color="white" />
        </div>
      </div>
    </div>
  );
};

const ActualiteItem = ({ id, titre, alt, posteddate, subtitle, img }) => {
  const handleClick = () => {
    window.location.href = `/actuality/${id}`;
  };

  return (
    <div onClick={() => handleClick()} className="landing-actualite_items">
      <img
        src={`https://dkbsign.com/dkbsign_web_api/public/storage/actualities/${img}`}
        alt={alt}
        className="landing-actualite_items-img"
      />

      <div className="landing-actualite_items-content">
        <div className="landing-actualite_items-content-description">
          {trimString(subtitle)}
        </div>
        <div className="landing-actualite_items-content-title">{titre}</div>

        <div className="landing-actualite_items-content-profile">
          <BsCalendarWeek className="landing-actualite_items-content-profile-img" />
          <div className="landing-actualite_items-content-profile-content">
            <div className="landing-actualite_items-content-profile-content-date">
              {posteddate}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Avantage = () => {
  return (
    <div className="landing-avantage">
      <p className="landing-partenaire__title">
        Avantages de la signature <br /> électronique
      </p>
      <div className="landing-avantage_content">
        <Fade duration={3000} left>
          <div className="landing-avantage_range rang-1">
            <div className="landing-avantage_range-items">
              <ImKey className="block-icon-avantages" />
              <div className="landing-avantage_range-items-header">
                <div className="block-text-avantages">Authentification</div>
                <div className="landing-avantage_range-items-content">
                  Une signature électronique garantie l'authenticité d'un
                  document signé où d'une transaction effectuée
                </div>
              </div>
            </div>
            <div className="landing-avantage_range-items">
              <RiSecurePaymentFill className="block-icon-avantages" />
              <div className="landing-avantage_range-items-header">
                <div className="block-text-avantages">Securisation</div>
                <div className="landing-avantage_range-items-content">
                  La signature électronique garantie la sécurité d'un document
                  car grâce à elle le doucument signé n'est en aucun cas
                  modifiable
                </div>
              </div>
            </div>
            <div className="landing-avantage_range-items">
              <GiReceiveMoney className="block-icon-avantages" />
              <div className="landing-avantage_range-items-header">
                <div className="block-text-avantages">Economique</div>
                <div className="landing-avantage_range-items-content">
                  Aujourd'hui nous vous donnons la possibilité de signer un
                  document sans l’imprimer (économie de papier) & aussi
                  l'envoyer de façon fiable par mail(économie de timbre)
                </div>
              </div>
            </div>
          </div>
        </Fade>

        <Fade duration={3000} right>
          <div className="landing-avantage_range rang-2">
            <div className="landing-avantage_range-items">
              <GoLaw className="block-icon-avantages" />
              <div className="landing-avantage_range-items-header">
                <div className="block-text-avantages">Integrité</div>
                <div className="landing-avantage_range-items-content">
                  Grâce à la signature éléctronique chaque document signé garde
                  son intégrité car celà nous permet d'identifier les différents
                  signataires en toute sérénité
                </div>
              </div>
            </div>
            <div className="landing-avantage_range-items">
              <GiFlexibleStar className="block-icon-avantages" />
              <div className="landing-avantage_range-items-header">
                <div className="block-text-avantages">Flexible</div>
                <div className="landing-avantage_range-items-content">
                  Notre module de signature electronique s'adapte a tout type
                  d'entreprise, grâce à notre API vous pourrez facilement
                  l'intégrer à vos applications
                </div>
              </div>
            </div>
            <div className="landing-avantage_range-items">
              <BsPatchCheckFill className="block-icon-avantages" />
              <div className="landing-avantage_range-items-header">
                <div className="block-text-avantages">Compatible</div>
                <div className="landing-avantage_range-items-content">
                  Notre module de signature permet de signer des documents
                  electroniques et aussi des transactions électroniques
                </div>
              </div>
            </div>
          </div>
        </Fade>
      </div>
    </div>
  );
};

const SignatureElectronique = () => {
  return (
    <div className="landing-electronic-sign">
      <p className="landing-partenaire__title">La signature électronique</p>
      <div className="landing-electronic-sign_content">
        <Bounce duration={3000} left>
          <div className="landing-electronic-sign-items">
            <FaPenAlt className="landing-electronic-sign-items-icon" />
            <div className="landing-electronic-sign-items-title">
              Signature electronique
            </div>
            <div className="landing-electronic-sign-items-content">
              DKBSign donne la possibilité d’envoyer, de signer et de suivre
              n’importe quel type de document, à partir de tous les appareils,
              où que vous soyez.
            </div>
          </div>
        </Bounce>

        <Bounce duration={3000} right>
          <div className="landing-electronic-sign-items">
            <SiWebmoney className="landing-electronic-sign-items-icon" />
            <div className="landing-electronic-sign-items-title">
              Digital Transaction Management
            </div>
            <div className="landing-electronic-sign-items-content">
              DKBSign, c’est bien plus que de la signature électronique. C’est
              la digitalisation de tous vos processus. Vous économisez de
              l’argent, augmentez votre productivité et améliorez la
              satisfaction de vos clients.
            </div>
          </div>
        </Bounce>
      </div>
    </div>
  );
};

const Certificat = ({ IMAGES }) => {
  const [certificats] = useState([
    {
      id: 1,
      image: IMAGES.img14
        ? `${BASE_URL_IMG}/${IMAGES.img14}`
        : './assets/media/home_icon/cert_eidas.png',
      alt: IMAGES.alt14 ? `${IMAGES.alt14}` : 'image_entreprise',
      description: 'DKBSign est qualifié selon le règlement européen eIDAS',
    },
    {
      id: 2,
      image: IMAGES.img15
        ? `${BASE_URL_IMG}/${IMAGES.img15}`
        : './assets/media/home_icon/logo1.png',
      alt: IMAGES.alt15 ? `${IMAGES.alt15}` : 'image_entreprise',
      description:
        "DKBSign est membre  de l'organisation nationale des tiers de confiance numérique",
    },
    {
      id: 3,
      image: IMAGES.img16
        ? `${BASE_URL_IMG}/${IMAGES.img16}`
        : './assets/media/home_icon/gotic-removebg-preview.png',
      alt: IMAGES.alt16 ? `${IMAGES.alt16}` : 'image_entreprise',
      description:
        'DKBSign est membre du Groupement des Opérateurs du Secteur des Technologies de l’Information et de la Communication de Côte d’Ivoire (GOTIC CI)',
    },
    {
      id: 4,
      image: IMAGES.img17
        ? `${BASE_URL_IMG}/${IMAGES.img17}`
        : './assets/media/home_icon/logo-LSTI-relief.png',
      alt: IMAGES.alt17 ? `${IMAGES.alt17}` : 'image_entreprise',
      description: 'DKBSolutions est certifié LSTI ETSI TS 102 042',
    },
    {
      id: 5,
      image: IMAGES.img18
        ? `${BASE_URL_IMG}/${IMAGES.img18}`
        : './assets/media/home_icon/ARTCI-removebg-preview.png',
      alt: IMAGES.alt18 ? `${IMAGES.alt18}` : 'image_entreprise',
      description:
        "DKBSign est conforme aux exigences- de loi sur la protection des données à caractères personnel de l'ARTCI",
    },
  ]);

  return (
    <div className="dkd">
      <Fade duration={4000} left>
        <div className="landing-certificat">
          {certificats.map((certs) => (
            <CertificatItem key={certs.id} certs={certs} />
          ))}
        </div>
        <div className="landing-certificat-desc">
          {certificats.map((certs) => (
            <CertificatItemDesc key={certs.id} certs={certs} />
          ))}
        </div>
      </Fade>
    </div>
  );
};

const CertificatItem = ({ certs }) => {
  return (
    <div className="landing-certificat_items">
      <img
        className="landing-certificat_items_img"
        src={certs.image}
        alt="certificat"
      />
    </div>
  );
};

const CertificatItemDesc = ({ certs }) => {
  return (
    <div
      style={{ height: '0px', justifyContent: 'normal' }}
      className="landing-certificat_items"
    >
      <div className="landing-certificat_items-description">
        {certs.description}
      </div>
    </div>
  );
};
