import React from 'react';
import Navbar from '../Navbar/Navbar';
import Toolbar from '../Toolbar/Toolbar';
import Footer from '../Footer/Footer';
import { useLocation } from 'react-router-dom';

/**
 *
 * Composant d'envellope principale
 * @param {children} param0
 * @returns
 */
export default function Container({ children }) {
  const { pathname } = useLocation();

  const isViewdocumentPage = pathname === '/viewdocument';

  console.log('isViewdocumentPage', isViewdocumentPage);

  return (
    <React.Fragment>
      <div className="d-flex flex-column flex-root">
        <div className="page d-flex flex-row flex-column-fluid">
          <div
            className="wrapper d-flex flex-column flex-row-fluid"
            id="kt_wrapper"
          >
            <Navbar />
            {!isViewdocumentPage && <Toolbar />}
            <div
              id="kt_content_container"
              className="d-flex flex-column-fluid align-items-start container-xxl"
            >
              <div className="content flex-row-fluid" id="kt_content">
                <div
                  id="kt_content_container"
                  className="d-flex flex-column-fluid align-items-start container-xxl"
                >
                  <div className="content flex-row-fluid" id="kt_content">
                    <div
                      className="row g-5 g-xl-10 mb-xl-10"
                      style={{ width: '100%' }}
                    >
                      {children}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
}
