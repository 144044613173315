import React, { useState, useEffect } from 'react'
import LandingWrapper from './LandingWrapper'
import { DemanderDevisModal } from './Modal'
import { AiOutlineCheck } from 'react-icons/ai'
import { Helmet } from "react-helmet";
import { GetReferencement, GetImagesByPage } from './Admin/Main/services'
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';
import SpinnerPage from './SpinnerPage';
import Bounce from 'react-reveal/Bounce';
import './styles.css'

var empty_img = {
    "img1": null,
    "alt1": null,
    "img2": null,
    "alt2": null,
    "img3": null,
    "alt3": null,
    "img4": null,
    "alt4": null,
    "img5": null,
    "alt5": null,
    "img6": null,
    "alt6": null,
    "img7": null,
    "alt7": null,
    "img8": null,
    "alt8": null,
    "img9": null,
    "alt9": null,
    "img10": null,
    "alt10": null,
    "img11": null,
    "alt11": null,
    "img12": null,
    "alt12": null,
    "img13": null,
    "alt13": null,
    "img14": null,
    "alt14": null,
    "img15": null,
    "alt15": null,
    "img16": null,
    "alt16": null,
    "img17": null,
    "alt17": null,
    "img18": null,
    "alt18": null,
    "img19": null,
    "alt19": null,
    "img20": null,
    "alt20": null
}

const PAGE_NAME = 'TARIF'
const BASE_URL_IMG = `https://dkbsign.com/dkbsign_web_api/public/storage/websiteimages/${PAGE_NAME}`

export default function LandingSolution() {
    const [referencement, setReferencement] = useState(null)
    const [IMAGES, SETIMAGES] = useState(null)

    useEffect(() => {
        GetReferencement().then(source => setReferencement(source))
        GetImagesByPage({ cible: PAGE_NAME }).then(source => SETIMAGES(source))
    }, [])

    return (
        IMAGES ?
            <LandingWrapper>
                <Helmet>
                    <title>
                        {
                            referencement ?
                                referencement.find((dt) => dt.pageCible === PAGE_NAME) ? referencement.find((dt) => dt.pageCible === PAGE_NAME).siteTitle : "DKBSign"
                                : "DKBSign"
                        }
                    </title>
                    <meta
                        name="description"
                        content={
                            referencement ?
                                referencement.find((dt) => dt.pageCible === PAGE_NAME) ? referencement.find((dt) => dt.pageCible === PAGE_NAME).meta_description : ""
                                : ""
                        }
                    />

                    <meta
                        name="keywords"
                        lang="fr"
                        content={
                            referencement ?
                                referencement.find((dt) => dt.pageCible === PAGE_NAME) ? referencement.find((dt) => dt.pageCible === PAGE_NAME).meta_keywords : ""
                                : ""
                        }
                    />

                    <meta name="robots" content={
                        referencement ?
                            referencement.find((dt) => dt.pageCible === PAGE_NAME) ? referencement.find((dt) => dt.pageCible === PAGE_NAME).meta_robots : ""
                            : ""
                    }
                    />
                    <meta name="category" content={
                        referencement ?
                            referencement.find((dt) => dt.pageCible === PAGE_NAME) ? referencement.find((dt) => dt.pageCible === PAGE_NAME).meta_category : ""
                            : ""
                    }
                    />

                    <meta name="identifier-url" content={
                        referencement ?
                            referencement.find((dt) => dt.pageCible === PAGE_NAME) ? referencement.find((dt) => dt.pageCible === PAGE_NAME).meta_identifier_url : ""
                            : ""
                    }
                    />

                    <meta name="reply-to" content={
                        referencement ?
                            referencement.find((dt) => dt.pageCible === PAGE_NAME) ? referencement.find((dt) => dt.pageCible === PAGE_NAME).meta_reply_to : ""
                            : ""
                    }
                    />
                </Helmet>
                <Header />
                <Tarif IMAGES={IMAGES[0] ? IMAGES[0] : empty_img}  />
                <FAQArea />
                <ButtomDescription />
            </LandingWrapper>
            :
            <div className='global-loader'>
                <SpinnerPage />
            </div>
    )
}

const FAQArea = () => {

    const [items] = useState([
        {
            id: 1,
            title: '1.	Comment gérer mon pack de signature ?',
            description:
                <>
                    <p>Nous proposons à nos clients plusieurs packs pour la signature électronique de leurs documents. Nous avons :</p>
                    <li>Le pack starter de (100 à 3000 signatures),</li>
                    <li>Le pack pro (3001 à 10000 signatures),</li>
                    <li>Le pack (10001 à 50000 signatures)</li>
                    <li>Le pack premium plus (50001 à 100000 signatures) et</li>
                    <li>Le pack entreprise 100000 et plus.</li>
                    <p>Plus le volume de signature demandé est élevé et le cout à la signature est plus bas. En fonction du volume de signature choisi le client peut signer des documents à tout moment jusqu’à expiration du volume choisit. Par contre le certificat électronique qui va permettre de faire la signature devra être renouveler chaque année.</p>
                </>
        },
        {
            id: 2,
            title: '2.	En dehors du volume de signature doit on payer une licence pour les utilisateurs ?',
            description:
                <>
                    <p>Oui pour chaque utilisateur il y a une licence d’utilisation qui doit être payée.</p>
                    <p>NB: les utilisateurs de la plateforme sont différents de tous ceux qui vont faire la signature.</p>
                    <p>Les utilisateurs ont obligatoirement une licence et tous les autres signataires ne peuvent que signer à partir de la plateforme les documents qui leurs sont envoyés.</p>
                </>
        },
        {
            id: 3,
            title: '3.	Offrez vous une garantie de remboursement ?',
            description:
                <>
                    <p>Si par rapport à une circonstance bien précise le client, souhaite se faire rembourser, une pénalité sera appliquée de sorte à lui rembourser 90% du montant qui lui devrait être du.</p>
                </>
        },
        {
            id: 4,
            title: '4.  Tous les signataires doivent ils avoir un compte DKBSign ?',
            description:
                <>
                    <p>
                        Non, les destinataires de vos documents n’ont pas besoin de compte pour signer avec DKBSign. Votre document sera envoyé par e-mail à partir no-reply@dkbsign.com
                        et les destinataires pourront examiner le document, adopter une signature et compléter le processus de signature sans avoir un compte DKBSign.
                    </p>
                </>
        },
        {
            id: 5,
            title: '5.  Combien coûte DKBSign ?',
            description:
                <>
                    <p>Dkbsign est facturé sur le nombre de licence utilisateur et le volume de signature.</p>
                </>
        },
        {
            id: 6,
            title: '6.	Comment est décomptée ma consommation de signature ?',
            description:
                <>
                    <p>Une signature est décomptée à partir du moment où elle est apposée.</p>
                    <p>Exemple : Si un document doit être signé par 4 signataires et qu'uniquement 3 signataires signent le document, 3 signatures seront tout de même décomptées .</p>
                </>
        }
    ])

    return (
        <div className="landing-tarif__faq-area">
            <Bounce duration={4000} left>
                <div className="landing-tarif__faq-area-left">
                    <div className="landing-tarif__faq-area-left-title">
                        Foire <br />
                        Aux <br />
                        Questions
                    </div>
                </div>
            </Bounce>

            <Bounce duration={4000} right>
                <div className="landing-tarif__faq-area-right">
                    {items.map((item) => <Accordion key={item.id} items={item} />)}
                </div>
            </Bounce>
        </div>
    )
}

const Header = () => {
    return (
        <React.Fragment>
            <div className="landing-tarif__body-content">
                <div className="flip-header-tarif">
                    <Fade duration={4000} left>
                        <div className="landing-tarif__body-content-left-title">
                            <h1>Forfaits et Tarifs standards</h1>
                            <p>Choisisser la solution de signature electronique</p>
                            <p className='N01'>N°1</p>
                        </div>
                    </Fade>
                </div>
            </div>
        </React.Fragment>
    )
}

const Tarif = ({ IMAGES }) => {
    const [data] = useState([
        {
            id: 1,
            title: 'Certificat personne physique',
            include: [
                'Sécurise tous les sous-domaines avec un seul certificat',
                'Sécurise un nombre illimité de serveurs',
                'Accélération de la vitesse de chargement pour une meilleure performance'
            ],
            price: '641.390CFA',
            href: '/load/Formulaire_Certificat_Personne_Physique_V2.0.pdf'
        },
        {
            id: 2,
            title: 'Certificat SSL',
            include: [
                'Active le protocole« https »',
                'Crédibilité du site avec cadenas vert',
                'Identité de votre entreprise'
            ],
            price: '277.300CFA',
            href: '/load/Formulaire_Certificat_SSL_OV_V2.0 (1).pdf'
        },
        {
            id: 3,
            title: 'Certificat cachet serveur',
            include: [
                'Logiciel',
                'Signatures de documents automatique',
                'Crédibilité des documents'
            ],
            price: '463.000CFA',
            href: '/load/Formulaire_Certificat_Cachet_Serveur_V2.0.pdf'
        },
        {
            id: 4,
            title: 'certificat SSL WILDCARD',
            include: [
                'Logiciel',
                'Signatures de documents automatique',
                'Crédibilité des documents'
            ],
            price: '463.000CFA',
            href: '/load/Formulaire_Certificat_SSL_OV_V2.0 (1).pdf'
        }
    ])
    const [showModal, setShowModal] = useState(false)

    return (
        <React.Fragment>
            {showModal && <DemanderDevisModal showModal={showModal} setShowModal={setShowModal} />}
            <div className="landing-tarif__">
                <div className="landing-tarif__menu-sec">
                    <div
                        className="landing-tarif__menu active-tarif">Annuel</div>
                </div>

                <div className="landing-tarif__pack">
                    {data.map((item) => <TarifItem key={item.id} IMAGES={IMAGES} tarif={item} />)}
                </div>

                <Fade duration={4000} left>
                    <div className="landing-tarif_buttm-desc">
                        Signez simplement vos documents en quelques clics ! Notre solution de signatures électroniques confère une valeur probante aux documents signés conformément à la réglementation de l'ARTCI.
                    </div>
                    <div
                        onClick={() => setShowModal(true)}
                        style={{ width: '250px' }}
                        className="landing-tarif__menu-devis active-tarif-devis">
                        Demander un devis
                    </div>
                </Fade>
            </div>
        </React.Fragment>
    )
}

const TarifItem = ({ tarif, IMAGES }) => {
    return (
        <React.Fragment>
            <Zoom left duration={1300}>
                <div className="landing-tarif__pack-items">
                    <div className="landing-tarif__pack-item-title">{tarif.title}</div>
                    <div className="landing-tarif__pack-item-icon">
                        <img 
                            src={IMAGES.img1 ? `${BASE_URL_IMG}/${IMAGES.img1}` : "./assets/media/home_icon/Certificat.png" }
                            alt={IMAGES.alt1 ? `${IMAGES.alt1}` : ""}
                            style={{ width: '70px' }} 
                            color={'white'} 
                        />
                    </div>
                    <div className="landing-tarif__pack-item-price">{tarif.price}</div>
                    <div className="landing-tarif__pack-item-include">
                        {
                            tarif.include.map((item) =>
                                <p className='item-inclued'>
                                    <AiOutlineCheck className='icon-check' />
                                    {item}
                                </p>
                            )
                        }
                    </div>
                    <a target='_blank' href={tarif.href} className="landing-tarif__pack-item-btn">COMMANDER</a>
                </div>
            </Zoom>
        </React.Fragment>
    )
}

const Integration = () => {
    return (
        <React.Fragment>
            <div className="landing-tarif__faq-content">
                <div className="landing-tarif__faq-header">
                    <h2>FAQ</h2>
                </div>
            </div>
        </React.Fragment>
    )
}

const ButtomDescription = () => {
    return (
        <React.Fragment>
            <div className="landing-tarif__buttom-desc">
                Si vous souhaitez en savoir plus sur nos solutions avancées ou si vous avez besoin de plus de utilisateur, appelez le +225 22 47 00 50 pour parler à un conseiller commercial. L'option de remboursement sous 30 jours peut être utilisée une seule fois par client. Lorsque vous achetez un forfait DKBSign, vous devez accepter les Conditions générales et la Politique de confidentialité afin d'utiliser les forfaits de service DKBSign. Ces conditions contiennent des informations importantes sur les frais et les modalités de paiement, le remboursement des soldes, et sur l'utilisation et l'annulation des comptes. Les annulations et les remboursements seront traités exclusivement en conformité avec les dispositions énoncées dans les Conditions générales.
            </div>
        </React.Fragment>
    )
}

const Accordion = ({ items }) => {
    const [show, setShow] = useState(false)

    return (
        <React.Fragment>
            <div className="accordion">
                <div
                    onClick={() => setShow(!show)}
                    className="accordion-header"
                >
                    <div className="accordion-header-title">{items.title}</div>
                    <div className="accordion-header-icon">{show ? '-' : '+'}</div>
                </div>
                <div className={show ? 'accordion-body body-display' : 'accordion-body'}>
                    <div className="accordion-body-description">{items.description}</div>
                </div>
            </div>
        </React.Fragment>
    )
}