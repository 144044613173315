import React from 'react'
import { useTranslateContext } from '../../../hooks';

export default function SignInAdmin() {
  const { translate } = useTranslateContext();

  return (
  <div className="d-flex flex-column flex-root">
  <div
    className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed"
    style={{
      backgroundImage:
        "url(/metronic8/demo2/assets/media/illustrations/sigma-1/14-dark.png"
    }}
  >
    <div className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
      <a href="../../../index-2.html" className="mb-12">
        <img
          alt="Logo"
          src="../../../assets/media/logos/logo-2.svg"
          className="h-40px"
        />
      </a>
      <div className="w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto">
        <form
          className="form w-100"
          noValidate="novalidate"
          id="kt_sign_in_form"
          data-kt-redirect-url="/metronic8/demo2/../demo2/index.html"
          action="#"
        >
          <div className="text-center mb-10">
            <h1 className="text-dark mb-3">Connexion Admin DKB-Sign </h1>
          </div>
          <div className="fv-row mb-10">
            <label className="form-label fs-6 fw-bolder text-dark">
              Email
            </label>
            <input
              className="form-control form-control-lg form-control-solid"
              type="text"
              name="email"
              autoComplete="off"
            />
          </div>
          <div className="fv-row mb-10">
            <div className="d-flex flex-stack mb-2">
              <label className="form-label fw-bolder text-dark fs-6 mb-0">
                Password
              </label>
            </div>
            <input
              className="form-control form-control-lg form-control-solid"
              type="password"
              name="password"
              autoComplete="off"
            />
          </div>
          <div className="text-center">
            <button
              type="submit"
              id="kt_sign_in_submit"
              className="btn btn-lg btn-primary w-100 mb-5"
            >
              <span className="indicator-label">{translate.admin.valider}</span>
              <span className="indicator-progress">
                Please wait...
                <span className="spinner-border spinner-border-sm align-middle ms-2" />
              </span>
            </button>
          </div>
        </form>
      </div>
    </div>
    <div className="d-flex flex-center flex-column-auto p-10">
      <div className="d-flex align-items-center fw-bold fs-6">
        <a
          href="https://keenthemes.com/"
          className="text-muted text-hover-primary px-2"
        >
          About
        </a>
        <a
          href="mailto:support@keenthemes.com"
          className="text-muted text-hover-primary px-2"
        >
          Contact
        </a>
        <a
          href="https://1.envato.market/EA4JP"
          className="text-muted text-hover-primary px-2"
        >
          Contact Us
        </a>
      </div>
    </div>
  </div>
</div>
  )
}
