import React from 'react'
import './styles.css'

import { useTranslateContext } from '../../hooks';

function set20LastDaysToInput() {
    const today = new Date();
    const tenDaysLater = new Date();
    tenDaysLater.setDate(today.getDate() - 20);
   
    const year = tenDaysLater.getFullYear();
    const month = String(tenDaysLater.getMonth() + 1).padStart(2, '0');
    const day = String(tenDaysLater.getDate()).padStart(2, '0');

    const formattedDate = `${year}-${month}-${day}`;

    return formattedDate
}

function setCurrentDateToInput() {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');

    const formattedDate = `${year}-${month}-${day}`;

    return formattedDate
}

export default function DateRangePicker({ setFromDate, setToDate, onApplyDateFilter }) {
    const { translate } = useTranslateContext();
    const setBeginDate = (e) =>{
        const { value } = e.target
        setFromDate(value)
    }

    const setEndDate = (e) =>{
        const { value } = e.target
        setToDate(value)
    }

    return (
        <div className='date-range-picker'>
            <input type="date" className='date-rang-input' id="from" onChange={(e)=> setBeginDate(e)} defaultValue={set20LastDaysToInput()} name="from" />
            <input type="date" className='date-rang-input' defaultValue={setCurrentDateToInput()} id="to" onChange={(e)=> setEndDate(e)} name="to" />
            <a onClick={()=>  onApplyDateFilter()} className='aply-fltr-btn' href="#">{translate.admin.appliquerLeFiltre}</a>
        </div>
    )
}
