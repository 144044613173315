import React from 'react'
import { Link } from 'react-router-dom'
import { RiLogoutCircleLine } from 'react-icons/ri';
import Cookies from 'js-cookie'


const Navbar = () => {

    const switchMenu = (e) =>{
        const element = e.target.parentElement.parentElement
        if(element.classList.contains('here')) return
        var mnu = document.getElementsByClassName('menu-parents')
        for (let i=0; i < mnu[0].children.length; i++) {
            console.log('mnu: ',mnu[0].children[i])
            mnu[0].children[i].classList.remove("here");
        }

        element.classList.add("here");
    }

    const logOut = () =>{
        Cookies.remove('token_admin')
        Cookies.remove('user_admin')

        window.location = '/home/admin'
    }

    return (
        <div id="kt_header" className="header align-items-stretch" data-kt-sticky="true" data-kt-sticky-name="header"
            data-kt-sticky-offset="{default: '200px', lg: '300px'}" style={{ "animationDuration": "0.3s", backgroundColor: '#0bb586' }}>
            <div className="container-xxl d-flex align-items-center">
                <div className="d-flex topbar align-items-center d-lg-none ms-n2 me-3" title="Show aside menu">
                    <div className="btn btn-icon btn-active-light-primary btn-custom w-30px h-30px w-md-40px h-md-40px"
                        id="kt_header_menu_mobile_toggle">
                        <span className="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z"
                                    fill="currentColor"></path>
                                <path opacity="0.3"
                                    d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z"
                                    fill="currentColor"></path>
                            </svg>
                        </span>
                    </div>
                </div>
                <div className="header-logo me-5 me-md-10 flex-grow-1 flex-lg-grow-0">
                    <a href="/">
                        <img alt="Logo" src="/assets/media/logos/logo.png" className="logo-default h-60px" />
                        <img alt="Logo" src="/assets/media/logos/logo.png" className="logo-sticky h-60px" />
                    </a>
                </div>
                <div className="d-flex align-items-stretch justify-content-between flex-lg-grow-1">
                    <div className="d-flex align-items-stretch" id="kt_header_nav">
                        <div className="header-menu align-items-stretch" data-kt-drawer="true"
                            data-kt-drawer-name="header-menu" data-kt-drawer-activate="{default: true, lg: false}"
                            data-kt-drawer-overlay="true" data-kt-drawer-width="{default:'200px', '300px': '250px'}"
                            data-kt-drawer-direction="start" data-kt-drawer-toggle="#kt_header_menu_mobile_toggle"
                            data-kt-swapper="true" data-kt-swapper-mode="prepend"
                            data-kt-swapper-parent="{default: '#kt_body', lg: '#kt_header_nav'}">
                            <div
                                className="menu-parents menu menu-lg-rounded menu-column menu-lg-row menu-state-bg menu-title-gray-700 menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-400 fw-bold my-5 my-lg-0 align-items-stretch"
                                id="#kt_header_menu" data-kt-menu="true">
                                <Link to={'/home/admin/actualite'} data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                                    data-kt-menu-placement="bottom-start"
                                    onClick={switchMenu}
                                    className="menu-item menu-lg-down-accordion me-lg-1">
                                    <span className="menu-link py-3">
                                        <span className="menu-title">Actualité</span>
                                        <span className="menu-arrow d-lg-none"></span>
                                    </span>
                                </Link>
                                <Link to={'/home/admin/client'} data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                                    data-kt-menu-placement="bottom-start"
                                    onClick={switchMenu}
                                    className="menu-item menu-lg-down-accordion me-lg-1">
                                    <span className="menu-link py-3">
                                        <span className="menu-title">Client</span>
                                        <span className="menu-arrow d-lg-none"></span>
                                    </span>
                                </Link>
                                <Link to={'/home/admin/referencement'} data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                                    data-kt-menu-placement="bottom-start"
                                    onClick={switchMenu}
                                    className="menu-item menu-lg-down-accordion me-lg-1">
                                    <span className="menu-link py-3">
                                        <span className="menu-title">Referencement</span>
                                        <span className="menu-arrow d-lg-none"></span>
                                    </span>
                                </Link>
                                <Link to={'/home/admin/devis'} data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                                    data-kt-menu-placement="bottom-start"
                                    onClick={switchMenu}
                                    className="menu-item menu-lg-down-accordion me-lg-1">
                                    <span className="menu-link py-3">
                                        <span className="menu-title">Devis</span>
                                        <span className="menu-arrow d-lg-none"></span>
                                    </span>
                                </Link>
                                <Link to={'/home/admin/contact'} data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                                    data-kt-menu-placement="bottom-start"
                                    onClick={switchMenu}
                                    className="menu-item menu-lg-down-accordion me-lg-1">
                                    <span className="menu-link py-3">
                                        <span className="menu-title">Contact</span>
                                        <span className="menu-arrow d-lg-none"></span>
                                    </span>
                                </Link>
                                <Link to={'/home/admin/image'} data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                                    data-kt-menu-placement="bottom-start"
                                    onClick={switchMenu}
                                    className="menu-item menu-lg-down-accordion me-lg-1">
                                    <span className="menu-link py-3">
                                        <span className="menu-title">Image</span>
                                        <span className="menu-arrow d-lg-none"></span>
                                    </span>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="topbar d-flex align-items-stretch flex-shrink-0">
                        <div className="d-flex align-items-center ms-1 ms-lg-3">
                            <a href="#"
                                style={{color:'red'}}
                                onClick={()=>logOut()}
                                className="btn btn-icon btn-active-light-primary btn-custom w-30px h-30px w-md-30px h-md-40px"
                                data-kt-menu-trigger="click" data-kt-menu-attach="parent"
                                data-kt-menu-placement="bottom-end" data-kt-menu-flip="bottom">
                               <RiLogoutCircleLine size={20} />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Navbar