import { useContext } from 'react';
//
import { TranslateContext } from '../context';

// ----------------------------------------------------------------------

export const useTranslateContext = () => {
  const context = useContext(TranslateContext);

  if (!context) throw new Error('useTranslateContext context must be use inside TranslateProvider');

  return context;
};
