import React, { useState } from 'react'
import { toast } from "react-toastify";
import { Formik, Form, ErrorMessage } from 'formik';
import axios from 'axios'
import * as Yup from "yup";
import './modal.css'
import './form.css'

const getMinDate = () => {
    const today = new Date();

    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);

    const year = tomorrow.getFullYear();
    const month = String(tomorrow.getMonth() + 1).padStart(2, '0');
    const day = String(tomorrow.getDate()).padStart(2, '0');

    const formattedDate = `${year}-${month}-${day}`;

    return formattedDate
}

export default function TryModal({ showModal, setShowModal }) {
    const [initialValues] = useState({
        nom: '',
        prenom: '',
        email: '',
        contact: '',
        fonction: '',
        motif: '',
        datedemo: '',
        entreprise: ''
    })

    const FormSchema = Yup.object().shape({
        nom: Yup.string()
            .required('Champs requis'),

        prenom: Yup.string()
            .required('Champs requis!'),

        email: Yup.string()
            .required('Champs requis!').email('Le format du mail est invalide'),

        contact: Yup.string()
            .required('Champs requis!'),

        fonction: Yup.string()
            .required('Champs requis!'),

        motif: Yup.string()
            .required('Champs requis!'),

        datedemo: Yup.string()
            .required('Champs requis!'),

        entreprise: Yup.string()
            .required('Champs requis!')
    });

    const sendData = (data) => {
        const { nom, prenom, email, contact, fonction, motif, datedemo, entreprise } = data;

        const formData = new FormData();
        formData.append('nom', nom);
        formData.append('prenom', prenom);
        formData.append('email', email);
        formData.append('contact', contact);
        formData.append('fonction', fonction);
        formData.append('motif', motif);
        formData.append('datedemo', datedemo);
        formData.append('entreprise', entreprise);

        const url = `https://dkbsign.com/dkbsign_web_api/public/api/contactdemo`;

        axios.post(url, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
            .then(response => {
                console.log(response.data);
                toast.success("Formulaire envoyé veuillez consulter vos mails", {
                    position: "bottom-left",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                setShowModal(false)
            })
            .catch(error => {
                console.error('Error posting data:', error);
            });
    };

    return (
        <div id="myModal" style={{ display: showModal ? 'block' : 'none' }} className="modal">
            <div className="modal-content-try">
                <h1 className='form-title'>Formulaire de demande de demo</h1>

                <Formik
                    initialValues={initialValues}

                    validationSchema={FormSchema}

                    onSubmit={async (values) => {

                        const dataSend = {
                            nom: values.nom,
                            prenom: values.prenom,
                            email: values.email,
                            contact: values.contact,
                            fonction: values.fonction,
                            motif: values.motif,
                            datedemo: values.datedemo,
                            entreprise: values.entreprise
                        }

                        sendData(dataSend)
                    }}
                >

                    {({ handleChange, handleBlur, handleSubmit, values }) => (
                        <Form>
                            <div className="form-try">
                                <div className="row-try">
                                    <Input label={'Nom'} name={'nom'} type={'text'} onChange={handleChange} value={values.nom} onBlur={handleBlur} />
                                    <Input label={'Prenom'} name={'prenom'} type={'text'} onChange={handleChange} value={values.prenom} onBlur={handleBlur} />
                                </div>

                                <div className="row-try">
                                    <Input label={'Email'} name={'email'} type={'text'} onChange={handleChange} value={values.email} onBlur={handleBlur} />
                                    <Input label={'Contact'} name={'contact'} type={'text'} onChange={handleChange} value={values.contact} onBlur={handleBlur} />
                                </div>

                                <div className="row-try">
                                    <Input label={'Fonction'} name={'fonction'} type={'text'} onChange={handleChange} value={values.fonction} onBlur={handleBlur} />
                                    <Input label={'Entreprise'} name={'entreprise'} type={'text'} onChange={handleChange} value={values.entreprise} onBlur={handleBlur} />
                                </div>

                                <TextArea label={'Motif de demande'} name={'motif'} onChange={handleChange} value={values.motif} onBlur={handleBlur} />

                                <Input label={'Date de demo'} min={getMinDate()} name={'datedemo'} type={'date'} onChange={handleChange} value={values.datedemo} onBlur={handleBlur} />
                            </div>

                            <div className="form-button-container">
                                <button className='valid-btn-try' onClick={handleSubmit}>Envoyer le formulaire</button>
                                <button className='valid-btn-try-cancel' onClick={() => setShowModal(false)}>Fermer</button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    )
}

const Input = ({ label, type, name, onChange, onBlur, value, min }) => {
    return (
        <div className="input-group-try">
            <label className="label-try">{label}</label>
            <input
                autoComplete="off"
                onBlur={onBlur}
                min={min}
                onChange={onChange}
                value={value}
                name={name}
                id={name}
                className="input-try"
                type={type}
            />
            <div />
            <ErrorMessage className="error-msg" name={name} component={'div'} />
        </div>
    )
}

const TextArea = ({ label, name, onChange, onBlur, value }) => {
    return (
        <div className="input-group-try">
            <label className="label-try">{label}</label>
            <textarea
                autoComplete="off"
                name={name}
                onBlur={onBlur}
                onChange={onChange}
                value={value}
                id={name}
                className="input-try"
                rows={8}
                cols={22}
            />
            <div />
            <ErrorMessage className="error-msg" name={name} component={'div'} />
        </div>
    )
}