import React, { useState } from 'react'
import ShowMotifModal from '../Modal/SignataireModal/ShowMotifModal'
import Cookies from 'js-cookie'
import axios from 'axios'

import { useTranslateContext } from '../../hooks';

const baseUrl = 'https://dkbsign.com/new_api_dkbsign/public/api'

/**
 * Ce composant représente une ligne de tableau(un enregistrement)
 * @returns RowTable
 */
export default function RowTable({ data }) {

    console.log(data.statut)
    const { translate } = useTranslateContext();
    const user = JSON.parse(Cookies.get('user'))
    const token = Cookies.get('token')

    const [motif, setMotif] = useState()

    const showDocName = (url_) => {
        const url = new URL(url_)
        const paths = url.pathname.split('/')
        const filename = paths[paths.length - 1]
        return filename.slice(0, 40) + '...'
    }

    function extractFileNameFromURL(url) {
        const urlParts = url.split('/');

        const fileName = urlParts[urlParts.length - 1];

        const cleanFileName = fileName.split('?')[0];

        return cleanFileName;
    }

    var getDateTime = function (timestamp) {
        var date = String(timestamp)

        var _date = date.split('T')[0]
        var _time = date.split('T')[1].split('.')[0]

        return _date + ' ' + _time
    }

    const goToConsulte = (e) => {
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };

        axios.patch(`${baseUrl}/signatures/consulter?document=${data.document}&token=${user.telephone}`, null, config)
            .then(res => {
                console.log('Your document has been wathed')
                window.location.reload()
            })
            .catch(err => console.error(err))
    }

    var entreprise = user.entreprise ? user.entreprise.raison_sociale : null

    return (
        <tr>
            <td>
                <div className="form-check form-check-sm form-check-custom form-check-solid">
                    <input className="form-check-input" type="checkbox" value="1" />
                </div>
            </td>

            <td>
                <div className="d-flex align-items-center">
                    <div className="ms-5">
                        <a href="edit-product.html" className="text-gray-800 text-hover-primary fs-5 fw-bolder" data-kt-ecommerce-product-filter="product_name">{showDocName(data.document)}</a>
                    </div>
                </div>
            </td>

            <td className="text-end pe-0">
                {data.statut === "en_cours" && <span className="badge en_cour-btn">{translate.admin.encours} </span>}
                {data.statut === "signe" && <span className="badge signer-btn">{translate.admin.signe} </span>}
                {data.statut === "refuse" && <span className="badge refuse-btn">{translate.admin.refuse} </span>}
                {data.statut === "en_attente" && <span className="badge en_attente-btn">{translate.admin.attente} </span>}
            </td>

            <td className="text-end pe-0" data-order="Published">
                <a
                    onClick={(e) => goToConsulte(e)}
                    href={`${entreprise === 'ANRMP' ? `https://www.dkbsigns.com/API3/Api_dkbsign3/include/DOCSIGN_ARNMP/${extractFileNameFromURL(data.document)}` : data.document}`}
                    target={'_blank'}
                    className="badge badge-light-success"
                >
               {translate.admin.voirLeDocument}
                </a>
            </td>

            <td>
                <div className="d-flex align-items-center">
                    <div className="ms-5">
                        <a href="#" className="text-gray-800 text-hover-primary fs-5 fw-bolder" data-kt-ecommerce-product-filter="product_name">{data.nom}</a>
                    </div>
                </div>
            </td>
            <td>
                <div className="d-flex align-items-center">
                    <div className="ms-5">
                        <a href="#" className="text-gray-800 text-hover-primary fs-5 fw-bolder" data-kt-ecommerce-product-filter="product_name">{getDateTime(data.updated_at)}</a>
                    </div>
                </div>
            </td>
            {
                data.motif &&
                (
                    <td className="text-end pe-0" data-order="Published">
                        <ShowMotifModal motif={motif} />
                        <a
                            onClick={() => {
                                setMotif(data.motif)
                            }}
                            href={'#'}
                            data-bs-toggle="modal"
                            data-bs-target="#MotifModal"
                            className="badge badge-light-success"
                        >Voir le motif
                        </a>
                    </td>
                )
            }
        </tr>
    )
}
