import React, { useEffect, useState } from 'react'
import { Document, Page, pdfjs } from 'react-pdf';
import { GoArrowRight, GoArrowLeft } from "react-icons/go";
import { FcCancel } from "react-icons/fc";
import { AiFillMinusSquare } from "react-icons/ai";
import { SignatairesSelector } from '../../services/reduxSlices/SignataireSlice';
import { signatureSelector } from '../../services/reduxSlices/SignatureSlice';
import { useSelector } from 'react-redux'
import Cookies from 'js-cookie';
import MainSignataireModal from '../../components/Modal/SignataireModal/MainSignataireModal';
import MotifModal from '../../components/Modal/SignataireModal/MotifModal';
import { useTranslateContext } from '../../hooks';
import './SignatairesStyles.css'

/**
 * 
 * Page de prévisualisation des documents
 * @param {} param0 
 * @returns PreviewDoc
 */
const pixelToMillimeter = (value) => value * 0.2645833333

export default function PreviewDoc() {
    const { langTranslate, translate } = useTranslateContext();
    
    const { signataire, currentDoc, status } = useSelector(SignatairesSelector)
    const { text } = useSelector(signatureSelector)

    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [isCancel, setIsCancel] = useState(false)
    const [motif, setMotif] = useState('')
    const [imIsChosing, setImIsChosing] = useState(false)
    const [imgCode, setImgCode] = useState(null)

    const signDocument = async () => {
        var ImagedataURL = text

        const datasended = {
            "Key_Api": "test@dkbsign7",
            "Id_cl": "0708155815",
            "signataire": "",
            "signataire2": "",
            "posX_signataire2": "",
            "posY_signataire2": "",
            "signataire3": "",
            "posX_signataire3": " ",
            "posY_signataire3": " ",
            "nbre_signatair": "1",
            "Code_ctr": "cl07",
            "nom_ctr": currentDoc.document,
            "posX": pixelToMillimeter(Math.max(0, parseFloat(currentDoc.positionX) * 1.35)),
            "posY": pixelToMillimeter(Math.max(0, parseFloat(currentDoc.positionY) * 1.35)),
            "posX_qr": currentDoc.qrcodePositionX ? currentDoc.qrcodePositionX : "",
            "posY_qr": currentDoc.qrcodePositionY ? currentDoc.qrcodePositionY : "",
            "taille_qr": 20,
            "img_signataire_png": ImagedataURL,
            "format_img_signataire": 1,
            "posX_Imgsign": pixelToMillimeter(Math.max(0, parseFloat(currentDoc.positionX) * 1.35)),
            "posY_Imgsign": pixelToMillimeter(Math.max(0, parseFloat(currentDoc.positionY) * 1.35)),
            "page_sign": currentDoc.page,
            "Largeur_img_signataire_png": pixelToMillimeter(200 * 1.35),
            "Hauteur_img_signataire_png": pixelToMillimeter(100 * 1.35),
            "initial": "",
            "qrcodeyes": currentDoc.qrcode ? "1" : "0"
        }

        const formData = new FormData();
        formData.append("data", JSON.stringify(datasended));
        formData.append('sendAlert', 1)

        const token = Cookies.get('token')

        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${token}`);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formData,
            redirect: 'follow'
        };

        fetch(`https://dkbsign.com/new_api_dkbsign/public/api/signatures/accepter?document=${currentDoc.document}&token=${signataire}&code_auth=${currentDoc.code_auth}&lang=${langTranslate}`, requestOptions)
            .then(result => {
                console.log(result.json())
                window.location.href = `/signataires/?signataire=${signataire}`
            })
            .catch(error => {
                console.log(error)
            });
    }

    const cancelDocument = () => {
        const datasended = {
            "Key_Api": "test@dkbsign7",
            "Id_cl": "0708155815",
            "signataire": "",
            "signataire2": "",
            "posX_signataire2": "",
            "posY_signataire2": "",
            "signataire3": "",
            "posX_signataire3": " ",
            "posY_signataire3": " ",
            "nbre_signatair": "1",
            "Code_ctr": "cl07",
            "nom_ctr": currentDoc.document,
            "posX": currentDoc.positionX * 0.264,
            "posY": currentDoc.positionY * 0.264,
            "posX_qr": "",
            "posY_qr": "",
            "taille_qr": 20,
            "img_signataire_png": '',
            "format_img_signataire": 1,
            "posX_Imgsign": currentDoc.positionX * 0.264,
            "posY_Imgsign": currentDoc.positionY * 0.264,
            "page_sign": currentDoc.page,
            "Largeur_img_signataire_png": 40,
            "Hauteur_img_signataire_png": 16,
            "initial": "",
            "qrcodeyes": "0"
        }

        const formData = new FormData();
        formData.append("data", JSON.stringify(datasended));
        formData.append("motif", motif);
        const token = Cookies.get('token')

        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${token}`);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formData,
            redirect: 'follow'
        };

        fetch(`https://dkbsign.com/new_api_dkbsign/public/api/signatures/refuser?document=${currentDoc.document}&token=${signataire}&code_auth=${currentDoc.code_auth}&lang=${langTranslate}`, requestOptions)
            .then(result => {
                console.log(result.json())
                window.location.href = `/signataires/?signataire=${signataire}`
            })
            .catch(error => {
                console.log(error)
            });
    }

    useEffect(() => {
        pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    }, [])

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    useEffect(() => {
        if (imIsChosing) {
            setTimeout(() => {
                var canvasWidth = 200;
                var canvasHeight = 100;

                var c = document.getElementById("signature-canvas");
                c.width = canvasWidth;
                c.height = canvasHeight;

                var ctx = c.getContext("2d");

                var base_image = new Image();
                base_image.src = text;
                base_image.onload = function () {
                    ctx.drawImage(base_image, 0, 0, base_image.width, base_image.height, 0, 0, 140, canvasHeight);
                };

                setImgCode(text)
            }, 1000);

            //signDocument()
        }
        if (isCancel) cancelDocument()
    }, [imIsChosing, isCancel])

    return (
        <React.Fragment>
            <MainSignataireModal setImIsChosing={setImIsChosing} />
            <MotifModal setIsCancel={setIsCancel} setMotif={setMotif} />
            <div className='content-sign'>
                <div className="container-page">
                    <div className="btn-pages">

                        {
                            pageNumber === 1 ?
                                (
                                    <div className="btn-switch" href="#" ><AiFillMinusSquare color='white' size={20} /></div>
                                ) :
                                (
                                    <div className="btn-switch" href="#" onClick={() => {
                                        pageNumber !== 1 && setPageNumber(pageNumber - 1)
                                    }}><GoArrowLeft color='white' size={20} /></div>
                                )
                        }

                        {
                            pageNumber === numPages ?
                                (
                                    <div className="btn-switch" href="#" ><AiFillMinusSquare color='white' size={20} /></div>
                                ) :
                                (
                                    <div className="btn-switch" href="#" onClick={() => {
                                        pageNumber !== numPages && setPageNumber(pageNumber + 1)
                                    }}><GoArrowRight color='white' size={20} /></div>
                                )
                        }

                    </div>

                    <div className='docview' onMouseDown={() => false} onSelect={() => false}>
                        <p style={{ color: 'black', margin: '5px' }}>Page <input className="btn-page" min="1" type="number" value={pageNumber} onChange={(e) => {
                            if (e.currentTarget.value === '') setPageNumber(0)
                            if (parseInt(e.currentTarget.value) <= numPages) {
                                setPageNumber(parseInt(e.currentTarget.value))
                            }
                        }} /> of {numPages}</p>

                        {status !== 'signe' && <canvas style={{ display: 'none' }} id="signature-canvas"></canvas>}
                        <div className="document">
                            <Document file={currentDoc.document} onLoadSuccess={onDocumentLoadSuccess}>
                                {
                                    (status !== 'signe' && currentDoc.page === pageNumber) &&
                                    <div style={{ top: currentDoc.positionY, left: currentDoc.positionX }} className="box-sign">
                                        <img src={imgCode ? imgCode : null} style={{ width: '200px', height: '95px' }} alt="Placer une signature ou qrcode" />
                                    </div>
                                }
                                <Page bookmark="Harry Potter and the Philosopher's Stone" width={600} pageNumber={pageNumber} />
                            </Document>
                        </div>
                        {
                            (status !== 'signe' && imgCode === null) ?
                                <div className="actions-signataire">
                                    <a href="#" className="me-4 btn btn-bg-white btn-active-color-primary btn-wh option-item" data-bs-toggle="modal" data-bs-target="#MainSignataireModal">
                                        <i className="bi bi-person-check-fill"></i> {translate.admin.signer}
                                    </a>
                                    <a href="#" className="me-4 btn btn-bg-white btn-active-color-primary btn-wh option-item cancel" data-bs-toggle="modal" data-bs-target="#MotifModal">
                                        <FcCancel size={20} />  {translate.admin.refuser}
                                    </a>
                                </div> :
                                <div className="actions-signataire">
                                    <a
                                        href="#"
                                        className="me-4 btn btn-bg-white btn-active-color-primary btn-wh option-item"
                                        onClick={async () => {
                                            await signDocument()
                                        }}
                                    >
                                        <i className="bi bi-person-check-fill"></i> {translate.admin.terminer}
                                    </a>
                                </div>
                        }
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
