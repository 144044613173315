import { useMemo, useState } from 'react';
import { TranslateContext } from './translate-context';
import { TRANSLATE_EN } from '../translate/en';
import { TRANSLATE_FR } from '../translate/fr';

export function TranslateProvider({ children }) {
  const [langTranslate, setLangTranslate] = useState('en');

  const toggleTranslate = (lang) => {
    setLangTranslate(lang ?? 'en');
  };

  const memoizedValue = useMemo(
    () => ({
      translate: langTranslate === 'fr' ? TRANSLATE_FR : TRANSLATE_EN,
      langTranslate: langTranslate,
      toggleTranslate: (e) => toggleTranslate(e),
    }),
    [langTranslate]
  );

  return (
    <TranslateContext.Provider value={memoizedValue}>
      {children}
    </TranslateContext.Provider>
  );
}
