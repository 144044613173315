import React, { useEffect } from 'react'
import { Document, Page, pdfjs } from 'react-pdf';
import { FcDocument } from "react-icons/fc";
import './style.css'

/**
 * 
 * Page de prévisualisation des documents
 * @param {setCurrentFile, docList, DOCUMENT, SETDOCUMENT, switchDocument} param0 
 * @returns PreviewDoc
 */
export default function PreviewDoc({ docList, DOCUMENT, SETDOCUMENT, switchDocument}) {

    const formatDoc = (docList) =>{
        var newDoc = []

        for (let i=0; i<docList.length; i++) {
            newDoc.push({'id': i+1, 'link' : docList[i], 'className' : i == 0 ? 'active' : '','nbSignature': 0, 'typeSign' : []})
        }

        return newDoc
    }

    useEffect(()=>{
        const lsDoc = formatDoc(docList)
        SETDOCUMENT(lsDoc)
        pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    },[])

    return (
        <div className="documents">
            <p style={{fontSize:17}}><FcDocument /> Documents</p>
            <div className="webviewer">
                {
                    DOCUMENT.map((doc, i) =>{
                        return(
                            <Document file={doc.link} key={i}>
                                <Page size="A4" onClick={()=> switchDocument(doc.link)} className={`pages-prev ${doc.className}`} pageNumber={1} />
                            </Document>
                        )
                    })
                }
            </div>
        </div>
    )
}
