import React, { useEffect, useState } from 'react'
import Container from '../../../../components/AdminLanding/Container/Container'
import ReferencementTable from '../../../../components/AdminLanding/Referencement/ReferencementTable'
import { GetReferencement } from './services'

export default function Referencement() {

    const [data, setData] = useState(null)

    useEffect(() => {
        GetReferencement().then(source => setData(source))
    }, [])
    
    return (
        <Container>
            <ReferencementTable data={data} />
        </Container>
    )
}
