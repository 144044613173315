import React from 'react'
import { deleteContact } from '../../../services/reduxSlices/UserSlice'
import { useDispatch } from 'react-redux';

import { useTranslateContext } from '../../../hooks';

export default function Delete({showDelete, setShowDelete, data}) {
  const dispatch = useDispatch()

  const {translate} = useTranslateContext();

  return (
    <div
        className={showDelete ? "modal fade show" : "modal fade"}
        id="kt_modal_new_target"
        tabIndex={-1}
        style={{ display: showDelete ? "block" : "none", marginTop: '0px' }}
    >
        <div className="modal-dialog modal-dialog-centered mw-650px">
          <div className="modal-content rounded" style={{width: '100%'}}>
            <div className="modal-header pb-0 border-0 justify-content-end">
            </div>
            <h2 style={{textAlign:'center', marginBottom:'25px'}}>Voulez vous vraiment supprimer cet contact ?</h2>
            <div className="text-center">
              <button
                type="reset"
                id="kt_modal_new_target_cancel"
                onClick={()=> setShowDelete(false)}
                className="btn btn-light me-3"
              >
                {translate.admin.annuler}
              </button>
              
              <button
                type="submit"
                  onClick={()=>{
                    setShowDelete(false)
                    dispatch(deleteContact(data.id))
                  }}
                  data-bs-dismiss="modal"
                  style={{color:'red'}}
                  className="btn"
                >
                  <span className="indicator-label">{translate.admin.supprimer}</span> 
              </button>
            </div>
          </div>
        </div>
      </div>
  )
}
