import React from 'react';
import { Navigate } from 'react-router-dom';
import Cookies from 'js-cookie';

export default function PrivateManageRoute({ children }){
    const isAuthenticated = Cookies.get('token_admin') ? true : false;    

    if (isAuthenticated) return children
      
    return <Navigate to="/home/admin" /> 
}