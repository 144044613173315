import React, { useState, useEffect } from 'react'
import LandingWrapper from './LandingWrapper'
import { Helmet } from "react-helmet";
import { TryModal } from './Modal'
import Fade from 'react-reveal/Fade';
import { GetReferencement, GetImagesByPage } from './Admin/Main/services'
import SpinnerPage from './SpinnerPage';
import './styles.css'

var empty_img = {
  "img1": null,
  "alt1": null,
  "img2": null,
  "alt2": null,
  "img3": null,
  "alt3": null,
  "img4": null,
  "alt4": null,
  "img5": null,
  "alt5": null,
  "img6": null,
  "alt6": null,
  "img7": null,
  "alt7": null,
  "img8": null,
  "alt8": null,
  "img9": null,
  "alt9": null,
  "img10": null,
  "alt10": null,
  "img11": null,
  "alt11": null,
  "img12": null,
  "alt12": null,
  "img13": null,
  "alt13": null,
  "img14": null,
  "alt14": null,
  "img15": null,
  "alt15": null,
  "img16": null,
  "alt16": null,
  "img17": null,
  "alt17": null,
  "img18": null,
  "alt18": null,
  "img19": null,
  "alt19": null,
  "img20": null,
  "alt20": null
}

const PAGE_NAME = 'PRODUITS'
const BASE_URL_IMG = `https://dkbsign.com/dkbsign_web_api/public/storage/websiteimages/${PAGE_NAME}`

export default function LandingProduct() {
  const [referencement, setReferencement] = useState(null)
  const [IMAGES, SETIMAGES] = useState(null)

  useEffect(() => {
    GetReferencement().then(source => setReferencement(source))
    GetImagesByPage({ cible: PAGE_NAME }).then(source => SETIMAGES(source))
  }, [])
  
  return (
    IMAGES ?
      <LandingWrapper>
        <Helmet>
          <title>
            {
              referencement ?
                referencement.find((dt) => dt.pageCible === PAGE_NAME) ? referencement.find((dt) => dt.pageCible === PAGE_NAME).siteTitle : "DKBSign"
                : "DKBSign"
            }
          </title>
          <meta
            name="description"
            content={
              referencement ?
                referencement.find((dt) => dt.pageCible === PAGE_NAME) ? referencement.find((dt) => dt.pageCible === PAGE_NAME).meta_description : ""
                : ""
            }
          />

          <meta
            name="keywords"
            lang="fr"
            content={
              referencement ?
                referencement.find((dt) => dt.pageCible === PAGE_NAME) ? referencement.find((dt) => dt.pageCible === PAGE_NAME).meta_keywords : ""
                : ""
            }
          />

          <meta name="robots" content={
            referencement ?
              referencement.find((dt) => dt.pageCible === PAGE_NAME) ? referencement.find((dt) => dt.pageCible === PAGE_NAME).meta_robots : ""
              : ""
          }
          />
          <meta name="category" content={
            referencement ?
              referencement.find((dt) => dt.pageCible === PAGE_NAME) ? referencement.find((dt) => dt.pageCible === PAGE_NAME).meta_category : ""
              : ""
          }
          />

          <meta name="identifier-url" content={
            referencement ?
              referencement.find((dt) => dt.pageCible === PAGE_NAME) ? referencement.find((dt) => dt.pageCible === PAGE_NAME).meta_identifier_url : ""
              : ""
          }
          />

          <meta name="reply-to" content={
            referencement ?
              referencement.find((dt) => dt.pageCible === PAGE_NAME) ? referencement.find((dt) => dt.pageCible === PAGE_NAME).meta_reply_to : ""
              : ""
          }
          />
        </Helmet>
        <Header IMAGES={IMAGES[0] ? IMAGES[0] : empty_img} />
        <SwitchToNumerique IMAGES={IMAGES[0] ? IMAGES[0] : empty_img} />
        <Entreprises IMAGES={IMAGES[0] ? IMAGES[0] : empty_img} />
        <StrongPoint IMAGES={IMAGES[0] ? IMAGES[0] : empty_img} />
        <FAQArea />
      </LandingWrapper>
      :
      <div className='global-loader'>
        <SpinnerPage />
      </div>
  )
}

const Header = ({ IMAGES }) => {
  
  const [showModal, setShowModal] = useState(false)

  return (
    <React.Fragment>
      {showModal && <TryModal showModal={showModal} setShowModal={setShowModal} />}
      <div className="landing-product__body-content">
        <div style={{ right: '8%' }} className='flip-header'>
          <Fade duration={4000} left>
            <div className="landing-product__body-content-left">
              <div className="landing-product__body-content-left-title">
                <h1>Apposez votre signature</h1>
                <h3>en un clique</h3>
                <p>
                  Passez au tout numérique pour vos signatures de documents. <br />
                  Grâce à nos solutions de signature électronique, <br />
                  les entreprises et les particuliers peuvent automatiser <br />
                  leurs flux de données et signer n'importe quel document, <br />
                  en tout lieu, à tout moment et sur tous les appareils. <br />
                  Pour tous vos processus B2C, B2B ou même internes, <br />
                  nous avons la solution fluide et rapide. Passez du papier <br />
                  et des tâches manuelles à la dématérialisation!<br />
                </p>
              </div>
              <div
                onClick={() => setShowModal(true)}
                className="landing-product__body-content-left-button">
                DEMANDER UNE DEMO
              </div>
            </div>
            <div className="landing-home__body-content-right">
              <img
                src={IMAGES.img1 ? `${BASE_URL_IMG}/${IMAGES.img1}` : "./assets/media/home_icon/Contrat-solution.png"}
                className='landing-product__body-content-right_img_signataire'
                alt={IMAGES.alt1 ? `${IMAGES.alt1}` : ""}
              />
            </div>
          </Fade>
        </div>
      </div>
    </React.Fragment>
  )
}

const FAQArea = () => {

  const [items] = useState([
    {
      id: 1,
      title: '1. Qu\'est-ce que la signature électronique ?',
      description:
        <>
          <p>
            La signature électronique (dite aussi signature numérique) est un processus, utilisant des mécanismes de cryptologie, permettant de garantir l'intégrité d'un document électronique et d'en authentifier l'auteur, par analogie avec la signature manuscrite d'un document papier. Les signatures électroniques ont une valeur légale dans la majorité des cas dans la majorité des pays du monde entier.
            En Côte d’Ivoire, c’est l’ARTCI qui règlemente les différents types de signatures et leur usage. De plus en plus de sociétés dématérialisent leurs parcours de signature, par exemple, leurs contrats commerciaux, leurs contrats fournisseurs, documents RH, validations internes...
          </p>
        </>
    },
    {
      id: 2,
      title: '2. Les signatures électroniques que vous proposez sont-elles basées sur des certificats numériques ?',
      description:
        <>
          <p>
            Toutes nos transactions sont chiffrées et sécurisées grâce aux certificats électroniques. Il s’agit de certificats cachet serveur et à la volée pour les signatures dites « avancées » et des certificats personnels pour les signatures dites « qualifiées ». Une authentification (OTP/SMS, code d’identification ou login) est réalisée pour chaque signataire. Une fois le signataire authentifié, l’infrastructure de signature génère une clé privée dans un dispositif matériel sécurisé (HSM) et un certificat au nom du signataire. Ce certificat personnel à usage unique est alors utilisé pour réaliser la signature cryptographique insérée dans le document. Il est important de comprendre que la solution DKBSign, à différence d’autres solutions concurrentes, ne nécessite pas que les signataires possèdent au préalable un certificat de signature. Chez nous, c’est le système qui lui crée à la volée, de façon complètement transparente pour l’utilisateur.
          </p>
        </>
    },
    {
      id: 3,
      title: '3. Comment fonctionne la signature cloud ?',
      description:
        <>
          <p>Beaucoup de personnes encore aujourd’hui associent la signature électronique à des clés USB. Historiquement il fallait avoir une clé de signature pour signer électroniquement un document à partir de son PC. Aujourd’hui, grâce aux avancées de la technologie et du monde du cloud, n’importe qui peut signer un document en tout lieu, à tout moment, sur tous les appareils grâce à plateforme web DKBSign. C’est vraiment plus simple pour le signataire mais aussi pour la personne qui envoie le document à signer car elle ne doit plus se soucier de savoir si son signataire est en possession d’une clé de signature ou pas. Au cas où votre cas d’usage requiert de signer par clés USB sachez que DKBSolutions commercialise aussi une gamme complète de clés de signature électronique – il s’agit de notre offre de Token cryptographique.</p>
        </>
    },
    {
      id: 4,
      title: '4. Proposez-vous plusieurs offres de signature numérique ?',
      description:
        <>
          <p>
            Les usages de cette technologie sont tellement divers et variés que nous proposons plusieurs offres pour mieux répondre aux différents besoins. Nos offres s’articulent autour de trois (3) gammes de produits, la plateforme web, l’API et le Token.
            <li>
              Avec son interface utilisateur simple et intuitive, sa grande flexibilité dans les workflows gérés et le respect des contraintes des standards de la signature électronique. La plateforme web DKBSign est idéale pour toute entreprise qui ne possède pas d’application.
            </li>
            <li>
              L’API DKBSign est celle-là même qui s’intègre facilement dans les process déjà existants que vous pouvez choisir d’héberger dans votre infrastructure ou dans le cloud. C’est d’ailleurs la solution de signature la plus utilisé par nos clients.
            </li>
            <li>
              La clé Token est notre offre de signature standards qui permet de signer des documents à l’aide de cette clé cryptographique physique, utilisable pour d’autres besoins de signature en local sur votre PC.
            </li>
          </p>
        </>
    },
    {
      id: 5,
      title: '5.  Comment les destinataires signent-ils électroniquement les documents avec DKBSign ?',
      description:
        <>
          <p>Le destinataire d'un document n'a pas besoin d'être client DKBSign ou d'installer un quelconque élément sur son appareil. Il lui suffit de cliquer sur le lien pour ouvrir les documents proposés. Des balises et des instructions simples guident l'utilisateur tout au long du processus de signature.</p>
        </>
    },
    {
      id: 6,
      title: '6. Les signatures de DKBSign sont-elles sécurisées ?',
      description:
        <>
          <p>DKBSign respecte les meilleures pratiques existantes concernant la sécurisation de vos données et de vos transactions. Chaque signature numérique est unique, documentable, chiffrée et infalsifiable. DKBSign garantit la confidentialité de toutes les transactions et la protection des données clients.</p>
        </>
    },
    {
      id: 7,
      title: '7.  Puis-je signer électroniquement sur mon smartphone ou ma tablette ?',
      description:
        <>
          <p>
            La plateforme web est responsive et s’adapte à tous les types d’écrans que vous soyez sur PC, smartphone ou tablette. La plateforme web de DKBSign est disponible via le site www.dkbsign.com. Vous devez juste avoir un compte préalablement créé pour pouvoir utiliser les fonctionnalités de l’application.
          </p>
        </>
    },
    {
      id: 8,
      title: '8. Les signatures numériques de DKBSign ont-elles une valeur légale ?',
      description:
        <>
          <p>Nous proposons un service certifié, qui répond à un cadre juridique éprouvé et conforme aux standards internationaux. Nos signatures avec certificat respectent les exigences relatives à la loi sur transactions électroniques encadré et réglementé par L'ARTCI, selon le décret n°2014-106 12 mars 2014 Fixant les conditions d'établissement et de conservation de l'écrit et de la signature sous forme électronique.</p>
        </>
    },
    {
      id: 9,
      title: '9. Comment DKBSign facture-t-elle les utilisateurs ?',
      description:
        <>
          <p>Dkbsign est facturé sur le nombre de licence utilisateur et le volume de signature </p>
        </>
    },
    {
      id: 10,
      title: '10. Quelle est la position de DKBSign vis-à-vis des lois ivoirienne ?',
      description:
        <>
          <p>L’activité en Côte d’Ivoire est gérée par l’ARTCI. Nous sommes une société de droit Ivoirien. Nos activités et infrastructures respectent la législation Ivoirienne sur la protection des données personnelles, et bénéficient des agréments de Prestataire de Service de Certification électronique.</p>
        </>
    },
    {
      id: 11,
      title: '11. Les signatures électroniques de DKBSign sont-elles disponibles et valides en dehors de la Côte d`Ivoire ?',
      description:
        <>
          <p>DKBSign est conformes aux normes mondiales en matière de gestion transactions électronique. Vous pouvez signer vos documents là où vous conduisent vos activités, tout en étant sûrs que vos signatures sont légales et valides dans le temps.</p>
        </>
    }
  ])

  return (
    <div className="landing-tarif__faq-area">
      <Fade duration={3000} left>
        <div className="landing-tarif__faq-area-left">
          <div className="landing-tarif__faq-area-left-title">
            Foire <br />
            Aux <br />
            Questions
          </div>
        </div>
      </Fade>

      <Fade duration={5000} right>
        <div className="landing-tarif__faq-area-right">
          {items.map((item) => <Accordion key={item.id} items={item} />)}
        </div>
      </Fade>
    </div>
  )
}

const SwitchToNumerique = ({ IMAGES }) => {
  return (
    <div className="landing-product__switch-to-numerique">
      <Fade duration={3000} left>
        <div className="landing-product__switch-to-numerique_left-content">
          <img
            className='landing-product__switch-to-numerique_left-content-img'
            src={IMAGES.img2 ? `${BASE_URL_IMG}/${IMAGES.img2}` : "./assets/media/home_icon/Signature-telephone-.png"}
            alt={IMAGES.alt2 ? `${IMAGES.alt2}` : ""}
          />
        </div>
      </Fade>

      <Fade duration={3000} right>
        <div className="landing-product__switch-to-numerique_right-content">
          <div className="landing-product__switch-to-numerique_right-content-title">
            Passer au tout numérique pour <br />
            vos signatures, c'est legal
          </div>
          <div className="landing-product__switch-to-numerique_right-content-text">
            Faire signer les approbations, les accords et les contrats en mettant <br />
            en place des processus intégralement en ligne n'est pas seulement <br />
            un gain de temps et d'argent mais c'est aussi 100% légal en Côte d'Ivoire et <br />
            dans le monde entier. Nos offres sont certifiées et répondent à <br />
            un cadre juridique éprouvé. Nos signatures respectent les exigences de la
            « Signature Avancée » et de la « Signature Qualifiée » du <br />
            règlement européen eIDAS.

            <li>Plus d'info sur elDAS</li>
            <li>Plus d'info sur la valeur juridique des signatures électroniques.</li>
          </div>
        </div>
      </Fade>
    </div>
  )
}

const Entreprises = ({ IMAGES }) => {
  return (
    <div className="landing-product__entreprises">

      <div className="landing-product__entreprises-list">
        <img
          className='landing-product__entreprises-list-img'
          src={IMAGES.img3 ? `${BASE_URL_IMG}/${IMAGES.img3}` : "./assets/media/home_icon/Grande-entreprise.png"}
          alt={IMAGES.alt3 ? `${IMAGES.alt3}` : ""}
        />

        <img
          className='landing-product__entreprises-list-img'
          src={IMAGES.img4 ? `${BASE_URL_IMG}/${IMAGES.img4}` : "./assets/media/home_icon/banque.png"}
          alt={IMAGES.alt4 ? `${IMAGES.alt4}` : ""}
        />

        <img
          className='landing-product__entreprises-list-img'
          src={IMAGES.img5 ? `${BASE_URL_IMG}/${IMAGES.img5}` : "./assets/media/home_icon/Petite-entreprise.png"}
          alt={IMAGES.alt5 ? `${IMAGES.alt5}` : ""}
        />
      </div>

      <Fade duration={5000} left>
        <div className="landing-product__entreprises-text">
          Notre solution est utilisée par les entreprises de toutes tailles et dans <br />
          tous les secteurs d'activités. <br />
          Les fonctionnalités intuitives et innovantes répondent à vos besoins de <br />
          dématérialisation, des plus simples au plus avancés <br />
        </div>
      </Fade>
    </div>
  )
}

const StrongPoint = ({ IMAGES }) => {
  return (
    <div className="landing-product__strong-point">
      <Fade duration={4000} left>
        <div className="landing-product__strong-point-title">
          Signature electronique: les points <br />
          forts de DKBSign
        </div>
      </Fade>

      <div className="landing-product__strong-point-list">
        <div className="landing-product__strong-point-list-item">
          <div className="landing-product__strong-point-list-item-title">
            <img
              className='landing-product__strong-point-list-item-title-img'
              src={IMAGES.img6 ? `${BASE_URL_IMG}/${IMAGES.img6}` : "./assets/media/home_icon/Valeur-legale.png"}
              alt={IMAGES.alt6 ? `${IMAGES.alt6}` : ""}
            />
            <p>Signatures a valeur légale</p>
          </div>
          <div className="landing-product__strong-point-list-item-text">
            La signature DKBSign est 100% légale en Côte d'Ivoire. Nos signatures électroniques sont conformes la loi N°2013-546 du 30 juillet 2013 relative aux transactions électroniques et du décret d'application
            N° 2014-106 du 12 mars 2014 fixant les conditions d'établissement et de conservation de l'écrit et de la signature sous forme électronique, ont une valeur probante pour toutes les transactions commerciales en Côte d'Ivoire, en Afrique et dans le monde entier
          </div>
        </div>

        <div className="landing-product__strong-point-list-item">
          <div className="landing-product__strong-point-list-item-title">
            <img
              className='landing-product__strong-point-list-item-title-img'
              src={IMAGES.img7 ? `${BASE_URL_IMG}/${IMAGES.img7}` : "./assets/media/home_icon/Disponiblite.png"}
              alt={IMAGES.alt7 ? `${IMAGES.alt7}` : ""}
            />
            <p>Toujours disponible</p>
          </div>
          <div className="landing-product__strong-point-list-item-text">
            L'architecture système de DKBSign garantit une disponibilité totale de nos sites actifs avec réplication en temps réel, supprimant ainsi totalement la nécessité d'interruption de service pour maintenance. Ce système de redondance offre des performances élevées 24 heures sur 24, y compris lors des pics d'utilisation.
          </div>
        </div>

        <div className="landing-product__strong-point-list-item">
          <div className="landing-product__strong-point-list-item-title">
            <img
              className='landing-product__strong-point-list-item-title-img'
              src={IMAGES.img8 ? `${BASE_URL_IMG}/${IMAGES.img8}` : "./assets/media/home_icon/Offre-Globale.png"}
              alt={IMAGES.alt8 ? `${IMAGES.alt8}` : ""}
            />
            <p>Offre globale</p>
          </div>
          <div className="landing-product__strong-point-list-item-text">
            les avantages de nos processus d'accords numériques sont accessibles depuis le monde entier. Acteur global engagé localement, nous respectons les différentes législations et obligations nationales.
            Nous nous engageons
            notamment à ce que les documents de nos clients Afrciains soient gérés en
            Afrique.
          </div>
        </div>

        <div className="landing-product__strong-point-list-item">
          <div className="landing-product__strong-point-list-item-title">
            <img
              className='landing-product__strong-point-list-item-title-img'
              src={IMAGES.img9 ? `${BASE_URL_IMG}/${IMAGES.img9}` : "./assets/media/home_icon/Choix.png"}
              alt={IMAGES.alt9 ? `${IMAGES.alt9}` : ""}
            />
            <p>Vous avez le choix</p>
          </div>
          <div className="landing-product__strong-point-list-item-text">
            Chaque entreprise étant différente, nous vous garantissons des offres flexibles. Nous proposons
            plusieurs offres de signature électronique, personnalisables selon vos besoins métier. Nos
            API permettent aussi aux développeurs
            d'intégrer la signature numérique dans leurs
            applicatifs métiers.
          </div>
        </div>
      </div>
    </div>
  )
}

const Accordion = ({ items }) => {
  const [show, setShow] = useState(false)

  return (
    <React.Fragment>
      <div className="accordion">
        <div
          onClick={() => setShow(!show)}
          className="accordion-header"
        >
          <div className="accordion-header-title">{items.title}</div>
          <div className="accordion-header-icon">{show ? '-' : '+'}</div>
        </div>
        <div className={show ? 'accordion-body body-display' : 'accordion-body'}>
          <div className="accordion-body-description">{items.description}</div>
        </div>
      </div>
    </React.Fragment>
  )
}