export const LOGIN_URL = "/api/login";
export const ENTREPRISES_URL = "/api/entreprises";
export const CERTIFICAT_URL = "/api/certificats";
export const CONTACT_URL = "/api/contacts";
export const IMPORT_CONTACT_URL = "/api/contacts/importer";
export const USER_URL = "/api/utilisateurs";
export const SIGN_DOCUMENT = '/Api_dkbsign4/v1/Testdkbsign7'
export const GET_DOCUMENT = '/api/signatures'
export const GET_STATISTIC = '/api/signatures/statistiques'

export const ACTUALITE_URL = '/api/actualities'
export const CLIENT_URL = '/api/client'
export const REFERENCEMENT_URL = '/api/referencement'
export const IMAGE_URL = '/api/gestimages'

export const GET_CLIENT_URL = '/api/clients'

export const API_URL = '/api'