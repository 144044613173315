import React, { useEffect, useState } from 'react'
import Table from './Table'
import Row from './Row'
import ImportContact from './Modal/ImportContact'
import Forms from './Modal/Forms'
import Delete from './Modal/Delete'
import { getContact, userSelector, clearState } from '../../services/reduxSlices/UserSlice'
import { useDispatch, useSelector } from 'react-redux';

export default function Add() {

    const [search, setSearch] = useState()
    const [page, setPage] = useState(1)
    const [showForm, setShowForm] = useState(false)
    const [showDelete, setShowDelete] = useState(false)
    const [showImport, setShowImport] = useState(false)
    const [dataEdit, setDataEdit] = useState(null)

    const dispatch = useDispatch()
    const { ls_contacts, ctc_updated } = useSelector(userSelector)

    useEffect(() => {
        dispatch(getContact({ page, search: search ?? undefined }))
        dispatch(clearState())

    }, [ctc_updated, page])

    const onApplySearch = (e) => {
        e.preventDefault()
        const { value } = e.target
        setSearch(value)

        const data = {
            page: page,
            search: value
        }

        dispatch(getContact(data))
    }


    return (
        <React.Fragment>
            <Table onApplySearch={onApplySearch} setShowForm={setShowForm} setShowImport={setShowImport} setDataEdit={setDataEdit} maxPage={ls_contacts.data ? ls_contacts.meta.last_page : 0} setPage={setPage} currentPage={page}>
                {
                    ls_contacts.data &&
                    ls_contacts.data.map(it => <Row data={it} setShowForm={setShowForm} setDataEdit={setDataEdit} setShowDelete={setShowDelete} />)
                }
            </Table>
            {showForm ? <Forms showForm={showForm} setShowForm={setShowForm} data={dataEdit} /> : <></>}
            {showImport ? <ImportContact showForm={showImport} setShowForm={setShowImport} /> : <></>}
            {showDelete ? <Delete showDelete={showDelete} setShowDelete={setShowDelete} data={dataEdit} /> : <></>}
        </React.Fragment>
    )
}