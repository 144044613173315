import React from 'react';
import { Navigate } from 'react-router-dom';
import Cookies from 'js-cookie';

export default function VerifyConnectedManage({ children }){
    const isAuthenticated = Cookies.get('token_admin') ? true : false;    

    if (isAuthenticated) return <Navigate to="/home/admin/actualite" />

    return children
}