import React from 'react'
import './style.css'
import Loader from '../../Loader/Loader'
import { useTranslateContext } from '../../../hooks'

export default function LoaderDocs({showModal, erros, setShowModal}) {

    const {translate} = useTranslateContext();

        return (
            <div id="myModal" style={{display: showModal ? 'block' : 'none'}} className="modal">
                <div className="modal-content">
                    {
                        erros ? 
                            <>
                                <p className='txt-alert'>{erros}</p>
                                <div className="btn-area">
                                    <button className='okbtn' onClick={()=> {
                                        setShowModal(false)
                                    }}>{translate.admin.fermer}</button>
                                </div>
                            </>
                        : 
                        <>
                            <h4 style={{textAlign:'center'}}>{translate.admin.votreDocumentEstEnCoursDeSignature}</h4>
                            <Loader />
                        </>
                    }
                </div>
            </div>
        )
}
