import React, { useState, useEffect, useRef } from 'react'
import { pdfjs } from 'react-pdf';
import { FcCancel } from 'react-icons/fc';
import LoaderDocs from '../../components/Modal/LoaderDocs/LoaderDocs'
import MainSignataireModal from "../../components/Modal/SignataireModal/MainSignataireModal";
import CodeAuthmModal from "../../components/Modal/CodeAuthmModal";
import axios from "axios";
import { signatureSelector } from '../../services/reduxSlices/SignatureSlice'
import { setCurrentDoc, setCodeAuth, setSignataire, setStatus } from '../../services/reduxSlices/SignataireSlice';
import { useDispatch, useSelector } from 'react-redux'
import { Link } from "react-router-dom";

import Cookies from 'js-cookie';
import MotifModal from '../../components/Modal/SignataireModal/MotifModal';
import './SignatairesStyles.css'
import { useTranslateContext } from '../../hooks';

/**
 * Page principale pour effectuer une signature
 * @returns ViewPDF
 */

const pixelToMillimeter = (value) => value * 0.2645833333

export default function SignatairesViewDocument() {

    const { langTranslate, toggleTranslate } = useTranslateContext();

    const signSel = useSelector(signatureSelector)
    const buttonSignRef = useRef(null)
    const buttonCancelRef = useRef(null)

    const {translate} = useTranslateContext();

    const [isCancel, setIsCancel] = useState(false)
    const [motif, setMotif] = useState('')
    const [checked, setChecked] = useState([])
    const [codeAuth, setCodeAuth] = useState('')
    const [imIsChosing, setImIsChosing] = useState(false)
    const [allChecked, setAllChecked] = useState(false)
    const [signataire, setSignataire] = useState('')
    const [showModal, setShowModal] = useState(true) // edit after to true
    const [imageSign, setImageSign] = useState('')
    const [currentFile, setCurrentFile] = useState({})
    const [loader, setLoader] = useState(false)
    const [error, setError] = useState('')
    const [DOCUMENT, SETDOCUMENT] = useState([])
    const [imgCode] = useState({ uri: null, type: '', page: null })

    useEffect(() => {
        if (imIsChosing) {
            setTimeout(() => {
                var choice = signSel.choice
                var canvasWidth = 200;
                var canvasHeight = 100;

                if (choice === 'text') {

                    var c = document.getElementById("signature-canvas");
                    var ctx = c.getContext("2d");

                    var image = signSel.text;
                    var base_image = new Image();
                    base_image.src = image;
                    base_image.onload = function () {
                        ctx.drawImage(
                            base_image,
                            0,
                            0,
                            base_image.width,
                            base_image.height,
                            0,
                            0,
                            140,
                            canvasHeight
                        );
                    };

                } else if (choice === 'draw') {

                    var c = document.getElementById("signature-canvas");
                    var ctx = c.getContext("2d");

                    var draw = signSel.draw;
                    var base_image = new Image();
                    base_image.src = draw;
                    base_image.onload = function () {
                        ctx.drawImage(
                            base_image,
                            0,
                            0,
                            canvasWidth * 0.5,
                            canvasHeight * 0.5
                        );
                    };

                } else if (choice === 'image') {

                    var c = document.getElementById("signature-canvas");
                    var ctx = c.getContext("2d");

                    var image = signSel.image;
                    var base_image = new Image();
                    base_image.src = image;
                    base_image.onload = function () {
                        ctx.drawImage(
                            base_image,
                            0,
                            0,
                            base_image.width,
                            base_image.height,
                            0,
                            0,
                            canvasWidth,
                            canvasHeight
                        );
                    };

                }
                setImageSign(c.toDataURL())

                sendData(c.toDataURL())
            }, 1000);
        }
    }, [imIsChosing])

    useEffect(() => {
        document.querySelectorAll(".modal-backdrop").forEach(el => el.remove());
        pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    }, [imgCode])

    useEffect(() => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const signataire = urlParams.get('signataire')
        const lang = urlParams.get('lang')

        toggleTranslate(lang)

        setSignataire(signataire)
        axios.get(`https://dkbsign.com/new_api_dkbsign/public/api/signataires/${signataire}`)
            .then(res => {
                setCurrentFile(res.data[0])
                SETDOCUMENT(res.data)
            })
            .catch(err => {
                console.error(err)
            })
    }, [])

    const sendData = (img_signature) => {

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const signataire = urlParams.get('signataire')

        var list_a_signer = DOCUMENT.filter(dts => { if (checked.includes(dts.id)) return dts })

        var i = 0

        list_a_signer.map((docs, key) => {

            const datasended = {
                "Key_Api": "test@dkbsign7",
                "Id_cl": "0708155815",
                "signataire": "",
                "signataire2": "",
                "posX_signataire2": "",
                "posY_signataire2": "",
                "signataire3": "",
                "posX_signataire3": " ",
                "posY_signataire3": " ",
                "nbre_signatair": "1",
                "Code_ctr": "cl07",
                "nom_ctr": docs.document,
                "posX": pixelToMillimeter(Math.max(0, parseFloat(docs.positionX) * 1.35)),
                "posY": pixelToMillimeter(Math.max(0, parseFloat(docs.positionY) * 1.35)),
                "posX_qr": "",
                "posY_qr": "",
                "taille_qr": 20,
                "img_signataire_png": img_signature,
                "format_img_signataire": 1,
                "posX_Imgsign": pixelToMillimeter(Math.max(0, parseFloat(docs.positionX) * 1.35)),
                "posY_Imgsign": pixelToMillimeter(Math.max(0, parseFloat(docs.positionY) * 1.35)),
                "page_sign": docs.page,
                "Largeur_img_signataire_png": pixelToMillimeter(200 * 1.35),
                "Hauteur_img_signataire_png": pixelToMillimeter(100 * 1.35),
                "initial": "",
                "qrcodeyes": "0"
            }

            const formData = new FormData();
            formData.append("data", JSON.stringify(datasended));
            formData.append('sendAlert', key > 0 ? 0 : 1)

            const token = Cookies.get('token')

            var myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${token}`);

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formData,
                redirect: 'follow'
            };

            setLoader(true)
            fetch(`https://dkbsign.com/new_api_dkbsign/public/api/signatures/accepter?document=${docs.document}&token=${signataire}&code_auth=${codeAuth}&lang=${langTranslate}`, requestOptions)
                .then(result => {
                    if (i == list_a_signer.length - 1) {
                        console.log(result.json())
                        window.location.href = `/signataires/${window.location.search}`
                    }
                    i++
                })
                .catch(error => {
                    console.log(error)
                    setError('Une erreur est survenue l\'ors du traitement, veuillez ressayer')
                });
        })
    }

    const RefuserSigner = () => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const signataire = urlParams.get('signataire')

        var list_a_refuser = DOCUMENT.filter(dts => { if (checked.includes(dts.id)) return dts })

        list_a_refuser.map(docs => {

            const datasended = {
                "Key_Api": "test@dkbsign7",
                "Id_cl": "0708155815",
                "signataire": "",
                "signataire2": "",
                "posX_signataire2": "",
                "posY_signataire2": "",
                "signataire3": "",
                "posX_signataire3": " ",
                "posY_signataire3": " ",
                "nbre_signatair": "1",
                "Code_ctr": "cl07",
                "nom_ctr": docs.document,
                "posX": docs.positionX * 0.264,
                "posY": docs.positionY * 0.264,
                "posX_qr": "",
                "posY_qr": "",
                "taille_qr": 20,
                "img_signataire_png": imageSign,
                "format_img_signataire": 1,
                "posX_Imgsign": docs.positionX * 0.264,
                "posY_Imgsign": docs.positionY * 0.264,
                "page_sign": docs.page,
                "Largeur_img_signataire_png": 40,
                "Hauteur_img_signataire_png": 16,
                "initial": "",
                "qrcodeyes": "0"
            }

            const formData = new FormData();

            formData.append("data", JSON.stringify(datasended));
            formData.append("motif", motif);
            const token = Cookies.get('token')

            var myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${token}`);

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formData,
                redirect: 'follow'
            };

            setLoader(true)
            fetch(`https://dkbsign.com/new_api_dkbsign/public/api/signatures/refuser?document=${currentFile.document}&token=${signataire}&lang=${langTranslate}`, requestOptions)
                .then(result => {
                    console.log(result.json())
                    window.location.href = `/`
                })
                .catch(error => {
                    console.log(error)
                    setError('Une erreur est survenue l\'ors du traitement, veuillez ressayer')
                });
        })
    }

    const showDocName = (text) => { return text.slice(54, text.length) }

    const handleClickSigner = () => {
        if (checked.length > 0) {
            setLoader(false)
            setError('')
            buttonSignRef.current.click()
        } else {
            setLoader(true)
            setError(translate.admin.veuillezSelectionnerAuMoinsUnDocument)
        }
    }

    useEffect(() => {
        console.log('isCancel', isCancel)
        if (isCancel) RefuserSigner()
    }, [isCancel])

    return (
        currentFile ?
            (
                <div className="area-signataire">
                    <canvas id="signature-canvas"></canvas>
                    <div className="card list-doc-signataire">
                        <CodeAuthmModal codeAuth={codeAuth} showModal={showModal} setShowModal={setShowModal} setCodeAuth={setCodeAuth} />
                        <MainSignataireModal setImIsChosing={setImIsChosing} />
                        <MotifModal setIsCancel={setIsCancel} setMotif={setMotif} />

                        <LoaderDocs showModal={loader} erros={error} setShowModal={setLoader} />
                        <div className="header-signataires">
                            <a onClick={() => handleClickSigner()} style={{ boxShadow: 'rgb(9 30 66 / 25%) 0px 4px 8px -2px, rgb(9 30 66 / 8%) 0px 0px 0px 1px' }} href="#" className="custum-menu me-4 btn btn-bg-white btn-active-color-primary btn-wh option-item">
                                <i className="bi bi-person-check-fill"></i>{translate.admin.signerLeDocument}
                            </a>

                            <a ref={buttonSignRef} style={{ boxShadow: 'rgb(9 30 66 / 25%) 0px 4px 8px -2px, rgb(9 30 66 / 8%) 0px 0px 0px 1px', display: 'none' }} href="#" className="custum-menu me-4 btn btn-bg-white btn-active-color-primary btn-wh option-item" data-bs-toggle="modal" data-bs-target="#MainSignataireModal">
                                <i className="bi bi-person-check-fill"></i> {translate.admin.signerLeDocument}
                            </a>

                            <a href="#" onClick={() => {
                                setLoader(true)
                                if (checked.length === 0) {
                                    setError(translate.admin.veuillezSelectionnerLesDocumentsRefuser)
                                } else {
                                    setLoader(false)
                                    setError('')
                                    buttonCancelRef.current.click()
                                }
                            }} className="custum-menu me-4 btn btn-bg-white btn-active-color-primary btn-wh option-item refuser-btn">
                                <FcCancel size={18} />{translate.admin.refuserLeDocument}
                            </a>

                            <a href="#" ref={buttonCancelRef} style={{ display: 'none' }} className="custum-menu me-4 btn btn-bg-white btn-active-color-primary btn-wh option-item refuser-btn" data-bs-toggle="modal" data-bs-target="#MotifModal" >
                                <FcCancel size={18} /> {translate.admin.refuserLeDocument}
                            </a>
                        </div>
                        <div className="card-header border-0 pt-5">
                            <h3 className="card-title align-items-start flex-column">
                                <span className="card-label fw-bolder fs-3 mb-1">{translate.admin.selectionnezLeDocument} </span>
                            </h3>
                        </div>
                        <div className="card-body py-3">
                            <div className="table-responsive">
                                <table className="table align-middle gs-0 gy-4">
                                    <thead>
                                        <tr className="fw-bolder text-muted bg-light">
                                            <th className="ps-4 min-w-325px rounded-start">
                                                <input
                                                    onClick={() => {
                                                        setAllChecked(!allChecked)

                                                        if (!allChecked) {
                                                            // DOCUMENT
                                                            DOCUMENT.map(doc => setChecked((prevChecked) => [doc.id, ...prevChecked]))

                                                            var ele = document.getElementsByName('checkbox-element');
                                                            for (var i = 0; i < ele.length; i++) {
                                                                if (ele[i].type == 'checkbox')
                                                                    ele[i].checked = true;
                                                            }
                                                        } else {
                                                            setChecked([])
                                                            var ele = document.getElementsByName('checkbox-element');
                                                            for (var i = 0; i < ele.length; i++) {
                                                                if (ele[i].type == 'checkbox')
                                                                    ele[i].checked = false;
                                                            }
                                                        }
                                                    }}
                                                    className='sel-doc-checkbox'
                                                    type="checkbox"
                                                />
                                            </th>
                                            <th className="ps-4 min-w-325px rounded-start">Document</th>
                                            <th className="min-w-150px">Status</th>
                                            <th className="min-w-150px">Date</th>
                                            <th className="min-w-200px text-end rounded-end" />
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            // DOCUMENT
                                            DOCUMENT.map(doc => {
                                                return <Row signataire={signataire} allChecked={allChecked} data={doc} id={doc.id} name={showDocName(doc.document)} created_at={doc.created_at} updated_at={doc.updated_at} status={doc.statut} link={doc.document} checked={checked} setChecked={setChecked} />
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            ) :
            (
                <React.Fragment>
                    <h1 style={{ color: 'green', textAlign: 'center', margin: 20 }}>{translate.admin.tousLesDocumentsOnSigne}</h1>
                </React.Fragment>
            )
    )
}


const Row = ({ id, name, status, link, checked, setChecked, signataire, codeAuth, data, allChecked, created_at, updated_at }) => {
    const { translate } = useTranslateContext();
    const dispatch = useDispatch()

    function truncateString(str, maxLength) {
        if (str.length <= maxLength) {
            return str;
        } else {
            const truncated = str.substr(0, maxLength);
            const lastSpaceIndex = truncated.lastIndexOf(' ');

            if (lastSpaceIndex !== -1) {
                return truncated.substr(0, lastSpaceIndex) + '...';
            } else {
                return truncated + '...';
            }
        }
    }

    return (
        <tr className='view-signataire-document' key={id}>
            <td>
                <input type="checkbox" name='checkbox-element' className="sel-doc-checkbox" defaultChecked={allChecked} onClick={() => {
                    if (checked.includes(id)) {
                        var new_arr = checked.filter(dt => dt != id)
                        setChecked(new_arr)
                    } else {
                        setChecked((prevCheck) => [id, ...prevCheck])
                    }
                }} />
            </td>
            <td>
                <div className="d-flex align-items-center">
                    <div className="symbol symbol-50px me-5">
                        <img
                            src="https://thumbs.dreamstime.com/b/documents-ou-contrat-de-bureau-sur-un-fond-bleu-136090443.jpg"
                            className=""
                            alt=""
                        />
                    </div>
                    <div className="d-flex justify-content-start flex-column">
                        <a href={link} className="text-dark fw-bolder text-hover-primary mb-1 fs-6">
                            {truncateString(name, 10)}
                        </a>
                    </div>
                </div>
            </td>
            <td className="text-left pe-0" data-order="Published">
                {status === "en_attente" && <span className="badge en_attente-btn">{translate.admin.attente}</span>}
                {status === "en_cours" && <span className="badge en_cour-btn">{translate.admin.encours} </span>}
                {status === "signe" && <span className="badge signer-btn">{translate.admin.signe} </span>}
                {status === "refuse" && <span className="badge refuse-btn">{translate.admin.refuse} </span>}
            </td>
            <td>
                <span className="badge badge-light-primary fs-7 fw-bold">
                    {status === 'en_attente' ? created_at : updated_at}
                </span>
            </td>
            <td className="text-end">
                <Link
                    to={'/previewdoc'}
                    onClick={() => {
                        dispatch(setCurrentDoc(data))
                        dispatch(setCodeAuth(codeAuth))
                        dispatch(setSignataire(signataire))
                        dispatch(setStatus(status))
                    }}
                    class="badge badge-light-success"
                >
                    {translate.admin.voirLeDocument}
                </Link>
            </td>
        </tr>
    )
}