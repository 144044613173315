import React from 'react'
import { MdDelete, MdOutlineModeEditOutline } from "react-icons/md";

function trimString(chaine) {
    if (chaine.length > 25) {
        return chaine.slice(0, 25) + '...';
    } else {
        return chaine;
    }
}

export default function Row({ item, setShowForm, setDataEdit, setShowDelete }) {
    return (
        <tr className="even">
            <td>
                <div className="form-check form-check-sm form-check-custom form-check-solid">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        defaultValue={1}
                    />
                </div>
            </td>

            <td>{item.title}</td>

            <td data-order="2022-08-17T08:23:58+00:00">
                <div className="badge badge-light fw-bolder">{trimString(item.subtitle)}</div>
            </td>

            <td>
                <div className="badge badge-light-success fw-bolder">
                    {trimString(item.posteddate)}
                </div>
            </td>

            <td className="text-end">
                <MdDelete
                    style={{ marginRight: '15px' }}
                    color='red'
                    onClick={() => {
                        setShowDelete(true)
                        setDataEdit(item)
                    }}
                    size={20}
                />
                <MdOutlineModeEditOutline
                    data-bs-toggle="modal"
                    data-bs-target="#kt_modal_new_target"
                    onClick={() => {
                        setShowForm(true)
                        setDataEdit(item)
                    }}
                    size={20}
                />
            </td>
        </tr>
    )
}
