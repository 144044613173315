import React, {useState, useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {signatureSelector, setSignataire, setHaveSignataire, setMode, setHaveOrder} from "../../../../../services/reduxSlices/SignatureSlice";
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import { useTranslateContext } from '../../../../../hooks';
/**
 * Composant d'ajout de signataires de l'étape 3 de la fenêtre modale signer et faire signer
 */
export default function Step3 () {
    const { translate } = useTranslateContext();
    const dispatch = useDispatch()
    const signature = useSelector(signatureSelector)

    const [ showRang, setShowRang ] = useState(false)
    const [signataireId, setSignataireId] = useState(1)
    const [_signataire, _setSignataire] = useState({
        nom: '',
        email: '',
        telephone: '',
        deadline: ''
    })

    const handleAddSignataire = () => {
        if (_signataire.nom === '' || _signataire.email === '' || _signataire.telephone === '') {
            return alert('Veuillez remplir tous les champs')
        }

        if (!new RegExp(/^[^\s@]+@[^\s@]+$/).test(_signataire.email)) {
            return alert('L\' adresse email est inccorrecte')
        }

        if (isNaN(_signataire.telephone) || _signataire.telephone.length !== 10) {
            return alert('Le numéro de téléphone ne doit contenir que 10 chiffres')
        }

        let signataires = signature.signataire.filter(signataire => {
            return signataire.email === _signataire.email || signataire.telephone === _signataire.telephone
        })

        if (signataires.length > 0) {
            return alert('Ce signataire a déjà été ajouté')
        }

        _setSignataire({
            nom: '',
            email: '',
            telephone: ''
        })

        setSignataireId(signataireId + 1)
        dispatch(setHaveSignataire(true))

        let _signataires = [...signature.signataire, {..._signataire, id: signataireId}]

        //Cette action a pour but de retirer l'attribut "read only" de la variable
        _signataires = JSON.parse(JSON.stringify(_signataires))

        _signataires = _signataires.map((_signataire, i) => {
            _signataire.id = i + 1
            return _signataire
        })

        dispatch(setSignataire(_signataires))
    }

    const handleRemoveSignataire = (id) => {
        dispatch(setSignataire(signature.signataire.filter(signataire => signataire.id !== id)))
    }

    return (
        <div className='d-flex flex-column'> 
            <div className="row">
                <div className="col">
                    <input type="search" className="form-control" value={_signataire.nom}
                        onChange={e => _setSignataire({..._signataire, nom: e.target.value})} placeholder={translate.admin.fullName}
                    />
                </div>

                <div className="col">
                    <input type="search" className="form-control" value={_signataire.email}
                        onChange={e => _setSignataire({..._signataire, email: e.target.value})} placeholder={translate.admin.email}
                    />
                </div>

                <div className="col">
                    <input type="search" className="form-control" value={_signataire.telephone}
                        onChange={e => _setSignataire({..._signataire, telephone: e.target.value})} placeholder={translate.admin.telephone}
                    />
                </div>

                <div className="col">
                    <button type='button' onClick={() => handleAddSignataire()} className="btn btn-success px-4" disabled={signataireId > 5}>Ajouter</button>
                    <button type='button' className="btn btn-primary ms-3 px-5" disabled={signataireId > 5} title="Importer des contacts" data-bs-toggle="modal" data-bs-target="#import-contacts">
                        <PermContactCalendarIcon />
                    </button>
                </div>
            </div>

            <div className="py-5">
                {
                    signature.signataire.length > 0 && <table className="table">
                        <thead>
                            <tr>
                                <th  scope="col"><input style={{height:17, width:17}} type="checkbox" defaultChecked={showRang} onChange={()=>{
                                    setShowRang(!showRang)
                                    dispatch(setHaveOrder(!showRang));
                                }} /></th>
                                {showRang && <th scope="col">Rang(s)</th>}
                                <th scope="col">Nom et prénom(s)</th>
                                <th scope="col">Email</th>
                                <th scope="col">Téléphone</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                            signature.signataire.map(signataire => {
                                return <tr key={signataire.id}>
                                    <td></td>
                                    {showRang && <td><input type="number" className='rang' defaultValue={signataire.id} /></td> }
                                    <td>{signataire.nom}</td>
                                    <td>{signataire.email}</td>
                                    <td>{signataire.telephone}</td>
                                    <td>
                                        <button type='button' onClick={() => handleRemoveSignataire(signataire.id)}
                                            className='btn btn-danger d-flex rounded-circle justify-content-center align-items-center'
                                            style={{width: '10px', height: '10px', padding: '15px'}}>X
                                        </button>
                                    </td>
                                </tr>
                            })
                        }
                        </tbody>
                    </table>
                }
            </div>
        </div>
    )
}
