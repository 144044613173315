import React from 'react';
import TableWrapper from './TableWrapper';
import RowTable from './RowTable';


/**
 * Composant présent dans le Menu mes documents
 * Tableau contenant l'enssemble des documents
 * 
 * @returns Document
 */
const Document = ({data, maxPage, setPage, currentPage, onApplySearch, onApplyFilter, status}) => {
	
    return(
		<TableWrapper onApplySearch={onApplySearch} setPage={setPage} currentPage={currentPage} maxPage={maxPage} onApplyFilter={onApplyFilter} status={status}>
			{
				data.map((rw)=>{
					return <RowTable data={rw} />
				})
			}
		</TableWrapper>
    );
}

export default Document;