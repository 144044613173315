import React from 'react';
import HeaderTable from './HeaderTable';

import { useTranslateContext } from '../../../hooks';

export default function Table({ children, setShowForm, setDataEdit }) {
  const { translate } = useTranslateContext();
  return (
    <HeaderTable setShowForm={setShowForm} setDataEdit={setDataEdit}>
      <div className="card-body py-4">
        <div
          id="kt_table_users_wrapper"
          className="dataTables_wrapper dt-bootstrap4 no-footer"
        >
          <div className="table-responsive">
            <table
              className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
              id="kt_table_users"
            >
              {/*begin::Table head*/}
              <thead>
                {/*begin::Table row*/}
                <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
                  <th
                    className="w-10px pe-2 sorting_disabled"
                    rowSpan={1}
                    colSpan={1}
                    aria-label="								
                                                        "
                    style={{ width: '29.25px' }}
                  >
                    <div className="form-check form-check-sm form-check-custom form-check-solid me-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        data-kt-check="true"
                        data-kt-check-target="#kt_table_users .form-check-input"
                        defaultValue={1}
                      />
                    </div>
                  </th>
                  <th
                    className="min-w-125px sorting"
                    tabIndex={0}
                    aria-controls="kt_table_users"
                    rowSpan={1}
                    colSpan={1}
                    aria-label="User: activate to sort column ascending"
                    style={{ width: '296.062px' }}
                  >
                    {translate.admin.utilisateur}
                  </th>
                  <th
                    className="min-w-125px sorting"
                    tabIndex={0}
                    aria-controls="kt_table_users"
                    rowSpan={1}
                    colSpan={1}
                    aria-label="Role: activate to sort column ascending"
                    style={{ width: '164.75px' }}
                  >
                    {translate.admin.fonction}
                  </th>
                  <th
                    className="min-w-125px sorting"
                    tabIndex={0}
                    aria-controls="kt_table_users"
                    rowSpan={1}
                    colSpan={1}
                    aria-label="Last login: activate to sort column ascending"
                    style={{ width: '164.75px' }}
                  >
                    {translate.admin.entreprise}
                  </th>
                  <th
                    className="min-w-125px sorting"
                    tabIndex={0}
                    aria-controls="kt_table_users"
                    rowSpan={1}
                    colSpan={1}
                    aria-label="Two-step: activate to sort column ascending"
                    style={{ width: '164.75px' }}
                  >
                    {translate.admin.role}
                  </th>
                  <th
                    className="min-w-125px sorting"
                    tabIndex={0}
                    aria-controls="kt_table_users"
                    rowSpan={1}
                    colSpan={1}
                    aria-label="Joined Date: activate to sort column ascending"
                    style={{ width: '213.484px' }}
                  >
                    {translate.admin.datenscription}
                  </th>
                  <th
                    className="text-end min-w-100px sorting_disabled"
                    rowSpan={1}
                    colSpan={1}
                    aria-label="Actions"
                    style={{ width: '132.453px' }}
                  >
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="text-gray-600 fw-bold">{children}</tbody>
              {/*end::Table body*/}
            </table>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start" />
            <div className="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end">
              <div
                className="dataTables_paginate paging_simple_numbers"
                id="kt_table_users_paginate"
              >
                <ul className="pagination">
                  <li
                    className="paginate_button page-item previous disabled"
                    id="kt_table_users_previous"
                  >
                    <a
                      href="#"
                      aria-controls="kt_table_users"
                      data-dt-idx={0}
                      tabIndex={0}
                      className="page-link"
                    >
                      <i className="previous" />
                    </a>
                  </li>
                  <li className="paginate_button page-item active">
                    <a
                      href="#"
                      aria-controls="kt_table_users"
                      data-dt-idx={1}
                      tabIndex={0}
                      className="page-link"
                    >
                      1
                    </a>
                  </li>
                  <li className="paginate_button page-item ">
                    <a
                      href="#"
                      aria-controls="kt_table_users"
                      data-dt-idx={2}
                      tabIndex={0}
                      className="page-link"
                    >
                      2
                    </a>
                  </li>
                  <li className="paginate_button page-item ">
                    <a
                      href="#"
                      aria-controls="kt_table_users"
                      data-dt-idx={3}
                      tabIndex={0}
                      className="page-link"
                    >
                      3
                    </a>
                  </li>
                  <li
                    className="paginate_button page-item next"
                    id="kt_table_users_next"
                  >
                    <a
                      href="#"
                      aria-controls="kt_table_users"
                      data-dt-idx={4}
                      tabIndex={0}
                      className="page-link"
                    >
                      <i className="next" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </HeaderTable>
  );
}
