import axios from 'axios';
import { IMAGE_URL } from '../../../../services/api/URL_API';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';
import { useTranslateContext } from '../../../../hooks';

const BASE_URL = 'https://dkbsign.com/dkbsign_web_api/public';

const token = Cookies.get('token_admin');

export const AddImage = async ({ pagecible, img, alt, index }) => {
  const { translate } = useTranslateContext();

  const formData = new FormData();

  formData.append('pagecible', pagecible);
  formData.append(`img${index}`, img);
  formData.append(`alt${index}`, alt);

  try {
    const response = await axios.post(
      `${BASE_URL}${IMAGE_URL}/store`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      }
    );
    // handle success

    toast.success(translate.admin.imageEteAjouteAvecSucces, {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    window.location.reload();
  } catch (error) {
    toast.error(translate.admin.erreurImpossibleAjouterImage, {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
};

export const EditImage = async ({ pagecible, img, alt, index, id }) => {
  const { translate } = useTranslateContext();
  const formData = new FormData();

  formData.append('pagecible', pagecible);
  formData.append(`img${index}`, img);
  formData.append(`alt${index}`, alt);

  try {
    const response = await axios.post(
      `${BASE_URL}${IMAGE_URL}/update/${id}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      }
    );
    // handle success

    toast.success(translate.admin.imageAEteModifie, {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    window.location.reload();
  } catch (error) {
    toast.error(translate.admin.erreurImpossibleDeModifieImage, {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
};

export const DeleteImage = async ({ id }) => {
  const { translate } = useTranslateContext();

  try {
    const response = await axios.get(`${BASE_URL}${IMAGE_URL}/destroy/${id}`, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
    });
    // handle success

    toast.success(translate.admin.lesImagesOntEteSupprime, {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    window.location.reload();
  } catch (error) {
    toast.error(translate.admin.erreurImpossibleDeSupprimerLesImages, {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
};
