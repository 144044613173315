import React, { useState } from 'react'
import { Formik, Form, ErrorMessage } from 'formik';
import { AddClient, EditClient } from './services';
import './styles.css'
import * as Yup from "yup";

export default function Forms({ showForm, setShowForm, data }) {
    const [photos, setPhotos] = useState([]);

    const [initialValues] = useState({
        name: data ? data.name : '',
        description: data ? data.description : '',
        img: data ? data.img : '',
        author: data ? data.author : '',
        authorfunction: data ? data.authorfunction : '',
        alt: data ? data.alt : ''
    })

    const ClientSchema = Yup.object().shape({
        name: Yup.string()
            .required('Champs requis'),

        description: Yup.string()
            .required('Champs requis!'),

        img: Yup.string()
            .notRequired(),
            
        author: Yup.string()
            .required('Champs requis!'),

        authorfunction: Yup.string()
            .required('Champs requis!'),

        alt: Yup.string()
            .required('Champs requis!')
    });

    return (
        <div
            className={showForm ? "modal fade show" : "modal fade"}
            id="kt_modal_new_target"
            tabIndex={-1}
            style={{ display: showForm ? "block" : "none", marginTop: '0px' }}
        >
            <div className="modal-dialog modal-dialog-centered mw-650px">
                <div className="modal-content rounded" style={{ width: '100%' }}>
                    <div className="modal-header pb-0 border-0 justify-content-end">
                        <div
                            className="btn btn-sm btn-icon btn-active-color-primary"
                            data-bs-dismiss="modal"
                            onClick={() => setShowForm(false)}
                        >
                            <span className="svg-icon svg-icon-1">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                >
                                    <rect
                                        opacity="0.5"
                                        x={6}
                                        y="17.3137"
                                        width={16}
                                        height={2}
                                        rx={1}
                                        transform="rotate(-45 6 17.3137)"
                                        fill="currentColor"
                                    />
                                    <rect
                                        x="7.41422"
                                        y={6}
                                        width={16}
                                        height={2}
                                        rx={1}
                                        transform="rotate(45 7.41422 6)"
                                        fill="currentColor"
                                    />
                                </svg>
                            </span>
                        </div>
                    </div>

                    <Formik
                        initialValues={initialValues}

                        validationSchema={ClientSchema}

                        onSubmit={async (values) => {

                            const dataSend = {
                                id: data ? data.id : 0,
                                name: values.name,
                                description: values.description,
                                img: photos[0],
                                author: values.author,
                                authorfunction: values.authorfunction,
                                alt: values.alt
                            }

                            data ? await EditClient(dataSend) : await AddClient(dataSend)
                            setShowForm(false)
                        }}
                    >

                        {({ handleChange, handleBlur, values, handleSubmit }) => (
                            <Form>
                                <div className="modal-body scroll-y px-10 px-lg-15 pt-0 pb-15">

                                    <div className="mb-13 text-center">
                                        <h1 className="mb-3">{data ? 'Modifier un client' : 'Ajouter un client'}</h1>
                                    </div>

                                    <div className="row mb-5">
                                        <div className="col-md-6 fv-row fv-plugins-icon-container lg-input">
                                            <label className="required fs-5 fw-bold mb-2">Name</label>
                                            <input
                                                type="text"
                                                className="form-control form-control-solid"
                                                placeholder="Entrez le nom"
                                                name="name"
                                                value={values.name}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            <div className="fv-plugins-message-container invalid-feedback" />
                                            <ErrorMessage className="error-msg" name="name" component={'div'} />
                                        </div>
                                    </div>

                                    <div className="row mb-5">
                                        <div className="col-md-6 fv-row fv-plugins-icon-container lg-input">
                                            <label className="required fs-5 fw-bold mb-2">Auteur</label>
                                            <input
                                                type="text"
                                                className="form-control form-control-solid"
                                                placeholder="Entrez l'Auteur"
                                                name="author"
                                                value={values.author}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            <div className="fv-plugins-message-container invalid-feedback" />
                                            <ErrorMessage className="error-msg" name="author" component={'div'} />
                                        </div>
                                    </div>

                                    <div className="row mb-5">
                                        <div className="col-md-6 fv-row fv-plugins-icon-container lg-input">
                                            <label className="required fs-5 fw-bold mb-2">Fonction</label>
                                            <input
                                                type="text"
                                                className="form-control form-control-solid"
                                                placeholder="Entrez la fonction"
                                                name="authorfunction"
                                                value={values.authorfunction}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            <div className="fv-plugins-message-container invalid-feedback" />
                                            <ErrorMessage className="error-msg" name="authorfunction" component={'div'} />
                                        </div>
                                    </div>

                                    <div className="row mb-5">
                                        <div className="col-md-6 fv-row fv-plugins-icon-container lg-input">
                                            <label className="required fs-5 fw-bold mb-2">Description</label>
                                            <textarea 
                                                cols="30" 
                                                rows="10"
                                                className="form-control form-control-solid"
                                                placeholder="Entrez le contenu"
                                                name="description"
                                                value={values.description}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            >
                                            </textarea>
                                          
                                            <div className="fv-plugins-message-container invalid-feedback" />
                                            <ErrorMessage className="error-msg" name="description" component={'div'} />
                                        </div>
                                       
                                    </div>

                                    <div className="d-flex flex-column mb-8 fv-row fv-plugins-icon-container fv-plugins-bootstrap5-row-invalid">
                                        <label className="d-flex align-items-center fs-6 fw-bold mb-2">
                                            <span className="required">Image</span>
                                        </label>
                                        <input 
                                            type="file" 
                                            name="img"
                                            onChange={(e)=>{
                                                setPhotos(e.target.files)
                                                handleChange(e)
                                            }}
                                            onBlur={handleBlur}
                                        />
                                        <ErrorMessage className="error-msg" name="img" component={'div'} />
                                    </div>

                                    <div className="row mb-5">
                                        <div className="col-md-6 fv-row fv-plugins-icon-container lg-input">
                                            <label className="required fs-5 fw-bold mb-2">Alt Image</label>
                                            <input
                                                className="form-control form-control-solid"
                                                placeholder="img_bank"
                                                name="alt"
                                                value={values.alt}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />

                                            <div className="fv-plugins-message-container invalid-feedback" />
                                            <ErrorMessage className="error-msg" name="alt" component={'div'} />
                                        </div>
                                    </div>

                                    <div className="text-center">
                                        <button
                                            type="reset"
                                            id="kt_modal_new_target_cancel"
                                            onClick={() => setShowForm(false)}
                                            className="btn btn-light me-3"
                                        >
                                            Annuler
                                        </button>
                                        <button
                                            type="submit"
                                            onClick={handleSubmit}
                                            data-bs-dismiss="modal"
                                            className="btn btn-primary"
                                        >
                                            <span className="indicator-label">Soumettre</span>
                                            <span className="indicator-progress">
                                                Please wait...
                                                <span className="spinner-border spinner-border-sm align-middle ms-2" />
                                            </span>
                                        </button>
                                    </div>
                                    <div />
                                </div>
                            </Form>
                        )}
                    </Formik>

                </div>
            </div>
        </div>
    )
}
