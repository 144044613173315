import React, { useEffect, useState } from 'react'
import LandingWrapper from './LandingWrapper'
import { BsCheckCircleFill } from 'react-icons/bs'
import { TryModal } from './Modal'
import { Helmet } from "react-helmet";
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';
import { GetReferencement, GetImagesByPage } from './Admin/Main/services'
import SpinnerPage from './SpinnerPage';
import './styles.css'

var empty_img = {
    "img1": null,
    "alt1": null,
    "img2": null,
    "alt2": null,
    "img3": null,
    "alt3": null,
    "img4": null,
    "alt4": null,
    "img5": null,
    "alt5": null,
    "img6": null,
    "alt6": null,
    "img7": null,
    "alt7": null,
    "img8": null,
    "alt8": null,
    "img9": null,
    "alt9": null,
    "img10": null,
    "alt10": null,
    "img11": null,
    "alt11": null,
    "img12": null,
    "alt12": null,
    "img13": null,
    "alt13": null,
    "img14": null,
    "alt14": null,
    "img15": null,
    "alt15": null,
    "img16": null,
    "alt16": null,
    "img17": null,
    "alt17": null,
    "img18": null,
    "alt18": null,
    "img19": null,
    "alt19": null,
    "img20": null,
    "alt20": null
}

const PAGE_NAME = 'TOKEN'
const BASE_URL_IMG = `https://dkbsign.com/dkbsign_web_api/public/storage/websiteimages/${PAGE_NAME}`

export default function LandingToken() {
    const [referencement, setReferencement] = useState(null)
    const [IMAGES, SETIMAGES] = useState(null)

    useEffect(() => {
        GetReferencement().then(source => setReferencement(source))
        GetImagesByPage({ cible: PAGE_NAME }).then(source => SETIMAGES(source))
    }, [])

    return (
        IMAGES ?
            <LandingWrapper>
                <Helmet>
                    <title>
                        {
                            referencement ?
                                referencement.find((dt) => dt.pageCible === PAGE_NAME) ? referencement.find((dt) => dt.pageCible === PAGE_NAME).siteTitle : "DKBSign"
                                : "DKBSign"
                        }
                    </title>
                    <meta
                        name="description"
                        content={
                            referencement ?
                                referencement.find((dt) => dt.pageCible === PAGE_NAME) ? referencement.find((dt) => dt.pageCible === PAGE_NAME).meta_description : ""
                                : ""
                        }
                    />

                    <meta
                        name="keywords"
                        lang="fr"
                        content={
                            referencement ?
                                referencement.find((dt) => dt.pageCible === PAGE_NAME) ? referencement.find((dt) => dt.pageCible === PAGE_NAME).meta_keywords : ""
                                : ""
                        }
                    />

                    <meta name="robots" content={
                        referencement ?
                            referencement.find((dt) => dt.pageCible === PAGE_NAME) ? referencement.find((dt) => dt.pageCible === PAGE_NAME).meta_robots : ""
                            : ""
                    }
                    />
                    <meta name="category" content={
                        referencement ?
                            referencement.find((dt) => dt.pageCible === PAGE_NAME) ? referencement.find((dt) => dt.pageCible === PAGE_NAME).meta_category : ""
                            : ""
                    }
                    />

                    <meta name="identifier-url" content={
                        referencement ?
                            referencement.find((dt) => dt.pageCible === PAGE_NAME) ? referencement.find((dt) => dt.pageCible === PAGE_NAME).meta_identifier_url : ""
                            : ""
                    }
                    />

                    <meta name="reply-to" content={
                        referencement ?
                            referencement.find((dt) => dt.pageCible === PAGE_NAME) ? referencement.find((dt) => dt.pageCible === PAGE_NAME).meta_reply_to : ""
                            : ""
                    }
                    />
                </Helmet>
                <Header />
                <DescToken IMAGES={IMAGES[0] ? IMAGES[0] : empty_img} />
                <TokenImage IMAGES={IMAGES[0] ? IMAGES[0] : empty_img} />
                <TokenBanner IMAGES={IMAGES[0] ? IMAGES[0] : empty_img} />
                <HowItsWork IMAGES={IMAGES[0] ? IMAGES[0] : empty_img} />
                <TokenBenefits IMAGES={IMAGES[0] ? IMAGES[0] : empty_img} />
            </LandingWrapper>
            :
            <div className='global-loader'>
                <SpinnerPage />
            </div>
    )
}

const Header = () => {
    const [showModal, setShowModal] = useState(false)

    return (
        <React.Fragment>
            {showModal && <TryModal showModal={showModal} setShowModal={setShowModal} />}
            <div className="landing-token__body-content">
                <div className='flip-header-token'>
                    <Fade duration={4000} left>
                        <div className="landing-token__body-content-left-title">
                            <h1>Basé sur une technologie de PKI, le Token permet d'authentifiez vos signatures</h1>
                        </div>
                        <a onClick={() => setShowModal(true)} className="landing_api-weclient-contactus">DEMANDER UNE DEMO</a>
                    </Fade>
                </div>
            </div>
        </React.Fragment>
    )
}

const DescToken = ({ IMAGES }) => {
    return (
        <div className="landing-token__desc-content">
            <div className="landing-token__desc-item">
                <p>Le Token de DKBSign</p>
                <p>
                    Le token PKI de DKBSign offre un stockage sécurisé pour les certificats numériques et les clés privées. Il permet d'utiliser la cryptographie à clé publique et les signatures numériques de façon parfaitement sécurisée, sans risque de fuite des clés privées.
                    Le token ou certificat personne physique est l'équivalent d'une véritable carte d'identité numérique professionnelle certifiée par le tiers de confiance DKBSign. C’est un certificat hébergé sur un ordinateur ou un support physique (carte à puce, clé USB, token) lié à une personne physique (et éventuellement lié aussi à son entreprise, à sa banque, à son ISP). Notre certificat de personne physique permet une authentification forte et la signature électronique des documents.
                    <br />
                    <br />
                    Le token est idéale pour les petites et moyennes entreprises souhaitant rester en conformité avec les réglementations et s'adapter aux besoins des télétravailleurs ou employés en déplacement.
                    <p className='hyphen'><BsCheckCircleFill className='hyphen-check' /> Signatures à l’aide d’identités individuelles ou au niveau de l'entreprise ou du département</p>
                    <p className='hyphen'><BsCheckCircleFill className='hyphen-check' /> Respect des exigences réglementaires nationales et sectorielles applicables aux signatures numériques</p>
                    <p className='hyphen'><BsCheckCircleFill className='hyphen-check' /> Protection de la propriété intellectuelle grâce à la garantie d’intégrité et d’authenticité des documents</p>
                    <p className='hyphen'><BsCheckCircleFill className='hyphen-check' /> Ajout de signatures d’approbation personnalisables (image de signature physique ou cachet/sceau de la profession)</p>
                    <p className='hyphen'><BsCheckCircleFill className='hyphen-check' /> Signature de plusieurs types de documents avec un seul identifiant</p>
                    <p className='hyphen'><BsCheckCircleFill className='hyphen-check' /> Horodatage inclus qui prend en charge les scénarios avec pistes d’audit et permet le respect des règles de conformité</p>
                </p>
            </div>

            <img
                src={IMAGES.img1 ? `${BASE_URL_IMG}/${IMAGES.img1}` : "./assets/media/home_icon/Token.png"}
                alt={IMAGES.alt1 ? `${IMAGES.alt1}` : ""}
                className='key_img'
            />
        </div>
    )
}

const TokenImage = ({ IMAGES }) => {
    return (
        <div className="landing-token__image-content">
            <img
                src={IMAGES.img2 ? `${BASE_URL_IMG}/${IMAGES.img2}` : "./assets/media/home_icon/Etapes-signature-token.png"}
                alt={IMAGES.alt2 ? `${IMAGES.alt2}` : ""}
                className='landing-token__image-content-img'
            />
        </div>
    )
}

const TokenBanner = ({ IMAGES }) => {
    return (
        <div className="landing-token__image-content-banner">
            <Fade duration={4000} left>
                <div className="landing-token__howitswork-title">Le Token permet de :</div>
            </Fade>

            <div className="landing-token__image-content-img-banner">
                <Zoom down duration={2500}>
                    <img
                        src={IMAGES.img3 ? `${BASE_URL_IMG}/${IMAGES.img3}` : "./assets/media/home_icon/Document-signer.png"}
                        alt={IMAGES.alt3 ? `${IMAGES.alt3}` : ""}
                        className='landing-token__image-content-img-banner-items'
                    />

                    <img
                        src={IMAGES.img4 ? `${BASE_URL_IMG}/${IMAGES.img4}` : "./assets/media/home_icon/Intégrité-document.png"}
                        alt={IMAGES.alt4 ? `${IMAGES.alt4}` : ""}
                        className='landing-token__image-content-img-banner-items'
                    />

                    <img
                        src={IMAGES.img5 ? `${BASE_URL_IMG}/${IMAGES.img5}` : "./assets/media/home_icon/Authentification.png"}
                        alt={IMAGES.alt5 ? `${IMAGES.alt5}` : ""}
                        className='landing-token__image-content-img-banner-items'
                    />

                    <img
                        src={IMAGES.img6 ? `${BASE_URL_IMG}/${IMAGES.img6}` : "./assets/media/home_icon/Emails-signer.png"}
                        alt={IMAGES.alt6 ? `${IMAGES.alt6}` : ""}
                        className='landing-token__image-content-img-banner-items'
                    />

                    <img
                        src={IMAGES.img7 ? `${BASE_URL_IMG}/${IMAGES.img7}` : "./assets/media/home_icon/Authentification-forte.png"}
                        alt={IMAGES.alt7 ? `${IMAGES.alt7}` : ""}
                        className='landing-token__image-content-img-banner-items'
                    />
                </Zoom>
            </div>
        </div>
    )
}

const HowItsWork = ({ IMAGES }) => {
    return (
        <div className="landing-token__howitswork-content">
            <div style={{ color: 'white' }} className="landing-token__howitswork-adobeTxt">Signatures pour vos documents Adobe PDF et MS office disponibles</div>

            <div className="landing-token__desc-content-howtswork">
                <div className="landing-token__desc-item-howtswork">
                    <p style={{ color: 'white' }}>
                        Signatures pour vos documents Adobe PDF et MS office disponibles
                        pour toute l’entreprise

                        Ajoutez facilement un gage de confiance et d’intégrité à vos documents et communications d'entreprise grâce aux signatures numériques DKBSign pour Microsoft Word, Microsoft Excel, Microsoft PowerPoint et Microsoft Outlook.

                        <p className='hyphen'><BsCheckCircleFill className='hyphen-check' /> Permet des échanges de documents électroniques sécurisés</p>
                        <p className='hyphen'><BsCheckCircleFill className='hyphen-check' /> Garantit l'origine et l'intégrité du document</p>
                        <p className='hyphen'><BsCheckCircleFill className='hyphen-check' /> Permet d'économiser du temps et de l'argent grâce à la dématérialisation de vos documents papiers</p>
                        <p className='hyphen'><BsCheckCircleFill className='hyphen-check' /> Est conforme aux réglementations en vigueur sur les documents électroniques</p>
                        <p className='hyphen'><BsCheckCircleFill className='hyphen-check' /> Permet de signer également vos documents PDFs</p>
                    </p>
                </div>

                <img
                    src={IMAGES.img8 ? `${BASE_URL_IMG}/${IMAGES.img8}` : "./assets/media/home_icon/Signature-DKBSign (2).png"}
                    alt={IMAGES.img8 ? `${IMAGES.img8}` : ""}
                    className='key_img-howtswork'
                />
            </div>
        </div>
    )
}

const TokenBenefits = ({ IMAGES }) => {
    const [showModal, setShowModal] = useState(false)

    return (
        <div className="landing-token__benefits">
            {showModal && <TryModal showModal={showModal} setShowModal={setShowModal} />}

            <Fade duration={4000} left>
                <div className="landing-token__benefits-title">Notre Token est</div>
            </Fade>

            <div className="landing-token__benefits-list">
                <Zoom left duration={2500}>
                    <Benefits
                        image={IMAGES.img9 ? `${BASE_URL_IMG}/${IMAGES.img9}` : "./assets/media/home_icon/Avantage-1.png"}
                        desc="Facile D'usage"
                        alt={IMAGES.alt9 ? `${IMAGES.alt9}` : ""}
                    />

                    <Benefits
                        image={IMAGES.img10 ? `${BASE_URL_IMG}/${IMAGES.img10}` : "./assets/media/home_icon/Avantage-2.png"}
                        desc="A faible côut"
                        alt={IMAGES.alt10 ? `${IMAGES.alt10}` : ""}
                    />

                    <Benefits
                        image={IMAGES.img11 ? `${BASE_URL_IMG}/${IMAGES.img11}` : "./assets/media/home_icon/Avantage-3.png"}
                        desc="Fiable et sécurisé"
                        alt={IMAGES.alt11 ? `${IMAGES.alt11}` : ""}
                    />
                </Zoom>
            </div>
            <a style={{ marginTop: '15px' }} onClick={() => setShowModal(true)} className="landing_api-weclient-contactus">DEMANDER UNE DEMO</a>
        </div>
    )
}

const Benefits = ({ image, desc, alt }) => {
    return (
        <div className="landing-token__benefits-content">
            <img src={image} alt={alt} className='landing-token__benefits-content-no' />
            <div className="landing-token__benefits-content-desc">{desc}</div>
        </div>
    )
}