import React from 'react'
import { MdDelete, MdOutlineModeEditOutline } from "react-icons/md";

export default function Row({data, setShowForm, setDataEdit,setShowDelete}) {
  return (
    <tr className="even">
    {/*begin::Checkbox*/}
    <td>
      <div className="form-check form-check-sm form-check-custom form-check-solid">
        <input
          className="form-check-input"
          type="checkbox"
          defaultValue={1}
        />
      </div>
    </td>
    {/*end::Checkbox*/}
    {/*begin::User=*/}
    <td className="d-flex align-items-center">
      {/*begin:: Avatar */}
      <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
        <a href="view.html">
          <div className="symbol-label fs-3 bg-light-danger text-danger">
            M
          </div>
        </a>
      </div>
      {/*end::Avatar*/}
      {/*begin::User details*/}
      <div className="d-flex flex-column">
        <a
          href="view.html"
          className="text-gray-800 text-hover-primary mb-1"
        >
          {data.cle_api}
        </a>
        <span>{data.date_expiration}</span>
      </div>
      {/*begin::User details*/}
    </td>
    {/*end::User=*/}
    {/*begin::Role=*/}
    <td>{data.intitule}</td>
    {/*end::Role=*/}
    {/*begin::Last login=*/}
    <td data-order="2022-08-17T08:23:58+00:00">
      <div className="badge badge-light fw-bolder">{data.volume_signatures_restant}</div>
    </td>
    {/*end::Last login=*/}
    {/*begin::Two step=*/}
    <td>
      <div className="badge badge-light-success fw-bolder">
        {data.volume_signatures_total}
      </div>
    </td>
    {/*end::Two step=*/}
    {/*begin::Action=*/}
    <td className="text-end">
     <MdDelete 
          style={{marginRight:'15px'}} 
          color='red' 
          onClick={()=>{
            setShowDelete(true)
            setDataEdit(data)
          }}
          size={20}
    />
     <MdOutlineModeEditOutline 
          data-bs-toggle="modal"
          data-bs-target="#kt_modal_new_target"
          onClick={()=> {
            setShowForm(true)
            setDataEdit(data)
          }}
          size={20}
     />
    </td>
    {/*end::Action=*/}
  </tr>
  )
}
