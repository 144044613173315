export const TRANSLATE_EN = {
  // landingPage: {
  //   accueil: 'HOME',
  //   produits: 'PRODUCTS',
  //   solutions: 'SOLUTIONS',
  //   api: 'API',
  //   token: 'TOKEN',
  //   plateform: 'PLATFORM',
  //   tarif: 'PRICING',
  //   reference: 'REFERENCE',
  //   propos: 'ABOUT',
  //   fr: 'EN',
  //   espaceClient: 'CUSTOMER SPACE',
  //   connexion: 'SIGN IN',
  //   inscrivezVous: 'SUBSCRIBE TO THE NEWSLETTER',
  //   descriptionFooter: `DKBSign is a platform made available to users to allow them to make electronic signature in order to give legal value to signed documents. DKBSign is a product of DKB Trusted Third Party Solutions approved by ARTCI, committed to ensuring the security, confidentiality, integrity of transactions on the internet.`,
  //   envoyezSigner: 'Send and sign',
  //   vosDocumentsEnLigne: 'your documents online.',
  //   OuQueVousSoyezEnTouteSimplicite: 'Wherever you are, with ease.',
  //   rapideFacileEtMoindreCout:
  //     'It’s fast, easy and cost-effective: try DKBSign now.',
  //   essayer: 'TRY',
  //   ilsNousFontConfiance: 'They trust us.',
  //   signezSimplementVosDocumentsEnQuelquesClics:
  //     'Simply sign your documents in a few clicks!',
  //   telechargerVosDocuments: 'Upload your documents',
  //   designezLesSignataires:
  //     'Designate signatories, signatures position and send.',
  //   signezEtFaitesSigner: 'Sign and get signed',
  //   chaquesSignataireParEmailSignerSaisieCodeSMS:
  //     'Each signatory is invited by e-mail to sign by entering an SMS code.',
  //   conservezVosDocuments: 'Keep your documents',
  //   notreServiceDeConservationValeurProbanteSecurise:
  //     'Our probative service secures the storage of the signed document.',
  // },
  landingPage: {
    accueil: 'ACCUEIL',
    produits: 'PRODUITS',
    solutions: 'SOLUTIONS',
    api: 'API',
    token: 'TOKEN',
    plateform: 'PLATEFORM',
    tarif: 'TARIF',
    reference: 'REFERENCE',
    propos: 'A PROPOS',
    fr: 'FR',
    espaceClient: 'ESPACE CLIENT',
    connexion: 'CONNEXION',
    inscrivezVous: 'INSCRIVEZ-VOUS A LA NEWSLETTER',
    descriptionFooter: `DKBSign est une plateforme mise à la disposition des utilisateurs
    pour leur permettre de faire de la signature électronique afin de
    conférer une valeur juridique légale aux documents signés. DKBSign
    est un produit de DKB Solutions tiers de confiance agréé par
    l'ARTCI, engagé à garantir la sécurité, la confidentialité,
    l'intégrité des transactions sur internet`,
    envoyezSigner: 'Envoyez et signez',
    vosDocumentsEnLigne: 'vos documents en ligne.',
    OuQueVousSoyezEnTouteSimplicite: 'Où que vous soyez, en toute simplicité.',
    rapideFacileEtMoindreCout:
      'C’est rapide, facile et à moindre cout : essayez DKBSign dès maintenant.',
    essayer: 'ESSAYER',
    ilsNousFontConfiance: 'Ils nous font confiance.',
    signezSimplementVosDocumentsEnQuelquesClics:
      'Signez simplement vos documents en quelques clics !',
    telechargerVosDocuments: 'Télécharger vos documents',
    designezLesSignataires: `Désignez les signataires, l'emplacement des signatures et envoyez.`,
    signezEtFaitesSigner: 'Signez et faites signer',
    chaquesSignataireParEmailSignerSaisieCodeSMS: `Chaques signataire est invité par e-mail a signer grâce a la saisie d'un code SMS.`,
    conservezVosDocuments: 'Conservez vos documents',
    notreServiceDeConservationValeurProbanteSecurise:
      'Notre service de conservation a valeur probante sécurise le stockage du document signé.',
  },
  others: {
    actualites: 'News',
  },
  admin: {
    first_last_name: 'First and last name',
    normal: 'Low',
    urgent: 'Medium',
    tres_urgent: 'High',
    signatureDelay: 'Signature deadlines',
    applySameDateForAll: 'Apply the same deadline for all signatories',
    priorite: 'Priority',
    parametre: 'Settings',
    configurer: 'Configure',
    tableauDeBord: 'Dashboard',
    boiteDeReception: 'Inbox',
    mesDocuments: 'My documents',
    rapport: 'Report',
    contact: 'Contact',
    signatureRestante: 'Remaining signature',
    signaturesRestantes: 'Remaining signatures',
    documentsTotal: 'Total documents',
    contactEnregistre: 'Stored contact',
    documentsSigneAujourd: 'Signed documents today',
    statistiqueDesDocuments: 'Documents statistics',
    CourbeDesDocumentsEnFonctionsDesStatus: 'Documents by status',
    signer: 'Sign',
    seulSignataire: 'Only signatory',
    faireSigner: 'Get signed',
    signerFaireSigner: 'Sign & Get Signed',
    documentsSignes: 'Signed documents',
    documentsRefuses: 'Refused documents',
    documentsEnAttentes: 'Pending documents',
    refuse: 'Refused',
    signe: 'Signed',
    attente: 'Pending',
    encours: 'In progress',
    vosDocuments: 'Your documents',
    voirTout: 'See all',
    documentSigne: 'Signed document',
    documentsEnAttente: 'Pending documents',
    DocumentsEnCours: 'Documents in progress',
    DocumentsRefuse: 'Refused documents',
    signezVosDocuments: 'Sign your PDF documents',
    chargezVosDocuments: 'Upload your PDF documents',
    signature: 'Signature',
    definissezVotreSignature: 'Set your signature',
    chargezVosDocumentsIci: 'Upload your PDF documents here',
    chargement: 'Loading',
    suivant: 'Next',
    terminer: 'Done',
    termine: 'Done',
    vousPerdrezToutesModificationsEffectues:
      'If you close this window, you will lose all the changes made.',
    dataMonth: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ],
    rechercherUnDocument: 'Search document',
    nom: 'Name',
    entrezLeNom: 'Enter first name',
    prenom: 'Last name',
    entrezLePrenom: 'Enter last name',
    telephone: 'Phone',
    email: 'Email',
    entrezEmail: `Enter Email`,
    entrezVotreContacts: 'Enter contact',
    motif: 'Motive',
    rechercherUnContact: 'Search contact',
    rechercherUnUtilisateur: 'Search user',
    rechercherUneEntreprise: 'Search company',
    rechercherUnCertificat: 'Search certificate',
    tous: 'All',
    recu: 'Received',
    appliquerLeFiltre: 'Apply filter',
    voirLeDocument: 'View document',
    importerContact: 'Import contact',
    ajouterContact: 'Add contact',
    modifierContact: 'Edit contact',
    utilisateur: 'User',
    fonction: 'Function',
    entreprise: 'Enterprise',
    role: 'Role',
    datenscription: `Registration date`,
    souvenirDeMoi: 'Remember me',
    connect: 'Sign in',
    attention: 'Warning',
    listeDocumentsCharges: 'List of loaded documents',
    precedent: 'previous',
    deinition: 'Definition',
    chargerUneImage: 'Load an image',
    zoneDeSignature: 'Signature area',
    paraphe: 'Initial',
    effacer: 'Clear',
    codeAuthentification: `Authentication code`,
    ajoutezVosSignataires: 'Add your signers',
    signataires: 'Signatories',
    fullName: 'Full name',
    ajouter: 'Add',
    cliquezPourSigne: 'Select to sign',
    votreQrcode: 'Your qrcode',
    signataire: 'Signatory',
    outils: 'Tools',
    sur: 'on',
    cliquezSurLeBouton: `Select the location where your signature will appear.`,
    selectionDeSignature: 'Signature selection',
    fermer: 'Close',
    selectionner: 'Select',
    enSignantDocumentAttesteAvoirLuComprisEtAcceptLes: `By signing this document, I certify that I have read, understood and accepted the`,
    conditionsRelativesSignatureElectronique:
      'conditions relating to the electronic signature',
    leDocument: 'The document',
    nApasEteSigne: `has not been signed`,
    nOntPasEteCorrectementRenseignes: `have not been filled in correctly`,
    etreVousSurAvoirLu: `Are you sure you have read the entire previous document(s)?`,
    oui: 'Ok',
    non: 'No',
    info: 'Infos',
    voir: 'View',
    annuler: 'Cancel',
    supprimer: 'Delete',
    soumettre: 'Submit',
    chargerVosContact: 'Load your contacts',
    valider: 'validate',
    actualiteEteAjoute: `The news has been added`,
    erreurImpossibleAjouterActualite: 'Error: Cannot add news',
    actualiteEteModifie: `The news has been changed`,
    erreurImpossibleDeModifieActualite: `Error: Unable to change the news`,
    actualitéEteSupprime: `The news has been deleted`,
    erreurImpossibleDeSupprimerActualite: `Error: Cannot delete news`,

    imageEteAjouteAvecSucces: `The image has been successfully added`,
    erreurImpossibleAjouterImage: `Error: Cannot add image`,
    imageAEteModifie: `The image has been modified`,
    erreurImpossibleDeModifieImage: `Error: Cannot edit image`,
    lesImagesOntEteSupprime: `The images have been deleted`,
    erreurImpossibleDeSupprimerLesImages: `Error: Cannot delete image`,
    emailOuMotDePasseIncorrect: `Incorrect email or password`,

    veuillezSaisirToutLesChamps: 'Please fill all fields',
    leMotDasseDoitContenirAuMinimumCaracteres:
      'The password must contains at least 8 characters',
    votreDocumentEstEnCoursDeSignature: 'Your document is being signed',
    signerLeDocument: 'Sign document(s)',
    refuserLeDocument: 'Refuse document(s)',
    selectionnezLeDocument: 'Select document(s)',
    refuser: 'Refuse',
    veuillezSelectionnerLesDocumentsRefuser:
      'Please select the documents to be sent',
    veuillezSelectionnerAuMoinsUnDocument:
      'Please select at least one document',
    enregistrerVotreSignature: 'Save your signature',
    tousLesDocumentsOnSigne: 'All documents have been signed',
  },
};
