import React, { useState, useEffect } from 'react'
import LandingWrapper from './LandingWrapper'
import { BsWindowSidebar, BsCheckCircleFill } from 'react-icons/bs'
import { TryModal } from './Modal'
import { Helmet } from "react-helmet";
import { FcAssistant } from 'react-icons/fc'
import { GiBodySwapping } from 'react-icons/gi'
import { GetReferencement, GetImagesByPage } from './Admin/Main/services'
import Fade from 'react-reveal/Fade';
import SpinnerPage from './SpinnerPage';
import './styles.css'

var empty_img = {
    "img1": null,
    "alt1": null,
    "img2": null,
    "alt2": null,
    "img3": null,
    "alt3": null,
    "img4": null,
    "alt4": null,
    "img5": null,
    "alt5": null,
    "img6": null,
    "alt6": null,
    "img7": null,
    "alt7": null,
    "img8": null,
    "alt8": null,
    "img9": null,
    "alt9": null,
    "img10": null,
    "alt10": null,
    "img11": null,
    "alt11": null,
    "img12": null,
    "alt12": null,
    "img13": null,
    "alt13": null,
    "img14": null,
    "alt14": null,
    "img15": null,
    "alt15": null,
    "img16": null,
    "alt16": null,
    "img17": null,
    "alt17": null,
    "img18": null,
    "alt18": null,
    "img19": null,
    "alt19": null,
    "img20": null,
    "alt20": null
}

const PAGE_NAME = 'PLATEFORM'
const BASE_URL_IMG = `https://dkbsign.com/dkbsign_web_api/public/storage/websiteimages/${PAGE_NAME}`

export default function LandingPlateform() {
    const [referencement, setReferencement] = useState(null)
    const [IMAGES, SETIMAGES] = useState(null)

    useEffect(() => {
        GetReferencement().then(source => setReferencement(source))
        GetImagesByPage({ cible: PAGE_NAME }).then(source => SETIMAGES(source))
    }, [])

    return (
        IMAGES ?
            <div className='landing-home'>
                <LandingWrapper>
                    <Helmet>
                        <title>
                            {
                                referencement ?
                                    referencement.find((dt) => dt.pageCible === PAGE_NAME) ? referencement.find((dt) => dt.pageCible === PAGE_NAME).siteTitle : "DKBSign"
                                    : "DKBSign"
                            }
                        </title>
                        <meta
                            name="description"
                            content={
                                referencement ?
                                    referencement.find((dt) => dt.pageCible === PAGE_NAME) ? referencement.find((dt) => dt.pageCible === PAGE_NAME).meta_description : ""
                                    : ""
                            }
                        />

                        <meta
                            name="keywords"
                            lang="fr"
                            content={
                                referencement ?
                                    referencement.find((dt) => dt.pageCible === PAGE_NAME) ? referencement.find((dt) => dt.pageCible === PAGE_NAME).meta_keywords : ""
                                    : ""
                            }
                        />

                        <meta name="robots" content={
                            referencement ?
                                referencement.find((dt) => dt.pageCible === PAGE_NAME) ? referencement.find((dt) => dt.pageCible === PAGE_NAME).meta_robots : ""
                                : ""
                        }
                        />
                        <meta name="category" content={
                            referencement ?
                                referencement.find((dt) => dt.pageCible === PAGE_NAME) ? referencement.find((dt) => dt.pageCible === PAGE_NAME).meta_category : ""
                                : ""
                        }
                        />

                        <meta name="identifier-url" content={
                            referencement ?
                                referencement.find((dt) => dt.pageCible === PAGE_NAME) ? referencement.find((dt) => dt.pageCible === PAGE_NAME).meta_identifier_url : ""
                                : ""
                        }
                        />

                        <meta name="reply-to" content={
                            referencement ?
                                referencement.find((dt) => dt.pageCible === PAGE_NAME) ? referencement.find((dt) => dt.pageCible === PAGE_NAME).meta_reply_to : ""
                                : ""
                        }
                        />
                    </Helmet>
                    <Header IMAGES={IMAGES[0] ? IMAGES[0] : empty_img} />
                    <Description IMAGES={IMAGES[0] ? IMAGES[0] : empty_img} />
                    <HowUse />
                    <CasUsage />
                    <Reglementation />
                    <Partenaire IMAGES={IMAGES[0] ? IMAGES[0] : empty_img} />
                </LandingWrapper>
            </div>
            :
            <div className='global-loader'>
                <SpinnerPage />
            </div>
    )
}

const Header = ({ IMAGES }) => {
    const [showModal, setShowModal] = useState(false)

    return (
        <React.Fragment>
            {showModal && <TryModal showModal={showModal} setShowModal={setShowModal} />}
            <div className="landing-plateform__body-content">
                <div className='flip-header'>
                    <Fade duration={4000} left>
                        <div className="landing-plateform__body-content-left">
                            <div className="landing-plateform__body-content-left-title">
                                <h1>La plateforme web DKBSign <p>permet aux utilisateurs de conférer une</p> valeure juridique probante aux documents.</h1>
                            </div>
                            <div onClick={() => setShowModal(true)} className="landing-plateform__body-content-left-button">
                                ESSAYER
                            </div>
                        </div>
                        <div className="landing-plateform__body-content-right">
                            <img
                                src={IMAGES.img1 ? `${BASE_URL_IMG}/${IMAGES.img1}` : "./assets/media/home_icon/Plateforme-signature.png"}
                                className='landing-plateform__body-content-right_img_signataire'
                                alt={IMAGES.alt1 ? `${IMAGES.alt1}` : ""}
                            />
                        </div>
                    </Fade>
                </div>
            </div>
        </React.Fragment>
    )
}

const Partenaire = ({ IMAGES }) => {

    const [images, setImages] = useState([
        {
            id: 1,
            src: IMAGES.img2 ? `${BASE_URL_IMG}/${IMAGES.img2}` : './assets/media/home_icon/ent_1.png',
            alt: IMAGES.alt2 ? `${IMAGES.alt2}` : ""
        },
        {
            id: 2,
            src: IMAGES.img3 ? `${BASE_URL_IMG}/${IMAGES.img3}` : './assets/media/home_icon/ent_2.png',
            alt: IMAGES.alt3 ? `${IMAGES.alt3}` : ""
        },
        {
            id: 3,
            src: IMAGES.img4 ? `${BASE_URL_IMG}/${IMAGES.img4}` : './assets/media/home_icon/ent_3.png',
            alt: IMAGES.alt4 ? `${IMAGES.alt4}` : ""
        },
        {
            id: 4,
            src: IMAGES.img5 ? `${BASE_URL_IMG}/${IMAGES.img5}` : './assets/media/home_icon/ent_4.png',
            alt: IMAGES.alt5 ? `${IMAGES.alt5}` : ""
        },
        {
            id: 5,
            src: IMAGES.img6 ? `${BASE_URL_IMG}/${IMAGES.img6}` : './assets/media/home_icon/ent_5.png',
            alt: IMAGES.alt6 ? `${IMAGES.alt6}` : ""
        },
        {
            id: 6,
            src: IMAGES.img7 ? `${BASE_URL_IMG}/${IMAGES.img7}` : './assets/media/home_icon/ent_6.png',
            alt: IMAGES.alt7 ? `${IMAGES.alt7}` : ""
        },
        {
            id: 7,
            src: IMAGES.img8 ? `${BASE_URL_IMG}/${IMAGES.img8}` : './assets/media/home_icon/ent_7.png',
            alt: IMAGES.alt8 ? `${IMAGES.alt8}` : ""
        },
        {
            id: 8,
            src: IMAGES.img9 ? `${BASE_URL_IMG}/${IMAGES.img9}` : './assets/media/home_icon/ent_8.jpg',
            alt: IMAGES.alt9 ? `${IMAGES.alt9}` : ""
        },
        {
            id: 9,
            src: IMAGES.img10 ? `${BASE_URL_IMG}/${IMAGES.img10}` : './assets/media/home_icon/ent_9.png',
            alt: IMAGES.alt10 ? `${IMAGES.alt10}` : ""
        },
        {
            id: 10,
            src: IMAGES.img11 ? `${BASE_URL_IMG}/${IMAGES.img11}` : './assets/media/home_icon/ent_10.png',
            alt: IMAGES.alt11 ? `${IMAGES.alt11}` : ""
        },
        {
            id: 11,
            src: IMAGES.img12 ? `${BASE_URL_IMG}/${IMAGES.img12}` : './assets/media/home_icon/ent_11.png',
            alt: IMAGES.alt12 ? `${IMAGES.alt12}` : ""
        },
        {
            id: 12,
            src: IMAGES.img13 ? `${BASE_URL_IMG}/${IMAGES.img13}` : './assets/media/home_icon/ent_12.png',
            alt: IMAGES.alt13 ? `${IMAGES.alt13}` : ""
        }
    ])

    return (
        <div className="landing-partenaire-plateform">
            <Fade duration={4000} left>
                <p className="landing-partenaire__title">Ils nous font confiance</p>
            </Fade>
            <div className="landing-partenaire__content">
                <marquee direction="left" scrollamount="10">
                    {images.map((image, index) => { return <ItemPartenaire image={image} key={index} /> })}
                </marquee>
            </div>
        </div>
    )
}

const ItemPartenaire = ({ image }) => {
    return (
        <img className="landing-partenaire_items" src={image.src} alt={image.alt} />
    )
}

const CasUsage = () => {
    const [casusage] = useState([
        {
            id: 1,
            logo: <BsWindowSidebar className='landing-casusage__content-items-icons' />,
            hasSignBg: true,
            title: 'En ligne',
            description: 'En intégrant la signature électronique à votre site web, vous dématérialisez vos parcours de vente, d’abonnement ou de souscription en ligne :',
            items: [
                'Vous proposez à vos clients une expérience entièrement digitalisée. Une simple connexion à Internet, un smartphone ou un PC suffisent pour signer vos documents.',
                'Vous bénéficiez de parcours de signatures cross-canal avec la possibilité de changer de canal en cours de processus.',
                'Vous améliorez vos taux de transformation, tout en sécurisant le recueil du consentement de vos clients et de vos prospects.'
            ]
        },
        {
            id: 2,
            logo: <FcAssistant className='landing-casusage__content-items-icons' />,
            title: 'À distance',
            bg: 'Green',
            description: 'La signature électronique simplifie les processus dans les cas de signataires multiples qui ne sont pas tous présents au même endroit au même moment :',
            items: [
                'Chaque signataire reçoit, par e-mail, une invitation pour accéder au document, le consulter et le signer.',
                'Vous êtes télévendeur ? Au cours d’un entretien téléphonique, vous pouvez instantanément formaliser des opérations sans avoir à recueillir de signatures à posteriori.',
                'Vous économisez du temps et n’avez plus besoin de réunir chacun des signataires lorsque ces derniers sont éloignés géographiquement.'
            ]
        },
        {
            id: 3,
            logo: <GiBodySwapping className='landing-casusage__content-items-icons' />,
            title: 'En face-à-face',
            hasSignBg: true,
            description: 'Particulièrement adaptée aux équipes mobiles et au point de vente physique, vous pouvez faire signer vos documents en face-à-face avec un PC, une tablette ou un smartphone :',
            items: [
                'Vous offrez une expérience optimale omnicanale tout en conservant les codes de la signature manuscrite.',
                'Plus fluide, plus dynamique et plus agréable, vous concluez des opérations directement lors d’un RDV, sans avoir à recueillir de signatures à posteriori.',
                'Vous améliorez votre image de marque et la qualité de vos procédures administratives.'
            ]
        },
    ])

    return (
        <div className="landing-casusage">
            <Fade duration={4000} left>
                <div className="landing-casusage__title">Cas d’usages de la signature électronique</div>
                <div className="landing-casusage__desc">
                    Sécurité renforcée, suivi en temps réel du statut des documents, réduction des délais, efficacité commerciale accrue, amélioration de la relation client, économies de temps et de frais d’impression, d’archivage… Les avantages liés à la signature électronique sont nombreux. Les entreprises sont donc gagnantes sur tous les plans.
                    Cette dernière peut être utilisée de bien des manières :
                </div>
            </Fade>

            <div className="landing-casusage__content">
                {casusage.map((cas) => (<CasUsageItems key={cas.id} {...cas} />))}
            </div>
        </div>
    )
}

const Description = ({ IMAGES }) => {
    return (
        <div className="platform-descs">
            <div className='plateform-header-desc'>
                Grâce à la signature electronique, elle permet de donner la preuve du consentement d'un signatairesur le contenu
                d'un document et prouve:

                <li>L'intégrité du document, Document non altérable</li>

                <li>L'identité certaine du ou des signataire par une double authentification</li>

                Envoyez et signez vos documents en toute sécurité.
                Sécurisez vos transactions électroniques et assurez leur traçabilité+
            </div>

            <div className="plateform-header-desc-img">
                <img 
                src={IMAGES.img14 ? `${BASE_URL_IMG}/${IMAGES.img14}` : "./assets/media/home_icon/Signature-verification.png" }
                className='desc-img' 
                alt={IMAGES.alt14 ? `${IMAGES.alt14}` : ""}
                />
            </div>
        </div>
    )
}

const HowUse = () => {
    const [casusage] = useState([
        {
            id: 1,
            logo: <BsWindowSidebar className='landing-casusage__content-items-icons-cust' />,
            title: 'Depuis notre portail web DKBSign',
            description: 'Signez et faites signer tous vos documents PDF: sélectionnez le fichier, désignez les signataires et envoyez !',
            items: []
        }
    ])

    return (
        <div style={{ backgroundColor: 'transparent' }} className="landing-casusage">

            <div className="landing-casusage__title">Comment utiliser la signature electronique ?</div>


            <div className="landing-casusage__content">
                {casusage.map((cas) => (<HowUseItems key={cas.id} {...cas} />))}
            </div>

            <div className="just-for-mg">
                <Fade duration={4000} left>
                    <div className="fonctionnality-associated-title">Fonctionnalités associées :</div>
                </Fade>

                <FunctionnalityAssociated />
            </div>
        </div>
    )
}

const HowUseItems = ({ logo, title, description, items }) => {
    return (
        <div style={{ height: 'auto', width: 'auto' }} className="landing-casusage__content-items-cust">
            <div className="landing-casusage__content-items-logo">{logo}</div>
            <div className="use-case-rigth-cust">
                <div className="landing-casusage__content-items-title">{title}</div>
                <div className="landing-casusage__content-items-desc">{description}</div>

                <div className="use-case-rigth-card">
                    {items.map((item) => (
                        <div className="landing-casusage__content-items-items">
                            <div className="bullshist"><BsCheckCircleFill className='landing-casusage__content-items-icons-check' /></div>
                            <div style={{ width: '90%', marginLeft: '15px' }} className="landing-casusage__content-items-desc">{item}</div>
                        </div>
                    ))}
                </div>

            </div>
        </div>
    )
}

const CasUsageItems = ({ logo, title, description, items, bg = '', hasSignBg = false }) => {
    return (
        <div className="landing-casusage__content-items">
            <Fade duration={4000} left>
                <div className="landing-casusage__content-items-left">
                    <div style={{ textAlign: 'center' }} className="landing-casusage__content-items-title">{title}</div>
                    <div className="landing-casusage__content-items-logo">{logo}</div>
                </div>
            </Fade>

            <Fade duration={4000} right>
                <div className={hasSignBg ? `use-case-rigth-sign` : `use-case-rigth${bg}`}>
                    <div className="landing-casusage__content-items-desc">{description}</div>

                    <div className={`use-case-rigth-card`}>
                        {items.map((item) => (
                            <div className="landing-casusage__content-items-items">
                                <div className="bullshist"><BsCheckCircleFill className={`landing-casusage__content-items-icons-check${bg}`} /></div>
                                <div style={{ width: '90%', marginLeft: '15px' }} className={`landing-casusage__content-items-desc${bg}`}>{item}</div>
                            </div>
                        ))}
                    </div>

                </div>
            </Fade>
        </div>
    )
}

const FunctionnalityAssociated = () => {
    return (
        <Fade duration={4000} right>
            <div className="fonctionnality-associated">
                <p style={{ textAlign: 'center', marginTop: '2rem', color: 'black' }} className='card-two-paragraph'>
                    La plateforme SaaS DKBSign repose sur
                    <strong>une infrastructure informatique performante et hautement sécurisée</strong>, elle vous offre une garantie de continuité de services
                    avec un taux de disponibilité de +99,9% par an.
                </p>
                <div className="fonctionnality-associated-content">
                    <div className="fonctionnality-associated-items">
                        <li><BsCheckCircleFill size={25} /> Gestion des documents à lire et optionnels</li>
                        <li><BsCheckCircleFill size={25} /> Gestion des transactions multi-document</li>
                        <li><BsCheckCircleFill size={25} /> Choix du niveau de signature</li>
                        <li><BsCheckCircleFill size={25} /> Personnalisation du contenu des emails et des SMS</li>
                    </div>
                    <div className="fonctionnality-associated-items">
                        <li><BsCheckCircleFill size={25} /> Notification en temps réel</li>
                        <li><BsCheckCircleFill size={25} /> Tableaux de bord & reporting</li>
                        <li><BsCheckCircleFill size={25} /> Page de signature multilingue : français, anglais, allemand, espagnol, italien, néerlandais, portugais, polonais, bulgare et roumain</li>
                    </div>
                </div>
            </div>
        </Fade>
    )
}

const Reglementation = () => {
    return (
        <React.Fragment>
            <div className='landing-plateform-reglementation'>
                <Fade duration={4000} left>
                    <div className="landing-plateform-reglementation-title">Quelle est la réglementation en vigueur ?</div>
                    <div className="landing-plateform-reglementation-desc">
                        Encadré et réglementé par L'ARTCI, la signature électronique offre aujourd'hui un haut niveau de sécurité et possède la même valeur légale que la signature manuscrite.
                    </div>
                </Fade>


                <div className="landing-plateform-reglementation-content">
                    <p className='reglementation-txt'>
                        Selon le décret n°2014-106 12 mars 2014
                        Fixant les conditions  d'établissement et de conservation de l'ecrit et de la signature sous forme électronique.
                    </p>
                    <p className='reglementation-txt-bwd'>
                        DKBSign en tant que Prestataire de Services agréé par l’ARTCI, délivre des services de signatures électroniques conformes à la loi sur les transactions électroniques promulguée par l’Etat de Côte- d’Ivoire et répondant aux exigences suivantes :
                    </p>
                    <div className="reglementation-content-items">
                        <div className='reglementation-item'>
                            <BsCheckCircleFill className='reglementation-item-icon' />
                            <p>Être propre au signataire</p>
                        </div>
                        <div className='reglementation-item'>
                            <BsCheckCircleFill className='reglementation-item-icon' />
                            <p>Être créée par des moyens que le signataire garde sous son contrôle exclusif</p>
                        </div>
                        <div className='reglementation-item'>
                            <BsCheckCircleFill className='reglementation-item-icon' />
                            <p>Garantir avec l’acte auquel elle s’attache un lien tel que toute modification ultérieure de l’acte soit détectable</p>
                        </div>
                        <div className='reglementation-item'>
                            <BsCheckCircleFill className='reglementation-item-icon' />
                            <p>Utiliser un dispositif sécurisé de création de signature électronique.</p>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
