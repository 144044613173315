import React from 'react'

export default function HeaderTable({ children, setShowForm, setDataEdit }) {
  return (
    <div className="card">
      <div className="card-header border-0 pt-6">
        <div className="card-title">

        </div>
        <div className="card-toolbar">
          <div
            className="d-flex justify-content-end"
            data-kt-user-table-toolbar="base"
          >

            <div
              className="menu menu-sub menu-sub-dropdown w-300px w-md-325px"
              data-kt-menu="true"
            >
              <div className="separator border-gray-200" />
            </div>

            {/* <button
              type="button"
              className="btn btn-primary"
              data-bs-toggle="modal"
              data-bs-target="#kt_modal_new_target"
              onClick={() => {
                setShowForm(true)
                setDataEdit(null)
              }}
            >
              <span className="svg-icon svg-icon-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <rect
                    opacity="0.5"
                    x="11.364"
                    y="20.364"
                    width={16}
                    height={2}
                    rx={1}
                    transform="rotate(-90 11.364 20.364)"
                    fill="currentColor"
                  />
                  <rect
                    x="4.36396"
                    y="11.364"
                    width={16}
                    height={2}
                    rx={1}
                    fill="currentColor"
                  />
                </svg>
              </span>
              Ajouter
            </button> */}
          </div>
      
        </div>
      </div>
      {children}
    </div>
  )
}
