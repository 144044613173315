import React, { useState, useEffect } from 'react'
import LandingWrapper from './LandingWrapper'
import { BsArrowRight, BsCalendarWeek } from 'react-icons/bs'
import { Helmet } from "react-helmet";
import Carousel from 'react-grid-carousel'
import Fade from 'react-reveal/Fade';
import { GetReferencement, GetActualite, GetClient } from './Admin/Main/services'
import './styles.css'

const PAGE_NAME = 'REFERENCE'
function trimString(chaine) {
    if (chaine.length > 150) {
        return chaine.slice(0, 150) + '...';
    } else {
        return chaine;
    }
}

export default function LandingReference() {

    const [partenariats] = useState([
        {
            id: 1,
            name: 'Entreprise 1',
            img: null,
            description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, quod., ur adipisicing elit. Quisquam, quod, ur adipisicing elit. Quisquam, quod'
        },
        {
            id: 2,
            name: 'Entreprise 2',
            img: null,
            description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, quod., ur adipisicing elit. Quisquam, quod, ur adipisicing elit. Quisquam, quod, ur adipisicing elit. Quisquam, quod'
        },
        {
            id: 3,
            name: 'Entreprise 3',
            img: null,
            description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, quod., ur adipisicing elit. Quisquam, quod, ur adipisicing elit. Quisquam, quod, ur adipisicing elit. Quisquam, quod, ur adipisicing elit. Quisquam, quod'
        }
    ])

    const [referencement, setReferencement] = useState(null)
    const [actualite, setActualite] = useState(null)
    const [client, setClient] = useState(null)

    useEffect(() => {
        GetReferencement().then(source => setReferencement(source))
        GetActualite().then(source => setActualite(source))
        GetClient().then(source => setClient(source))
    }, [])

    return (
        <LandingWrapper>
            <Helmet>
                <title>
                    {
                        referencement ?
                            referencement.find((dt) => dt.pageCible === PAGE_NAME) ? referencement.find((dt) => dt.pageCible === PAGE_NAME).siteTitle : "DKBSign"
                            : "DKBSign"
                    }
                </title>
                <meta
                    name="description"
                    content={
                        referencement ?
                            referencement.find((dt) => dt.pageCible === PAGE_NAME) ? referencement.find((dt) => dt.pageCible === PAGE_NAME).meta_description : ""
                            : ""
                    }
                />

                <meta
                    name="keywords"
                    lang="fr"
                    content={
                        referencement ?
                            referencement.find((dt) => dt.pageCible === PAGE_NAME) ? referencement.find((dt) => dt.pageCible === PAGE_NAME).meta_keywords : ""
                            : ""
                    }
                />

                <meta name="robots" content={
                    referencement ?
                        referencement.find((dt) => dt.pageCible === PAGE_NAME) ? referencement.find((dt) => dt.pageCible === PAGE_NAME).meta_robots : ""
                        : ""
                }
                />
                <meta name="category" content={
                    referencement ?
                        referencement.find((dt) => dt.pageCible === PAGE_NAME) ? referencement.find((dt) => dt.pageCible === PAGE_NAME).meta_category : ""
                        : ""
                }
                />

                <meta name="identifier-url" content={
                    referencement ?
                        referencement.find((dt) => dt.pageCible === PAGE_NAME) ? referencement.find((dt) => dt.pageCible === PAGE_NAME).meta_identifier_url : ""
                        : ""
                }
                />

                <meta name="reply-to" content={
                    referencement ?
                        referencement.find((dt) => dt.pageCible === PAGE_NAME) ? referencement.find((dt) => dt.pageCible === PAGE_NAME).meta_reply_to : ""
                        : ""
                }
                />
            </Helmet>
            <Header />
            <Actualite data={actualite} />
            <div className="we-trust-us">
                <Fade duration={3000} left>
                    <p className="landing-partenaire__title">Success stories</p>
                </Fade>
                <ConfianceGroup partenariats={client} />
            </div>
        </LandingWrapper>
    )
}

const Header = () => {
    return (
        <React.Fragment>
            <div className="landing-reference__body-content">
                <div className="flip-header-reference">
                    <Fade duration={4000} left>
                        <div className="landing-reference__body-content-left-title">
                            <h1>Les clients et les références</h1>
                            <p>de notre offre de signature</p>
                        </div>
                    </Fade>
                </div>
            </div>
        </React.Fragment>
    )
}

const Actualite = ({ data }) => {
    return (
        <div className="landing-actualite">
            <p className="landing-partenaire__title">Actualités</p>
            <div className="landing-actualite__content">
                <div className="news-list">
                    <Fade duration={3000} top>
                        {data ? data.length > 0 ? data.map((dt, key) => <ActualiteItem key={key} id={dt.slug} titre={dt.title} alt={dt.alt} subtitle={dt.subtitle} posteddate={dt.posteddate} img={dt.img} />) : <></> : <></>}
                    </Fade>
                </div>
                <div style={{ marginLeft: '18px' }} className="we-trust-us_content-direction">
                    <BsArrowRight color='white' />
                </div>
            </div>

        </div>
    )
}

const ActualiteItem = ({ id, titre, alt, posteddate, subtitle, img }) => {

    const handleClick = () => {
        window.location.href = `/actuality/${id}`
    }

    return (
        <div onClick={() => handleClick()} className="landing-actualite_items">
            <img src={`https://dkbsign.com/dkbsign_web_api/public/storage/actualities/${img}`} alt={alt} className="landing-actualite_items-img" />

            <div className="landing-actualite_items-content">
                <div className="landing-actualite_items-content-description">{trimString(subtitle)}</div>
                <div className="landing-actualite_items-content-title">{titre}</div>

                <div className="landing-actualite_items-content-profile">
                    <BsCalendarWeek className="landing-actualite_items-content-profile-img" />
                    <div className="landing-actualite_items-content-profile-content">
                        <div className="landing-actualite_items-content-profile-content-date">{posteddate}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const ConfianceGroup = ({ partenariats }) => {
    return (
        <div className="list-confiance">
            {partenariats && partenariats.map((data, key) => (
                <Confiance key={key} author={data.author} authorfunction={data.authorfunction} alt={data.alt} img={data.img} description={data.description} />
            ))}
        </div>
    )
}

const Confiance = ({ img, description, author, authorfunction, alt }) => {
    return (
        <div className="confiance_items">
            <img className="img-header" alt={alt} src={`https://dkbsign.com/dkbsign_web_api/public/storage/clients/${img}`} />
            <div className="confiance-description">
                {description}
            </div>
            <div className="bar-line" />

            <div className="confiance-author">
                <div className="confiance-author-name">{author || ''}</div>
                <div className="confiance-author-job">{authorfunction || ''}</div>
            </div>
        </div>
    )
}