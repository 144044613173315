import React, { useState } from 'react'
import { Formik, Form, ErrorMessage } from 'formik';
import { AddReferencement, EditReferencement } from './services';
import './styles.css'
import * as Yup from "yup";

export default function Forms({ showForm, setShowForm, data }) {

    const [initialValues] = useState({
        pageCible: data ? data.pageCible : '',
        siteTitle: data ? data.siteTitle : '',
        meta_keywords: data ? data.meta_keywords : '',
        meta_description: data ? data.meta_description : '',
        meta_robots: data ? data.meta_robots : '',
        meta_category: data ? data.meta_category : '',
        meta_identifier_url: data ? data.meta_identifier_url : '',
        meta_reply_to: data ? data.meta_reply_to : ''
    })

    const ReferencementSchema = Yup.object().shape({
        pageCible: Yup.string()
            .required('Champs requis'),

        siteTitle: Yup.string()
            .required('Champs requis!'),

        meta_keywords: Yup.string()
            .required('Champs requis!'),

        meta_description: Yup.string()
            .required('Champs requis!'),

        meta_robots: Yup.string()
            .required('Champs requis!'),

        meta_category: Yup.string()
            .required('Champs requis!'),

        meta_identifier_url: Yup.string()
            .required('Champs requis!'),

        meta_reply_to: Yup.string()
            .required('Champs requis!')
    });

    return (
        <div
            className={showForm ? "modal fade show" : "modal fade"}
            id="kt_modal_new_target"
            tabIndex={-1}
            style={{ display: showForm ? "block" : "none", marginTop: '0px' }}
        >
            <div className="modal-dialog modal-dialog-centered mw-650px">
                <div className="modal-content rounded" style={{ width: '100%' }}>
                    <div className="modal-header pb-0 border-0 justify-content-end">
                        <div
                            className="btn btn-sm btn-icon btn-active-color-primary"
                            data-bs-dismiss="modal"
                            onClick={() => setShowForm(false)}
                        >
                            <span className="svg-icon svg-icon-1">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                >
                                    <rect
                                        opacity="0.5"
                                        x={6}
                                        y="17.3137"
                                        width={16}
                                        height={2}
                                        rx={1}
                                        transform="rotate(-45 6 17.3137)"
                                        fill="currentColor"
                                    />
                                    <rect
                                        x="7.41422"
                                        y={6}
                                        width={16}
                                        height={2}
                                        rx={1}
                                        transform="rotate(45 7.41422 6)"
                                        fill="currentColor"
                                    />
                                </svg>
                            </span>
                        </div>
                    </div>

                    <Formik
                        initialValues={initialValues}

                        validationSchema={ReferencementSchema}

                        onSubmit={async (values) => {

                            const dataSend = {
                                id: data ? data.id : 0,
                                pageCible: values.pageCible,
                                siteTitle: values.siteTitle,
                                meta_description: values.meta_description,
                                meta_robots: values.meta_robots,
                                meta_keywords: values.meta_keywords,
                                meta_category: values.meta_category,
                                meta_identifier_url: values.meta_identifier_url,
                                meta_reply_to: values.meta_reply_to
                            }

                            data ? await EditReferencement(dataSend) : await AddReferencement(dataSend)
                            setShowForm(false)
                        }}
                    >

                        {({ handleChange, handleBlur, values, handleSubmit }) => (
                            <Form>
                                <div className="modal-body scroll-y px-10 px-lg-15 pt-0 pb-15">

                                    <div className="mb-13 text-center">
                                        <h1 className="mb-3">{data ? 'Modifier le referencement' : 'Ajouter un referencement'}</h1>
                                    </div>

                                    <div className="row mb-5">
                                        <div className="col-md-6 fv-row fv-plugins-icon-container">
                                            <label className="required fs-5 fw-bold mb-2">Page cible</label>
                                            <input
                                                type="text"
                                                className="form-control form-control-solid"
                                                placeholder="Page cible"
                                                name="pageCible"
                                                value={values.pageCible}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            <div className="fv-plugins-message-container invalid-feedback" />
                                            <ErrorMessage className="error-msg" name="pageCible" component={'div'} />
                                        </div>
                                        <div className="col-md-6 fv-row fv-plugins-icon-container">
                                            <label className="required fs-5 fw-bold mb-2">Page titre</label>
                                            <input
                                                type="text"
                                                className="form-control form-control-solid"
                                                placeholder="Page titre"
                                                name="siteTitle"
                                                value={values.siteTitle}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            <div className="fv-plugins-message-container invalid-feedback" />
                                            <ErrorMessage className="error-msg" name="siteTitle" component={'div'} />
                                        </div>
                                    </div>

                                    <div className="row mb-5">
                                        <div className="col-md-6 fv-row fv-plugins-icon-container">
                                            <label className="required fs-5 fw-bold mb-2">Meta description</label>
                                            <input
                                                type="text"
                                                className="form-control form-control-solid"
                                                placeholder="Page cible"
                                                name="meta_description"
                                                value={values.meta_description}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            <div className="fv-plugins-message-container invalid-feedback" />
                                            <ErrorMessage className="error-msg" name="meta_description" component={'div'} />
                                        </div>
                                        <div className="col-md-6 fv-row fv-plugins-icon-container">
                                            <label className="required fs-5 fw-bold mb-2">Meta robots</label>
                                            <input
                                                type="text"
                                                className="form-control form-control-solid"
                                                placeholder="Page titre"
                                                name="meta_robots"
                                                value={values.meta_robots}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            <div className="fv-plugins-message-container invalid-feedback" />
                                            <ErrorMessage className="error-msg" name="meta_robots" component={'div'} />
                                        </div>
                                    </div>

                                    <div className="row mb-5">
                                        <div className="col-md-6 fv-row fv-plugins-icon-container">
                                            <label className="required fs-5 fw-bold mb-2">Meta keywords</label>
                                            <input
                                                type="text"
                                                className="form-control form-control-solid"
                                                placeholder="Page cible"
                                                name="meta_keywords"
                                                value={values.meta_keywords}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            <div className="fv-plugins-message-container invalid-feedback" />
                                            <ErrorMessage className="error-msg" name="meta_keywords" component={'div'} />
                                        </div>
                                        <div className="col-md-6 fv-row fv-plugins-icon-container">
                                            <label className="required fs-5 fw-bold mb-2">Meta category</label>
                                            <input
                                                type="text"
                                                className="form-control form-control-solid"
                                                placeholder="Page titre"
                                                name="meta_category"
                                                value={values.meta_category}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            <div className="fv-plugins-message-container invalid-feedback" />
                                            <ErrorMessage className="error-msg" name="meta_category" component={'div'} />
                                        </div>
                                    </div>

                                    <div className="row mb-5">
                                        <div className="col-md-6 fv-row fv-plugins-icon-container">
                                            <label className="required fs-5 fw-bold mb-2">Meta identifier url</label>
                                            <input
                                                type="text"
                                                className="form-control form-control-solid"
                                                placeholder="Page cible"
                                                name="meta_identifier_url"
                                                value={values.meta_identifier_url}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            <div className="fv-plugins-message-container invalid-feedback" />
                                            <ErrorMessage className="error-msg" name="meta_identifier_url" component={'div'} />
                                        </div>
                                        <div className="col-md-6 fv-row fv-plugins-icon-container">
                                            <label className="required fs-5 fw-bold mb-2">Meta reply to</label>
                                            <input
                                                type="text"
                                                className="form-control form-control-solid"
                                                placeholder="Page titre"
                                                name="meta_reply_to"
                                                value={values.meta_reply_to}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            <div className="fv-plugins-message-container invalid-feedback" />
                                            <ErrorMessage className="error-msg" name="meta_reply_to" component={'div'} />
                                        </div>
                                    </div>

                                    <div className="text-center">
                                        <button
                                            type="reset"
                                            id="kt_modal_new_target_cancel"
                                            onClick={() => setShowForm(false)}
                                            className="btn btn-light me-3"
                                        >
                                            Annuler
                                        </button>
                                        <button
                                            type="submit"
                                            onClick={handleSubmit}
                                            data-bs-dismiss="modal"
                                            className="btn btn-primary"
                                        >
                                            <span className="indicator-label">Soumettre</span>
                                            <span className="indicator-progress">
                                                Please wait...
                                                <span className="spinner-border spinner-border-sm align-middle ms-2" />
                                            </span>
                                        </button>
                                    </div>
                                    <div />
                                </div>
                            </Form>
                        )}
                    </Formik>

                </div>
            </div>
        </div>
    )
}
