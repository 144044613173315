import React,{ useRef, useState, useEffect } from 'react';
import {clearState, login, userSelector} from '../../services/reduxSlices/UserSlice'
import { useDispatch, useSelector } from 'react-redux';
import Logo from '../../public/asset/img/logodkbsign.png';
import sign from '../../public/asset/img/notary-signature.webp';
import Loader from '../../components/Loader/Loader'
import { toast } from "react-toastify";
import { useNavigate } from 'react-router-dom'
import './Login.css'
import Cookies from 'js-cookie'

import { useTranslateContext } from '../../hooks';

const Login = () => {
    const { translate } = useTranslateContext();
    const navigate = useNavigate()
    const usernameRef = useRef()
    const passwordRef = useRef()
    
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const { isFetching, isLogin } = useSelector(userSelector)
    const [remember, setRemember] = useState(false)

    useEffect(() => {
        if(Cookies.get('token')){
          navigate('/home');
        }
    },[isLogin]);

    const showToastError = (error) =>{
        toast.error(error, {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    const checkForm = (e) =>{
        e.preventDefault()
        const data = {
            'email' : usernameRef.current.value,
            'password' : passwordRef.current.value,
            'remember' : remember
        }

        if(!data.email || !data.password || data.email === undefined || data.password === undefined){
            showToastError(translate.admin.veuillezSaisirToutLesChamps)
        }else if(data.password.length < 8){
            showToastError(translate.admin.leMotDasseDoitContenirAuMinimumCaracteres)
        }else{
            dispatch(login(data))
        }

    }

    return(
        <React.Fragment>
            <div className="login-page">
                <div className="form-connexion">
                    <div className="content-connexion">

                        <div className="head-img">
                            <img src={sign} alt="" />
                        </div>

                        <div className="header">
                            <p>{translate.admin.connect}</p>
                        </div>

                        <form action='' method='post'>
                            <div className="input-content">
                                <input ref={usernameRef} type="text" placeholder='Username' />
                            </div>
                            <div className="input-content">
                                <input ref={passwordRef} type="password" placeholder='Password'/>
                            </div>
                            {/** se souvenir de moi */}
                            <div className="input-content-remember">
                                <input 
                                    defaultChecked={remember} 
                                    onChange={(e)=> setRemember(!remember)}
                                    type="checkbox" 
                                    name="remember" 
                                    id="input-remember" 
                                />
                                <label htmlFor="remember">{translate.admin.souvenirDeMoi}</label>
                            </div>
                            <div className="button-content">
                                {loading ? <Loader /> :  <button onClick={(e)=> checkForm(e)} type='submit'>{translate.admin.connect}</button>}
                            </div>
                        </form>

                        <div className="footer">
                            <img src={Logo} alt="logo" />
                        </div>

                    </div>
                </div>
            </div>      
        </React.Fragment>
    )
}
export default Login;