import React, { useEffect } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
// import { FcDocument } from 'react-icons/fc';

export default function PreviewAllDocs({
  setCurrentFile,
  docList,
  DOCUMENT,
  SETDOCUMENT,
  setIsSwitch,
  isSwicth,
}) {
  const formatDoc = (docList) => {
    var newDoc = [];

    for (let i = 0; i < docList.length; i++) {
      newDoc.push({
        id: i + 1,
        link: docList[i],
        className: i == 0 ? 'active' : '',
        nbSignature: 0,
        typeSign: [],
      });
    }

    return newDoc;
  };

  useEffect(() => {
    const lsDoc = formatDoc(docList);
    SETDOCUMENT(lsDoc);
    pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  }, []);

  const switchDocument = (link) => {
    var qr = document.getElementById('exit-qrcode');
    var sign = document.getElementById('exit-signature');
    var square = document.getElementById('exit-square');

    if (qr) qr.remove();
    //if(sign) sign.remove()
    if (square) square.remove();
    setIsSwitch(true);
    //setIsSigned([])
    setCurrentFile(link);

    var newLsDoc = DOCUMENT.map((doc) => {
      if (doc.link == link)
        return {
          id: doc.id,
          link: doc.link,
          className: 'active',
          nbSignature: doc.nbSignature,
          typeSign: doc.typeSign,
        };
      else
        return {
          id: doc.id,
          link: doc.link,
          className: '',
          nbSignature: doc.nbSignature,
          typeSign: doc.typeSign,
        };
    });

    SETDOCUMENT(newLsDoc);
  };

  return (
    <div>
      <p style={{ fontSize: 17, paddingLeft: '20px' }}>Document</p>
      <div
        style={{
          padding: '5px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          flexGrow: 1,
        }}
      >
        {DOCUMENT.map((doc, i) => {
          return (
            <Document file={doc.link} key={i}>
              <Page
                size="A4"
                renderTextLayer={false}
                width={100}
                onClick={() => switchDocument(doc.link)}
                pageNumber={1}
              />
            </Document>
          );
        })}
      </div>
    </div>
  );
}
