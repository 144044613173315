import React from 'react'
import { DeleteActualite } from './services'

export default function Delete({showDelete, setShowDelete, data}) {

  return (
    <div
        className={showDelete ? "modal fade show" : "modal fade"}
        id="kt_modal_new_target"
        tabIndex={-1}
        style={{ display: showDelete ? "block" : "none", marginTop: '0px' }}
    >
        <div className="modal-dialog modal-dialog-centered mw-650px">
          <div className="modal-content rounded" style={{width: '100%'}}>
            <div className="modal-header pb-0 border-0 justify-content-end">
            </div>
            <h2 style={{textAlign:'center', marginBottom:'25px'}}>Voulez vous vraiment supprimer cet actualite ?</h2>
            <div className="text-center">
              <button
                type="reset"
                id="kt_modal_new_target_cancel"
                onClick={()=> setShowDelete(false)}
                className="btn btn-light me-3"
              >
                Annuler
              </button>
              
              <button
                type="submit"
                  onClick={async ()=>{
                    setShowDelete(false)
                    await DeleteActualite({id: data.id})
                  }}
                  data-bs-dismiss="modal"
                  style={{color:'red'}}
                  className="btn"
                >
                  <span className="indicator-label">Supprimer</span> 
              </button>
            </div>
          </div>
        </div>
      </div>
  )
}
