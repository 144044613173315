import React from 'react'
import Navbar from '../Navbar/Navbar'

/**
 * 
 * Composant d'envellope principale
 * @param {children} param0 
 * @returns 
 */
export default function Container({children}) {
  return (
    <React.Fragment>
      <div className="d-flex flex-column flex-root">
        <div className="page d-flex flex-row flex-column-fluid">
          <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
            <Navbar />
            <div id="kt_content_container" className="d-flex flex-column-fluid align-items-start container-xxl">
                <div className="content flex-row-fluid" id="kt_content">
                    <div id="kt_content_container" className="d-flex flex-column-fluid align-items-start container-xxl">
                <div className="content flex-row-fluid" id="kt_content">
                  <div className="row g-5 g-xl-10 mb-xl-10" style={{width: '100%'}}>
                                {children}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}