import React from 'react'
import { FcContacts } from 'react-icons/fc'

import { useTranslateContext } from '../../hooks';

export default function HeaderTable({children, setShowForm, setDataEdit, onApplySearch, setShowImport}) {
  const { translate } = useTranslateContext();
  return (
    <div className="card">
    {/*begin::Card header*/}
    <div className="card-header border-0 pt-6">
      {/*begin::Card title*/}
      <div className="card-title">
        {/*begin::Search*/}
        <div className="d-flex align-items-center position-relative my-1">
          {/*begin::Svg Icon | path: icons/duotune/general/gen021.svg*/}
          <span className="svg-icon svg-icon-1 position-absolute ms-6">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
            >
              <rect
                opacity="0.5"
                x="17.0365"
                y="15.1223"
                width="8.15546"
                height={2}
                rx={1}
                transform="rotate(45 17.0365 15.1223)"
                fill="currentColor"
              />
              <path
                d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                fill="currentColor"
              />
            </svg>
          </span>
          {/*end::Svg Icon*/}
          <input
            type="text"
            onChange={(e)=> onApplySearch(e)}
            data-kt-user-table-filter="search"
            className="form-control form-control-solid w-250px ps-14"
            placeholder={translate.admin.rechercherUnContact}
          />
        </div>
        {/*end::Search*/}
      </div>
      {/*begin::Card title*/}
      {/*begin::Card toolbar*/}
      <div className="card-toolbar">
        {/*begin::Toolbar*/}
        <div
          className="d-flex justify-content-end"
          data-kt-user-table-toolbar="base"
        >

          {/*begin::Menu 1*/}
          <div
            className="menu menu-sub menu-sub-dropdown w-300px w-md-325px"
            data-kt-menu="true"
          >
            <div className="separator border-gray-200" />
          </div>

          <button
            type="button"
            className="btn btn-secondary mr-2"
            data-bs-toggle="modal"
            data-bs-target="#kt_modal_new_target"
            onClick={()=> {
              setShowImport(true)
              setDataEdit(null)
            }}
          >
            <FcContacts />{translate.admin.importerContact}
          </button>
         
          <button
            type="button"
            className="btn btn-primary"
            data-bs-toggle="modal"
            data-bs-target="#kt_modal_new_target"
            onClick={()=> {
              setShowForm(true)
              setDataEdit(null)
            }}
          >
            {/*begin::Svg Icon | path: icons/duotune/arrows/arr075.svg*/}
            <span className="svg-icon svg-icon-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
              >
                <rect
                  opacity="0.5"
                  x="11.364"
                  y="20.364"
                  width={16}
                  height={2}
                  rx={1}
                  transform="rotate(-90 11.364 20.364)"
                  fill="currentColor"
                />
                <rect
                  x="4.36396"
                  y="11.364"
                  width={16}
                  height={2}
                  rx={1}
                  fill="currentColor"
                />
              </svg>
            </span>
            {/*end::Svg Icon*/} {translate.admin.ajouterContact}
          </button>
        </div>
        {/*end::Toolbar*/}
        {/*begin::Group actions*/}
        <div
          className="d-flex justify-content-end align-items-center d-none"
          data-kt-user-table-toolbar="selected"
        >
          <div className="fw-bolder me-5">
            <span className="me-2" data-kt-user-table-select="selected_count" />
            Selected
          </div>
          <button
            type="button"
            className="btn btn-danger"
            data-kt-user-table-select="delete_selected"
          >
            Delete Selected
          </button>
        </div>
        {/*end::Group actions*/}
        {/*begin::Modal - Adjust Balance*/}
        <div
          className="modal fade"
          id="kt_modal_export_users"
          tabIndex={-1}
          aria-hidden="true"
        >
          {/*begin::Modal dialog*/}
          <div className="modal-dialog modal-dialog-centered mw-650px">
            {/*begin::Modal content*/}
            <div className="modal-content">
              {/*begin::Modal header*/}
              <div className="modal-header">
                {/*begin::Modal title*/}
                <h2 className="fw-bolder">Export Users</h2>
                {/*end::Modal title*/}
                {/*begin::Close*/}
                <div
                  className="btn btn-icon btn-sm btn-active-icon-primary"
                  data-kt-users-modal-action="close"
                >
                  {/*begin::Svg Icon | path: icons/duotune/arrows/arr061.svg*/}
                  <span className="svg-icon svg-icon-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <rect
                        opacity="0.5"
                        x={6}
                        y="17.3137"
                        width={16}
                        height={2}
                        rx={1}
                        transform="rotate(-45 6 17.3137)"
                        fill="currentColor"
                      />
                      <rect
                        x="7.41422"
                        y={6}
                        width={16}
                        height={2}
                        rx={1}
                        transform="rotate(45 7.41422 6)"
                        fill="currentColor"
                      />
                    </svg>
                  </span>
                  {/*end::Svg Icon*/}
                </div>
                {/*end::Close*/}
              </div>
              {/*end::Modal header*/}
              {/*begin::Modal body*/}
              <div className="modal-body scroll-y mx-5 mx-xl-15 my-7">
                {/*begin::Form*/}
                <form
                  id="kt_modal_export_users_form"
                  className="form fv-plugins-bootstrap5 fv-plugins-framework"
                  action="#"
                >
                  {/*begin::Input group*/}
                  <div className="fv-row mb-10">
                    {/*begin::Label*/}
                    <label className="fs-6 fw-bold form-label mb-2">
                      Select Roles:
                    </label>
                    {/*end::Label*/}
                    {/*begin::Input*/}
                    <select
                      name="role"
                      data-control="select2"
                      data-placeholder="Select a role"
                      data-hide-search="true"
                      className="form-select form-select-solid fw-bolder select2-hidden-accessible"
                      data-select2-id="select2-data-16-abkj"
                      tabIndex={-1}
                      aria-hidden="true"
                    >
                      <option data-select2-id="select2-data-18-u39f" />
                      <option value="Administrator">Administrator</option>
                      <option value="Analyst">Analyst</option>
                      <option value="Developer">Developer</option>
                      <option value="Support">Support</option>
                      <option value="Trial">Trial</option>
                    </select>
                    <span
                      className="select2 select2-container select2-container--bootstrap5"
                      dir="ltr"
                      data-select2-id="select2-data-17-vhl2"
                      style={{ width: "100%" }}
                    >
                      <span className="selection">
                        <span
                          className="select2-selection select2-selection--single form-select form-select-solid fw-bolder"
                          role="combobox"
                          aria-haspopup="true"
                          aria-expanded="false"
                          tabIndex={0}
                          aria-disabled="false"
                          aria-labelledby="select2-role-30-container"
                          aria-controls="select2-role-30-container"
                        >
                          <span
                            className="select2-selection__rendered"
                            id="select2-role-30-container"
                            role="textbox"
                            aria-readonly="true"
                            title="Select a role"
                          >
                            <span className="select2-selection__placeholder">
                              Select a role
                            </span>
                          </span>
                          <span
                            className="select2-selection__arrow"
                            role="presentation"
                          >
                            <b role="presentation" />
                          </span>
                        </span>
                      </span>
                      <span className="dropdown-wrapper" aria-hidden="true" />
                    </span>
                    {/*end::Input*/}
                  </div>
                  {/*end::Input group*/}
                  {/*begin::Input group*/}
                  <div className="fv-row mb-10 fv-plugins-icon-container">
                    {/*begin::Label*/}
                    <label className="required fs-6 fw-bold form-label mb-2">
                      Select Export Format:
                    </label>
                    {/*end::Label*/}
                    {/*begin::Input*/}
                    <select
                      name="format"
                      data-control="select2"
                      data-placeholder="Select a format"
                      data-hide-search="true"
                      className="form-select form-select-solid fw-bolder select2-hidden-accessible"
                      data-select2-id="select2-data-19-xlmq"
                      tabIndex={-1}
                      aria-hidden="true"
                    >
                      <option data-select2-id="select2-data-21-k0dd" />
                      <option value="excel">Excel</option>
                      <option value="pdf">PDF</option>
                      <option value="cvs">CVS</option>
                      <option value="zip">ZIP</option>
                    </select>
                    <span
                      className="select2 select2-container select2-container--bootstrap5"
                      dir="ltr"
                      data-select2-id="select2-data-20-j6g3"
                      style={{ width: "100%" }}
                    >
                      <span className="selection">
                        <span
                          className="select2-selection select2-selection--single form-select form-select-solid fw-bolder"
                          role="combobox"
                          aria-haspopup="true"
                          aria-expanded="false"
                          tabIndex={0}
                          aria-disabled="false"
                          aria-labelledby="select2-format-kk-container"
                          aria-controls="select2-format-kk-container"
                        >
                          <span
                            className="select2-selection__rendered"
                            id="select2-format-kk-container"
                            role="textbox"
                            aria-readonly="true"
                            title="Select a format"
                          >
                            <span className="select2-selection__placeholder">
                              Select a format
                            </span>
                          </span>
                          <span
                            className="select2-selection__arrow"
                            role="presentation"
                          >
                            <b role="presentation" />
                          </span>
                        </span>
                      </span>
                      <span className="dropdown-wrapper" aria-hidden="true" />
                    </span>
                    {/*end::Input*/}
                    <div className="fv-plugins-message-container invalid-feedback" />
                  </div>
                  {/*end::Input group*/}
                  {/*begin::Actions*/}
                  <div className="text-center">
                    <button
                      type="reset"
                      className="btn btn-light me-3"
                      data-kt-users-modal-action="cancel"
                    >
                      Discard
                    </button>
                    <button
                      type="submit"
                      className="btn btn-primary"
                      data-kt-users-modal-action="submit"
                    >
                      <span className="indicator-label">Submit</span>
                      <span className="indicator-progress">
                        Please wait...
                        <span className="spinner-border spinner-border-sm align-middle ms-2" />
                      </span>
                    </button>
                  </div>
                  {/*end::Actions*/}
                  <div />
                </form>
                {/*end::Form*/}
              </div>
              {/*end::Modal body*/}
            </div>
            {/*end::Modal content*/}
          </div>
          {/*end::Modal dialog*/}
        </div>
        {/*end::Modal - New Card*/}
        {/*begin::Modal - Add task*/}
        <div
          className="modal fade"
          id="kt_modal_add_user"
          tabIndex={-1}
          aria-hidden="true"
        >
          {/*begin::Modal dialog*/}
          <div className="modal-dialog modal-dialog-centered mw-650px">
            {/*begin::Modal content*/}
            <div className="modal-content">
              {/*begin::Modal header*/}
              <div className="modal-header" id="kt_modal_add_user_header">
                {/*begin::Modal title*/}
                <h2 className="fw-bolder">Add User</h2>
                {/*end::Modal title*/}
                {/*begin::Close*/}
                <div
                  className="btn btn-icon btn-sm btn-active-icon-primary"
                  data-kt-users-modal-action="close"
                >
                  {/*begin::Svg Icon | path: icons/duotune/arrows/arr061.svg*/}
                  <span className="svg-icon svg-icon-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <rect
                        opacity="0.5"
                        x={6}
                        y="17.3137"
                        width={16}
                        height={2}
                        rx={1}
                        transform="rotate(-45 6 17.3137)"
                        fill="currentColor"
                      />
                      <rect
                        x="7.41422"
                        y={6}
                        width={16}
                        height={2}
                        rx={1}
                        transform="rotate(45 7.41422 6)"
                        fill="currentColor"
                      />
                    </svg>
                  </span>
                  {/*end::Svg Icon*/}
                </div>
                {/*end::Close*/}
              </div>
              {/*end::Modal header*/}
              {/*begin::Modal body*/}
              <div className="modal-body scroll-y mx-5 mx-xl-15 my-7">
                {/*begin::Form*/}
                <form
                  id="kt_modal_add_user_form"
                  className="form fv-plugins-bootstrap5 fv-plugins-framework"
                  action="#"
                >
                  {/*begin::Scroll*/}
                  <div
                    className="d-flex flex-column scroll-y me-n7 pe-7"
                    id="kt_modal_add_user_scroll"
                    data-kt-scroll="true"
                    data-kt-scroll-activate="{default: false, lg: true}"
                    data-kt-scroll-max-height="auto"
                    data-kt-scroll-dependencies="#kt_modal_add_user_header"
                    data-kt-scroll-wrappers="#kt_modal_add_user_scroll"
                    data-kt-scroll-offset="300px"
                    style={{ maxHeight: 0 }}
                  >
                    {/*begin::Input group*/}
                    <div className="fv-row mb-7">
                      {/*begin::Label*/}
                      <label className="d-block fw-bold fs-6 mb-5">Avatar</label>
                      {/*end::Label*/}
                      {/*begin::Image input*/}
                      <div
                        className="image-input image-input-outline"
                        data-kt-image-input="true"
                        style={{
                          backgroundImage:
                            'url("../../../assets/media/svg/avatars/blank.svg")'
                        }}
                      >
                        {/*begin::Preview existing avatar*/}
                        <div
                          className="image-input-wrapper w-125px h-125px"
                          style={{
                            backgroundImage:
                              "url(../../../assets/media/avatars/300-6.jpg)"
                          }}
                        />
                        {/*end::Preview existing avatar*/}
                        {/*begin::Label*/}
                        <label
                          className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                          data-kt-image-input-action="change"
                          data-bs-toggle="tooltip"
                          title=""
                          data-bs-original-title="Change avatar"
                        >
                          <i className="bi bi-pencil-fill fs-7" />
                          {/*begin::Inputs*/}
                          <input
                            type="file"
                            name="avatar"
                            accept=".png, .jpg, .jpeg"
                          />
                          <input type="hidden" name="avatar_remove" />
                          {/*end::Inputs*/}
                        </label>
                        {/*end::Label*/}
                        {/*begin::Cancel*/}
                        <span
                          className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                          data-kt-image-input-action="cancel"
                          data-bs-toggle="tooltip"
                          title=""
                          data-bs-original-title="Cancel avatar"
                        >
                          <i className="bi bi-x fs-2" />
                        </span>
                        {/*end::Cancel*/}
                        {/*begin::Remove*/}
                        <span
                          className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                          data-kt-image-input-action="remove"
                          data-bs-toggle="tooltip"
                          title=""
                          data-bs-original-title="Remove avatar"
                        >
                          <i className="bi bi-x fs-2" />
                        </span>
                        {/*end::Remove*/}
                      </div>
                      {/*end::Image input*/}
                      {/*begin::Hint*/}
                      <div className="form-text">
                        Allowed file types: png, jpg, jpeg.
                      </div>
                      {/*end::Hint*/}
                    </div>
                    {/*end::Input group*/}
                    {/*begin::Input group*/}
                    <div className="fv-row mb-7 fv-plugins-icon-container">
                      {/*begin::Label*/}
                      <label className="required fw-bold fs-6 mb-2">
                        Full Name
                      </label>
                      {/*end::Label*/}
                      {/*begin::Input*/}
                      <input
                        type="text"
                        name="user_name"
                        className="form-control form-control-solid mb-3 mb-lg-0"
                        placeholder="Full name"
                        defaultValue="Emma Smith"
                      />
                      {/*end::Input*/}
                      <div className="fv-plugins-message-container invalid-feedback" />
                    </div>
                    {/*end::Input group*/}
                    {/*begin::Input group*/}
                    <div className="fv-row mb-7 fv-plugins-icon-container">
                      {/*begin::Label*/}
                      <label className="required fw-bold fs-6 mb-2">Email</label>
                      {/*end::Label*/}
                      {/*begin::Input*/}
                      <input
                        type="email"
                        name="user_email"
                        className="form-control form-control-solid mb-3 mb-lg-0"
                        placeholder="example@domain.com"
                        defaultValue="smith@kpmg.com"
                      />
                      {/*end::Input*/}
                      <div className="fv-plugins-message-container invalid-feedback" />
                    </div>
                    {/*end::Input group*/}
                    {/*begin::Input group*/}
                    <div className="mb-7">
                      {/*begin::Label*/}
                      <label className="required fw-bold fs-6 mb-5">Role</label>
                      {/*end::Label*/}
                      {/*begin::Roles*/}
                      {/*begin::Input row*/}
                      <div className="d-flex fv-row">
                        {/*begin::Radio*/}
                        <div className="form-check form-check-custom form-check-solid">
                          {/*begin::Input*/}
                          <input
                            className="form-check-input me-3"
                            name="user_role"
                            type="radio"
                            defaultValue={0}
                            id="kt_modal_update_role_option_0"
                            defaultChecked="checked"
                          />
                          {/*end::Input*/}
                          {/*begin::Label*/}
                          <label
                            className="form-check-label"
                            htmlFor="kt_modal_update_role_option_0"
                          >
                            <div className="fw-bolder text-gray-800">
                              Administrator
                            </div>
                            <div className="text-gray-600">
                              Best for business owners and company administrators
                            </div>
                          </label>
                          {/*end::Label*/}
                        </div>
                        {/*end::Radio*/}
                      </div>
                      {/*end::Input row*/}
                      <div className="separator separator-dashed my-5" />
                      {/*begin::Input row*/}
                      <div className="d-flex fv-row">
                        {/*begin::Radio*/}
                        <div className="form-check form-check-custom form-check-solid">
                          {/*begin::Input*/}
                          <input
                            className="form-check-input me-3"
                            name="user_role"
                            type="radio"
                            defaultValue={1}
                            id="kt_modal_update_role_option_1"
                          />
                          {/*end::Input*/}
                          {/*begin::Label*/}
                          <label
                            className="form-check-label"
                            htmlFor="kt_modal_update_role_option_1"
                          >
                            <div className="fw-bolder text-gray-800">
                              Developer
                            </div>
                            <div className="text-gray-600">
                              Best for developers or people primarily using the
                              API
                            </div>
                          </label>
                          {/*end::Label*/}
                        </div>
                        {/*end::Radio*/}
                      </div>
                      {/*end::Input row*/}
                      <div className="separator separator-dashed my-5" />
                      {/*begin::Input row*/}
                      <div className="d-flex fv-row">
                        {/*begin::Radio*/}
                        <div className="form-check form-check-custom form-check-solid">
                          {/*begin::Input*/}
                          <input
                            className="form-check-input me-3"
                            name="user_role"
                            type="radio"
                            defaultValue={2}
                            id="kt_modal_update_role_option_2"
                          />
                          {/*end::Input*/}
                          {/*begin::Label*/}
                          <label
                            className="form-check-label"
                            htmlFor="kt_modal_update_role_option_2"
                          >
                            <div className="fw-bolder text-gray-800">Analyst</div>
                            <div className="text-gray-600">
                              Best for people who need full access to analytics
                              data, but don't need to update business settings
                            </div>
                          </label>
                          {/*end::Label*/}
                        </div>
                        {/*end::Radio*/}
                      </div>
                      {/*end::Input row*/}
                      <div className="separator separator-dashed my-5" />
                      {/*begin::Input row*/}
                      <div className="d-flex fv-row">
                        {/*begin::Radio*/}
                        <div className="form-check form-check-custom form-check-solid">
                          {/*begin::Input*/}
                          <input
                            className="form-check-input me-3"
                            name="user_role"
                            type="radio"
                            defaultValue={3}
                            id="kt_modal_update_role_option_3"
                          />
                          {/*end::Input*/}
                          {/*begin::Label*/}
                          <label
                            className="form-check-label"
                            htmlFor="kt_modal_update_role_option_3"
                          >
                            <div className="fw-bolder text-gray-800">Support</div>
                            <div className="text-gray-600">
                              Best for employees who regularly refund payments and
                              respond to disputes
                            </div>
                          </label>
                          {/*end::Label*/}
                        </div>
                        {/*end::Radio*/}
                      </div>
                      {/*end::Input row*/}
                      <div className="separator separator-dashed my-5" />
                      {/*begin::Input row*/}
                      <div className="d-flex fv-row">
                        {/*begin::Radio*/}
                        <div className="form-check form-check-custom form-check-solid">
                          {/*begin::Input*/}
                          <input
                            className="form-check-input me-3"
                            name="user_role"
                            type="radio"
                            defaultValue={4}
                            id="kt_modal_update_role_option_4"
                          />
                          {/*end::Input*/}
                          {/*begin::Label*/}
                          <label
                            className="form-check-label"
                            htmlFor="kt_modal_update_role_option_4"
                          >
                            <div className="fw-bolder text-gray-800">Trial</div>
                            <div className="text-gray-600">
                              Best for people who need to preview content data,
                              but don't need to make any updates
                            </div>
                          </label>
                          {/*end::Label*/}
                        </div>
                        {/*end::Radio*/}
                      </div>
                      {/*end::Input row*/}
                      {/*end::Roles*/}
                    </div>
                    {/*end::Input group*/}
                  </div>
                  {/*end::Scroll*/}
                  {/*begin::Actions*/}
                  <div className="text-center pt-15">
                    <button
                      type="reset"
                      className="btn btn-light me-3"
                      data-kt-users-modal-action="cancel"
                    >
                      Discard
                    </button>
                    <button
                      type="submit"
                      className="btn btn-primary"
                      data-kt-users-modal-action="submit"
                    >
                      <span className="indicator-label">Submit</span>
                      <span className="indicator-progress">
                        Please wait...
                        <span className="spinner-border spinner-border-sm align-middle ms-2" />
                      </span>
                    </button>
                  </div>
                  {/*end::Actions*/}
                  <div />
                </form>
                {/*end::Form*/}
              </div>
              {/*end::Modal body*/}
            </div>
            {/*end::Modal content*/}
          </div>
          {/*end::Modal dialog*/}
        </div>
        {/*end::Modal - Add task*/}
      </div>
      {/*end::Card toolbar*/}
    </div>
    {children}
    </div>
  )
}
