import React, { useState } from 'react'
import { Editor } from 'react-draft-wysiwyg';
import htmlToDraft from 'html-to-draftjs';
import './styles.css'
import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

export default function TextEditer({ editorState, setEditorState }) {

    const onEditorStateChange = (edit) =>{ setEditorState(edit) }

    return (
        <div>
            <Editor
                editorState={editorState}
                wrapperClassName="demo-wrapper"
                editorClassName="demo-editor"
                onEditorStateChange={onEditorStateChange}
            />
        </div>
    )
}