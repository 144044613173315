import React, { useEffect, useState } from 'react'
import Container from '../../../../components/AdminLanding/Container/Container'
import ContactTable from '../../../../components/AdminLanding/Contact/ContactTable'
import { GetContact } from './services'

export default function Contact() {

    const [data, setData] = useState(null)

    useEffect(() => {
        GetContact().then(source => setData(source))
    }, [])
    
    return (
        <Container>
            <ContactTable data={data} />
        </Container>
    )
}
