import React, { useState } from 'react'
import { Delete, Forms } from './Modal'
import Table from './Table'
import Row from './Row'

export default function ClientTable({ data }) {
    const [showForm, setShowForm] = useState(false)
    const [showDelete, setShowDelete] = useState(false)
    const [dataEdit, setDataEdit] = useState(null)

    return (
        <React.Fragment>
            {showForm ? <Forms showForm={showForm} setShowForm={setShowForm} data={dataEdit} /> : <></>}
            {showDelete ? <Delete showDelete={showDelete} setShowDelete={setShowDelete} data={dataEdit} />:<></>}
            
            <Table setShowForm={setShowForm} setDataEdit={setDataEdit}>
                {
                    data &&
                    data.map((dt, index)=> <Row key={index} item={dt} setShowForm={setShowForm} setDataEdit={setDataEdit} setShowDelete={setShowDelete} /> )
                }
            </Table>
        </React.Fragment>
    )
}
