import React from 'react'
import styles from './designed.module.css'

export default function Designed({ style, value, id }) {

  return (
    <div id={id ? id : 'containerDesigned'}>
      <div className={styles.box}>
        <div className={styles.container}>
          <div className={`${styles['left-desc']} left-desc_designed`}>
            <img className={styles.logo} src="/logo-90.png" alt="logo" />
            <div className={styles.info}>
              <p>Digital signed by DKBSIGN</p>
              <p>email= </p>
              <p>raison= </p>
              <p>lieu= </p>
              <p>signataire= </p>
              <p>date= </p>
            </div>
          </div>
          <div className={style['signature-area']}>
            <p className={styles.p} dangerouslySetInnerHTML={{ __html: value }} style={style} />
          </div>
        </div>
      </div>
    </div>
  )
}
