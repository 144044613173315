import React from 'react';
import { IoMdCloseCircleOutline } from 'react-icons/io';
import { useTranslateContext } from '../../../hooks';
import '../style.css';

export default function ApplyModal({ showModal, setShowModal, onAccept, onDecline, alertMsg }) {
    const { translate } = useTranslateContext();

    return (
        <div
            id="myModal"
            style={{ display: showModal ? 'block' : 'none' }}
            className="modal"
        >
            <div className="modal-content">
                <IoMdCloseCircleOutline
                    className="top-close-icon"
                    onClick={() => setShowModal(false)}
                />

                <p className="txt-alert">{alertMsg}</p>

                <div className="btn-area">
                    <button
                        className={'okbtn'}
                        onClick={() => {
                            setShowModal(false);
                            onAccept();
                        }}
                    >
                        {translate.admin.oui}
                    </button>

                    <button
                        className={'okbtn'}
                        onClick={() => {
                            setShowModal(false)
                            onDecline()
                        }}
                    >
                        {translate.admin.non}
                    </button>
                </div>
            </div>
        </div>
    )
}
