import React,{useEffect} from 'react'
import Row from './Row'
import { useDispatch, useSelector } from 'react-redux';
import { getDocument, userSelector } from '../../services/reduxSlices/UserSlice';

import { useTranslateContext } from '../../hooks';

/**
 * Composant présent sur le Dashbord 
 * Tableau contenant les 5 derniers des documents
 * 
 * @returns TableCard
 */
export default function TableCard() {
    const { translate } = useTranslateContext();
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getDocument({
            page: undefined,
            start: undefined,
            end: undefined,
            search: undefined,
            limit: 20
        }))
    }, [])

    const { signDocuments } = useSelector(userSelector)

    const getFiveFirstItems = (array) => {
        return array.slice(0, 5)
    }

    return (
        <div className="card mb-5 mb-xl-8">
            <div className="card-header border-0 pt-5">
                <h3 className="card-title align-items-start flex-column">
                    <span className="card-label fw-bolder fs-3 mb-1">{translate.admin.vosDocuments}</span>
                    <span className="text-muted mt-1 fw-bold fs-7">{translate.admin.voirTout} </span>
                </h3>
            </div>
            <div className="card-body py-3">
                <div className="table-responsive">
                    <table className="table align-middle gs-0 gy-4">
                        <thead>
                            <tr className="fw-bolder text-muted bg-light">
                                <th className="ps-4 min-w-325px rounded-start">Document</th>
                                <th className="min-w-125px">Action</th>
                                <th className="min-w-150px">Status</th>
                                <th className="min-w-200px text-end rounded-end"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                signDocuments.data && getFiveFirstItems(signDocuments.data).map((rw) => {
                                    return <Row data={rw} />
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}
