import React from 'react'
import { MdOutlineModeEditOutline } from "react-icons/md";
import './styles.css'

const BASE_URL_IMG = `https://dkbsign.com/dkbsign_web_api/public/storage/websiteimages`
export default function Row({ item, setShowForm, setDataEdit, setShowDelete }) {
    var idx = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]

    return (
        <tr className="even">
            <td>
                <div className="form-check form-check-sm form-check-custom form-check-solid">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        defaultValue={1}
                    />
                </div>
            </td>

            <td>{item.pagecible}</td>

            { idx.map(it =>  <td>{item[`img${it}`] ? <img className='prev-img' src={`${BASE_URL_IMG}/${item.pagecible}/${item[`img${it}`]}`} alt="prev-img" /> : 'Non modifié'}</td> ) }

            <td className="text-end">
                <MdOutlineModeEditOutline
                    data-bs-toggle="modal"
                    data-bs-target="#kt_modal_new_target"
                    onClick={() => {
                        setShowForm(true)
                        setDataEdit(item)
                    }}
                    size={20}
                />
            </td>
        </tr>
    )
}
