import React from 'react'
import Table from './Table'
import Row from './Row'

export default function ContactTable({ data }) {
    return (
        <React.Fragment>
            <Table>
                {
                    data &&
                    data.map((dt, index)=> <Row key={index} item={dt} /> )
                }
            </Table>
        </React.Fragment>
    )
}
