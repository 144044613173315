import React, { useEffect, useState } from 'react'
import { getEntreprise, userSelector, saveUsers, patchUsers, getCertificat } from '../../../../services/reduxSlices/UserSlice'
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from "yup";
import './style.css'

export default function Forms({ showForm, setShowForm, data }) {

  const [initialValues] = useState({
    nom: data ? data.nom : '',
    prenom: data ? data.prenoms : '',
    email: data ? data.email : '',
    fonction: data ? data.fonction : '',
    contact: data ? data.telephone : 0,
    entreprise: data ? data.entreprise_id : 0,
    certificat_id: data ? data.certificat_id : 0,
    roles: []
  })

  const dispatch = useDispatch()
  const { ls_entrepises, ls_certificat } = useSelector(userSelector)

  useEffect(() => {
    dispatch(getEntreprise())
    dispatch(getCertificat())
  }, [])

  const ContactsSchema = Yup.object().shape({
    nom: Yup.string()
      .min(2, 'Trop court!')
      .max(30, 'Trop long!')
      .required('Champs requis'),

    prenom: Yup.string()
      .min(2, 'Trop court!')
      .max(30, 'Trop long!')
      .required('Champs requis'),

    email: Yup.string()
      .email('email invalide')
      .required('Champs requis!'),

    fonction: Yup.string()
      .required('Champs requis!'),

    contact: Yup.string()
      .typeError('Format invalide')
      .min(10, 'Minimum 10 caractères')
      .required('Champs requis')
  });

  return (
    <div
      className={showForm ? "modal fade show" : "modal fade"}
      id="kt_modal_new_target"
      tabIndex={-1}
      style={{ display: showForm ? "block" : "none", marginTop: '0px' }}
    >
      <div className="modal-dialog modal-dialog-centered mw-650px">
        <div className="modal-content rounded" style={{ width: '100%' }}>
          <div className="modal-header pb-0 border-0 justify-content-end">
            <div
              className="btn btn-sm btn-icon btn-active-color-primary"
              data-bs-dismiss="modal"
              onClick={() => setShowForm(false)}
            >
              <span className="svg-icon svg-icon-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <rect
                    opacity="0.5"
                    x={6}
                    y="17.3137"
                    width={16}
                    height={2}
                    rx={1}
                    transform="rotate(-45 6 17.3137)"
                    fill="currentColor"
                  />
                  <rect
                    x="7.41422"
                    y={6}
                    width={16}
                    height={2}
                    rx={1}
                    transform="rotate(45 7.41422 6)"
                    fill="currentColor"
                  />
                </svg>
              </span>
            </div>
          </div>

          <Formik
            initialValues={initialValues}

            onReset={true}
            validationSchema={ContactsSchema}

            onSubmit={async (values, { setSubmitting }) => {
              if(!parseInt(values.certificat_id) && !parseInt(values.entreprise)) {
                alert('Le certificat ou l\'entrprise doivent etre renseigné')
                return
              }

              const dataSend = {
                id: data ? data.id : 0,
                nom: values.nom,
                prenoms: values.prenom,
                email: values.email,
                fonction: values.fonction,
                telephone: values.contact,
                certificat_id: parseInt(values.certificat_id) !==0 ? parseInt(values.certificat_id) : null,
                entreprise_id: parseInt(values.entreprise) !==0 ? parseInt(values.entreprise) : null,
                signer: values.roles.includes('signer') ? 1 : 0,
                faire_signer: values.roles.includes('faire_signer') ? 1 : 0,
                signer_faire_signer: values.roles.includes('signer_faire_signer') ? 1 : 0
              }

              data ? dispatch(patchUsers(dataSend)) : dispatch(saveUsers(dataSend))

              setShowForm(false)
            }}
          >

            {({ isSubmitting, handleChange, handleBlur, values, handleSubmit, setFieldValue }) => (
              <Form>
                <div className="modal-body scroll-y px-10 px-lg-15 pt-0 pb-15">
                  <form
                    id="kt_modal_new_target_form"
                    className="form fv-plugins-bootstrap5 fv-plugins-framework"
                    action="#"
                  >

                    <div className="mb-13 text-center">
                      <h1 className="mb-3">{data ? 'Modifier un utilisateur' : 'Ajouter un utilisateur'}</h1>
                    </div>

                    <div className="row mb-5">
                      <div className="col-md-6 fv-row fv-plugins-icon-container">
                        <label className="required fs-5 fw-bold mb-2">Nom</label>
                        <input
                          type="text"
                          className="form-control form-control-solid"
                          placeholder="Entrez le nom"
                          name="nom"
                          value={values.nom}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <div className="fv-plugins-message-container invalid-feedback" />
                        <ErrorMessage className="error-msg" name="nom" component={'div'} />
                      </div>
                      <div className="col-md-6 fv-row fv-plugins-icon-container">
                        <label className="required fs-5 fw-bold mb-2">Prénom</label>
                        <input
                          type="text"
                          className="form-control form-control-solid"
                          placeholder="Entrez le prenom"
                          name="prenom"
                          value={values.prenom}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <div className="fv-plugins-message-container invalid-feedback" />
                        <ErrorMessage className="error-msg" name="prenom" component={'div'} />
                      </div>
                    </div>

                    <div className="d-flex flex-column mb-8 fv-row fv-plugins-icon-container fv-plugins-bootstrap5-row-invalid">
                      <label className="d-flex align-items-center fs-6 fw-bold mb-2">
                        <span className="required">Email</span>
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-solid"
                        placeholder="Entrez l'email"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <ErrorMessage className="error-msg" name="email" component={'div'} />
                    </div>

                    <div className="d-flex flex-column mb-8 fv-row fv-plugins-icon-container fv-plugins-bootstrap5-row-invalid">
                      <label className="d-flex align-items-center fs-6 fw-bold mb-2">
                        <span className="required">Fonction</span>
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-solid"
                        placeholder="Entrez votre fonction"
                        name="fonction"
                        value={values.fonction}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <ErrorMessage className="error-msg" name="fonction" component={'div'} />
                    </div>

                    <div className="d-flex flex-column mb-8 fv-row fv-plugins-icon-container fv-plugins-bootstrap5-row-invalid">
                      <label className="d-flex align-items-center fs-6 fw-bold mb-2">
                        <span className="required">Contacts</span>
                      </label>
                      <input
                        type='tel'
                        className="form-control form-control-solid"
                        placeholder="Entrez votre contacts"
                        name="contact"
                        value={values.contact}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <ErrorMessage className="error-msg" name="contact" component={'div'} />
                    </div>

                    <select value={values.entreprise} onChange={handleChange} onBlur={handleBlur} name="entreprise" class="form-select" style={{ marginBottom: '3rem' }}>
                      <option value={0} selected>Selectionnez votre entreprise</option>
                      {
                        ls_entrepises.data &&
                        ls_entrepises.data.map(it => <option value={it.id}>{it.raison_sociale}</option>)
                      }
                    </select>

                    <select value={values.certificat_id} onChange={handleChange} onBlur={handleBlur} name="certificat_id" class="form-select" style={{ marginBottom: '3rem' }}>
                      <option value={0} selected>Selectionnez votre certificat</option>
                      {
                        ls_certificat.data &&
                        ls_certificat.data.map(it => <option value={it.id}>{it.intitule}</option>)
                      }
                    </select>


                    <div className="mt-5">
                      <div className="d-flex align-items-center mb-5">
                        <div className="form-check form-check-custom form-check-solid">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="roles"
                            name="roles"
                            defaultChecked={data && data.signer}
                            value={"signer"}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <label className="form-check-label" htmlFor="signe">
                            Signer
                          </label>
                        </div>
                        <div className="form-check form-check-custom form-check-solid mx-5">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="roles"
                            name="roles"
                            defaultChecked={data && data.faire_signer}
                            value={"faire_signer"}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <label className="form-check-label" htmlFor="fairesigne">
                            Faire signé
                          </label>
                        </div>
                        <div className="form-check form-check-custom form-check-solid mx-5">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="roles"
                            name="roles"
                            defaultChecked={data && data.signer_faire_signer}
                            value={"signer_faire_signer"}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <label className="form-check-label" htmlFor="signefairesigne">
                            Signé & faire signé
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="text-center">
                      <button
                        type="reset"
                        id="kt_modal_new_target_cancel"
                        onClick={() => setShowForm(false)}
                        className="btn btn-light me-3"
                      >
                        Annuler
                      </button>
                      <button
                        type="submit"
                        onClick={handleSubmit}
                        data-bs-dismiss="modal"
                        className="btn btn-primary"
                      >
                        <span className="indicator-label">Soumettre</span>
                        <span className="indicator-progress">
                          Please wait...
                          <span className="spinner-border spinner-border-sm align-middle ms-2" />
                        </span>
                      </button>
                    </div>
                    <div />
                  </form>
                </div>
              </Form>
            )}
          </Formik>

        </div>
      </div>
    </div>
  )
}
