import React, { useState, useEffect } from 'react'
import LandingWrapper from './LandingWrapper'
import { BsCheckCircleFill } from 'react-icons/bs'
import { TryModal } from './Modal'
import { SiFastapi } from 'react-icons/si'
import { GetReferencement, GetImagesByPage } from './Admin/Main/services'
import { Helmet } from "react-helmet";
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';
import SpinnerPage from './SpinnerPage'
import './styles.css'

var empty_img = {
    "img1": null,
    "alt1": null,
    "img2": null,
    "alt2": null,
    "img3": null,
    "alt3": null,
    "img4": null,
    "alt4": null,
    "img5": null,
    "alt5": null,
    "img6": null,
    "alt6": null,
    "img7": null,
    "alt7": null,
    "img8": null,
    "alt8": null,
    "img9": null,
    "alt9": null,
    "img10": null,
    "alt10": null,
    "img11": null,
    "alt11": null,
    "img12": null,
    "alt12": null,
    "img13": null,
    "alt13": null,
    "img14": null,
    "alt14": null,
    "img15": null,
    "alt15": null,
    "img16": null,
    "alt16": null,
    "img17": null,
    "alt17": null,
    "img18": null,
    "alt18": null,
    "img19": null,
    "alt19": null,
    "img20": null,
    "alt20": null
}

const PAGE_NAME = 'API'
const BASE_URL_IMG = `https://dkbsign.com/dkbsign_web_api/public/storage/websiteimages/${PAGE_NAME}`

export default function LandingApi() {
    const [referencement, setReferencement] = useState(null)
    const [showModal, setShowModal] = useState(false)
    const [IMAGES, SETIMAGES] = useState(null)


    useEffect(() => {
        GetReferencement().then(source => setReferencement(source))
        GetImagesByPage({ cible: PAGE_NAME }).then(source => SETIMAGES(source))
    }, [])

    return (
        IMAGES ?
            <LandingWrapper>
                {showModal && <TryModal showModal={showModal} setShowModal={setShowModal} />}
                <Helmet>
                    <title>
                        {
                            referencement ?
                                referencement.find((dt) => dt.pageCible === PAGE_NAME) ? referencement.find((dt) => dt.pageCible === PAGE_NAME).siteTitle : "DKBSign"
                                : "DKBSign"
                        }
                    </title>
                    <meta
                        name="description"
                        content={
                            referencement ?
                                referencement.find((dt) => dt.pageCible === PAGE_NAME) ? referencement.find((dt) => dt.pageCible === PAGE_NAME).meta_description : ""
                                : ""
                        }
                    />

                    <meta
                        name="keywords"
                        lang="fr"
                        content={
                            referencement ?
                                referencement.find((dt) => dt.pageCible === PAGE_NAME) ? referencement.find((dt) => dt.pageCible === PAGE_NAME).meta_keywords : ""
                                : ""
                        }
                    />

                    <meta name="robots" content={
                        referencement ?
                            referencement.find((dt) => dt.pageCible === PAGE_NAME) ? referencement.find((dt) => dt.pageCible === PAGE_NAME).meta_robots : ""
                            : ""
                    }
                    />
                    <meta name="category" content={
                        referencement ?
                            referencement.find((dt) => dt.pageCible === PAGE_NAME) ? referencement.find((dt) => dt.pageCible === PAGE_NAME).meta_category : ""
                            : ""
                    }
                    />

                    <meta name="identifier-url" content={
                        referencement ?
                            referencement.find((dt) => dt.pageCible === PAGE_NAME) ? referencement.find((dt) => dt.pageCible === PAGE_NAME).meta_identifier_url : ""
                            : ""
                    }
                    />

                    <meta name="reply-to" content={
                        referencement ?
                            referencement.find((dt) => dt.pageCible === PAGE_NAME) ? referencement.find((dt) => dt.pageCible === PAGE_NAME).meta_reply_to : ""
                            : ""
                    }
                    />
                </Helmet>
                <HeaderApi setShowModal={setShowModal} IMAGES={IMAGES[0] ? IMAGES[0] : empty_img} />
                <DescriptionApi />
                <StrongPoint IMAGES={IMAGES[0] ? IMAGES[0] : empty_img} />
                <Fast />
                <Process />
                <Cachet />
                <HowUseElectronik IMAGES={IMAGES[0] ? IMAGES[0] : empty_img} />
                <WeClient IMAGES={IMAGES[0] ? IMAGES[0] : empty_img} />
            </LandingWrapper>
            :
            <div className='global-loader'>
                <SpinnerPage />
            </div>
    )
}

const HeaderApi = ({ setShowModal, IMAGES }) => {

    return (
        <div className="landing_api-content">

            <Fade duration={4000} left>
                <img 
                    className='landing_api-image' 
                    src={IMAGES.img1 ? `${BASE_URL_IMG}/${IMAGES.img1}` : "./assets/media/home_icon/API.png"}
                    alt={IMAGES.alt1 ? `${IMAGES.alt1}` : ""}
                />
            </Fade>

            <div className="landing_api_title">
                L'API DE DKBSIGN A VOTRE SERVICE
            </div>


            <Fade duration={4000} right>
                <div onClick={() => setShowModal(true)} className="request-demo-btn">DEMANDER UNE DEMO</div>
            </Fade>
        </div>
    )
}

// <Fade duration={4000} left></Fade>
const DescriptionApi = () => {
    return (
        <div className="landing_api-description">
            <Fade duration={4000} left>
                <p>
                    Le réseau DKBSign permet aux entreprises de tout type et de toute taille de conclure des affaires plus rapidement à tout moment, en tout lieu et sur tous les appareils grâce à la solution de signature électronique la plus utilisée en Côte d'Ivoire.
                </p>
            </Fade>
        </div>
    )
}

const StrongPoint = ({ IMAGES }) => {
    return (
        <div className="landing_api-strongpoint">
            <Fade duration={4000} left>
                <div className="landing_api-strongpoint-title">Nos points forts API</div>
            </Fade>
            <hr className='line-strong-point' />
            <div className="display-strongpoint">
                <Zoom top duration={3000}>
                    <img 
                        className='strongpoint-img' 
                        src={IMAGES.img2 ? `${BASE_URL_IMG}/${IMAGES.img2}` : "./assets/media/home_icon/Innovation.png" }
                        alt={IMAGES.alt2 ? `${IMAGES.alt2}` : ""}
                    />

                    <img 
                        className='strongpoint-img' 
                        src={IMAGES.img3 ? `${BASE_URL_IMG}/${IMAGES.img3}` : "./assets/media/home_icon/rapide.png" }
                        alt={IMAGES.alt3 ? `${IMAGES.alt3}` : ""}
                    />

                    <img 
                        className='strongpoint-img' 
                        src={IMAGES.img4 ? `${BASE_URL_IMG}/${IMAGES.img4}` : "./assets/media/home_icon/Eco-responsable.png" }
                        alt={IMAGES.alt4 ? `${IMAGES.alt4}` : ""}
                    />

                    <img 
                        className='strongpoint-img' 
                        src={IMAGES.img5 ? `${BASE_URL_IMG}/${IMAGES.img5}` : "./assets/media/home_icon/securise.png" }
                        alt={IMAGES.alt5 ? `${IMAGES.alt5}` : ""} 
                    />
                </Zoom>
            </div>
        </div>
    )
}

const HowUseElectronik = ({ IMAGES }) => {
    const [useData] = useState([
        {
            id: 2,
            logo: <SiFastapi size={30} color={'#164A80'} className='landing_api-how-use-item-logo' />,
            title: 'Via l’API DKBSign',
            description: 'Directement intégrée à votre site web ou logiciel métier, signez automatiquement tous vos documents.'
        }
    ])
    return (
        <div className="landing_api-how-use">
            <Fade duration={4000} left>
                <div className="landing_api-how-use-left">
                    <div className="landing_api-how-use-title">Comment utiliser la signature electronique ?</div>

                    <div className="landing_api-how-use-content">
                        {useData.map((item) => (
                            <HowUseElectronikItem key={item.id} {...item} />
                        ))}
                    </div>
                </div>
            </Fade>

            <Fade duration={4000} right>
                <div className="landing_api-how-use-right">
                    <img 
                        className='use-right-img' 
                        src={IMAGES.img6 ? `${BASE_URL_IMG}/${IMAGES.img6}` : "./assets/media/home_icon/API-Dkbsign.png" }
                        alt={IMAGES.alt6 ? `${IMAGES.alt6}` : ""}
                    />
                </div>
            </Fade>
        </div>
    )
}

const HowUseElectronikItem = ({ logo, title, description }) => {
    return (
        <div className="landing_api-how-use-item">
            {logo}
            <div className="landing_api-how-use-item-content">
                <div className="landing_api-how-use-item-title">{title}</div>
                <div className="landing_api-how-use-item-description">{description}</div>
            </div>
        </div>
    )
}

const Integration = () => {
    return (
        <React.Fragment>
            <div className="landing-tarif__faq-content">
                <div className="landing-tarif__faq-header">
                    <p className='landing_api-garantir'>GARANTISSEZ LA REGLEMENTATION DE VOS TRANSACTIONS NUMERIQUE</p>
                </div>
            </div>
        </React.Fragment>
    )
}

const WePlus = () => {
    return (
        <div className="landing_api-weplus">
            <div className="landing_api-weplus-title">
                <div className='landing_api-weplus-txt'>Nos</div>
                <div className='landing_api-weplus-plus'>+</div>
                <div className='landing_api-weplus-txt'>que nous vous apporterons</div>
            </div>
        </div>
    )
}

const WeClient = ({ IMAGES }) => {
    const [images] = useState([
        {
            id: 1,
            src: IMAGES.img7 ? `${BASE_URL_IMG}/${IMAGES.img7}` : './assets/media/home_icon/ent_1.png',
            alt: IMAGES.alt7 ? `${IMAGES.alt7}` : ""
        },
        {
            id: 2,
            src: IMAGES.img8 ? `${BASE_URL_IMG}/${IMAGES.img8}` : './assets/media/home_icon/ent_2.png',
            alt: IMAGES.alt8 ? `${IMAGES.alt8}` : ""
        },
        {
            id: 3,
            src: IMAGES.img9 ? `${BASE_URL_IMG}/${IMAGES.img9}` : './assets/media/home_icon/ent_3.png',
            alt: IMAGES.alt9 ? `${IMAGES.alt9}` : ""
        },
        {
            id: 4,
            src: IMAGES.img10 ? `${BASE_URL_IMG}/${IMAGES.img10}` : './assets/media/home_icon/ent_4.png',
            alt: IMAGES.alt10 ? `${IMAGES.alt10}` : ""
        },
        {
            id: 5,
            src: IMAGES.img11 ? `${BASE_URL_IMG}/${IMAGES.img11}` : './assets/media/home_icon/ent_5.png',
            alt: IMAGES.alt11 ? `${IMAGES.alt11}` : ""
        },
        {
            id: 6,
            src: IMAGES.img12 ? `${BASE_URL_IMG}/${IMAGES.img12}` : './assets/media/home_icon/ent_6.png',
            alt: IMAGES.alt12 ? `${IMAGES.alt12}` : ""
        },
        {
            id: 7,
            src: IMAGES.img13 ? `${BASE_URL_IMG}/${IMAGES.img13}` : './assets/media/home_icon/ent_7.png',
            alt: IMAGES.alt13 ? `${IMAGES.alt13}` : ""
        },
        {
            id: 8,
            src: IMAGES.img14 ? `${BASE_URL_IMG}/${IMAGES.img14}` : './assets/media/home_icon/ent_8.jpg',
            alt: IMAGES.alt14 ? `${IMAGES.alt14}` : ""
        },
        {
            id: 9,
            src: IMAGES.img15 ? `${BASE_URL_IMG}/${IMAGES.img15}` : './assets/media/home_icon/ent_9.png',
            alt: IMAGES.alt15 ? `${IMAGES.alt15}` : ""
        },
        {
            id: 10,
            src: IMAGES.img16 ? `${BASE_URL_IMG}/${IMAGES.img16}` : './assets/media/home_icon/ent_10.png',
            alt: IMAGES.alt16 ? `${IMAGES.alt16}` : ""
        },
        {
            id: 11,
            src: IMAGES.img17 ? `${BASE_URL_IMG}/${IMAGES.img17}` : './assets/media/home_icon/ent_11.png',
            alt: IMAGES.alt17 ? `${IMAGES.alt17}` : ""
        },
        {
            id: 12,
            src: IMAGES.img18 ? `${BASE_URL_IMG}/${IMAGES.img18}` : './assets/media/home_icon/ent_12.png',
            alt: IMAGES.alt18 ? `${IMAGES.alt18}` : ""
        }
    ])

    return (
        <div className="landing_api-weclient">
            <Fade duration={4000} left>
                <div className="landing_api-weclient-title">Découvrez nos clients sous API</div>
            </Fade>


            <div className="landing_api-weclient-content">
                <marquee direction="left" scrollamount="10">
                    {images.map((image, index) => { return <ItemPartenaire image={image} key={index} /> })}
                </marquee>
            </div>
            <a href='/home-apropos' className="landing_api-weclient-contactus">CONTACTEZ NOUS</a>
        </div>
    )
}

const ItemPartenaire = ({ image }) => {
    return (
        <img className="landing-partenaire_items" src={image.src} alt={image.alt} />
    )
}

const CardWrapper = ({ children, color, hasBg = '' }) => {
    return (
        <div className={`landing_api-card-wrapper-${color}${hasBg}`}>
            {children}
        </div>
    )
}

const CardONE = () => {
    return (
        <CardWrapper color={'white'}>
            <div className="title-card-one">Notre solution a été pensée, dès sa conception, pour un usage en mode SaaS. Ceci signifie que nous gérons l’ensemble de nos clients sur la même plateforme avec le même code applicatif.</div>
            <p className='card-one-paragraph'>
                Afin de répondre à l’ensemble de nos clients, l’API de signature électronique DKBSign est très modulaire et chacun
                d’eux peut dynamiquement et de manière autonome paramétrer le comportement du service.
                L’intégration de notre API de signature électronique est un processus simple en <strong>3 étapes</strong> et <strong>2 flux principaux</strong> :
            </p>
            <img className='step-img' src="./assets/media/home_icon/Etapes-API-3.png" alt="step-api" />
            <p className='card-one-step'>
                <BsCheckCircleFill style={{ marginRight: '10px' }} className='landing-casusage__content-items-icons-check' />
                <strong>ÉTAPE 1 : </strong>Création de la demande de signature avec les documents et les données d’identification des signataires.
            </p>
            <p className='card-one-step'>
                <BsCheckCircleFill style={{ marginRight: '10px' }} className='landing-casusage__content-items-icons-check' />
                <strong>ÉTAPE 2 : </strong>Signature des documents, entièrement gérée par DKBSign sur une page multidevice et web responsive.
            </p>
            <p className='card-one-step'>
                <BsCheckCircleFill style={{ marginRight: '10px' }} className='landing-casusage__content-items-icons-check' />
                <strong>ÉTAPE 3 : </strong>Envoi et/ou récupération des documents signés.
            </p>
            <hr class="card-one-separator" />
            <p className='card-one-paragraph-bottom'>
                <strong>
                    L’API que nous mettons à disposition des développeurs est entièrement
                    documentée pour vous permettre de tester et
                    implémenter nos services de confiance en toute simplicité.
                </strong>
            </p>
            <p className='card-one-paragraph-bottom'>
                Grâce à notre offre de services professionnels,
                nous vous accompagnons dans la construction de vos workflows,
                afin que vous puissiez ajouter rapidement et simplement des fonctionnalités de signature électronique,
                de cachet électronique ou d’horodatage à vos applications existantes.
            </p>
        </CardWrapper>
    )
}

const CardTWO = () => {
    return (
        <CardWrapper hasBg={'BG'} color={'white'}>
            <div className="card-two-for-align-center">
                <div className="title-card-two">Faites le choix d’une API performante alliant sécurité et simplicité d’utilisation</div>
            </div>
        </CardWrapper>
    )
}

const CardTHREE = () => {
    return (
        <CardWrapper color={'blue'}>
            <div className="title-card-two-lg">Garantissez la conformité réglementaire de vos transactions numériques</div>
            <p className='card-two-paragraph'>
                DKBSign, Prestataire de Service de certificat électronique (PSCE)
                agréé par l’ARTCI vous apporte toutes les garanties de sécurité,
                notamment juridiques, afin d’offrir des services de confiance conférant une
                valeur probante aux documents conformément à la loi sur les transactions électroniques.
            </p>
        </CardWrapper>
    )
}

const Process = () => {
    return (
        <div className="landing_api-process">
            <Fade duration={4000} left>
                <div className="landing_api-process-title">
                    Un processus simple d’intégration
                    de nos API de signature électronique,
                    de cachet électronique et d’horodatage
                </div>
            </Fade>

            <CardONE />
            <CardTWO />
            <CardTHREE />
        </div>
    )
}

const Cachet = () => {
    return (
        <div className="landing_api-process">
            <Fade duration={4000} left>
                <div className="landing_api-process-title"> L’API de signature électronique, de cachet électronique et d’horodatage DKBSign</div>
            </Fade>

            <CardCachet />
        </div>
    )
}

const CardCachet = () => {
    return (
        <CardWrapper color={'white'} hasBg={'BGSign'}>
            <div className="title-card-one">
                Qu’il s’agisse de projets de développement d’applications ou de logiciels métier, les services de confiance sont aujourd’hui un incontournable. Ainsi, vous pouvez intégrer simplement l’API DKBSign dans votre logiciel métier :
            </div>
            <p className='card-one-step'>
                <BsCheckCircleFill style={{ marginRight: '10px' }} className='landing-casusage__content-items-icons-check' />
                <strong>CRM : </strong>intégrez l’API de signature électronique Universign et faites signer vos devis et propositions commerciales directement depuis votre CRM
            </p>
            <p className='card-one-step'>
                <BsCheckCircleFill style={{ marginRight: '10px' }} className='landing-casusage__content-items-icons-check' />
                <strong>ERP : </strong>cachetez et horodatez vos transactions pour en garantir l’intégrité et automatisez vos workflows
            </p>
            <p className='card-one-step'>
                <BsCheckCircleFill style={{ marginRight: '10px' }} className='landing-casusage__content-items-icons-check' />
                <strong>Contract Management : </strong>gérez, signez et centralisez tous vos contrats au sein d’une unique plateforme
            </p>
            <p className='card-one-step'>
                <BsCheckCircleFill style={{ marginRight: '10px' }} className='landing-casusage__content-items-icons-check' />
                <strong>SIRH : </strong>signez à distance promesses d’embauche et contrats de travail ou dématérialisez le traitement des notes de frais grâce à notre cachet électronique
            </p>

        </CardWrapper>
    )
}

const Fast = () => {
    return (
        <div className="landing_api-process">
            <Fade duration={4000} left>
                <div className="landing_api-process-title">Une API de signature électronique facile et rapide à intégrer</div>
            </Fade>

            <FastCard />
        </div>
    )
}

const FastCard = () => {
    return (
        <CardWrapper color={'green'}>
            <p className='card-two-paragraph'>
                <strong>
                    Les services web DKBsign sont performants, simples et documentés.
                    Ils vous permettent d’intégrer rapidement nos services de confiance à toutes vos applications,
                    sites web et logiciels métier de manière transparente.
                </strong>
            </p>
            <p className='card-two-paragraph'>
                Notre API a été conçue pour répondre aux besoins des développeurs et leur
                permettre de gagner en temps et en praticité. Ainsi, la plupart des projets utilisant l’API
                DKBsign sont en production seulement en quelques jours homme. Découvrez comment
                votre équipe peut implémenter l’API DKBsign en demandant notre documentation.
            </p>
        </CardWrapper>
    )
}